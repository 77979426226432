import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { getFileContent } from './firebasepageshareservice.js';

function DisplayPage() {
  const { fileId } = useParams();
  const [htmlContent, setHtmlContent] = useState('');

  useEffect(() => {
    const fetchContent = async () => {
      try {
        const content = await getFileContent(fileId+".html");
        setHtmlContent(content);
      } catch (error) {
        console.error('Error fetching file content:', error);
      }
    };

    fetchContent();
  }, [fileId]);

  return (
    <div>
      <div
        style={{
          width: '100vw',
          height: '100vh',
          border: '1px solid #ccc',
          overflow: 'auto'
        }}
        dangerouslySetInnerHTML={{ 
          __html: `<iframe 
                     style="border:none; width:100%; height:100%;" 
                     src="${htmlContent}"
                   ></iframe>` 
        }}
      ></div>
    </div>
  );
}

export default DisplayPage;
