import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleNotch, faChartBar, faUser, faUsers } from '@fortawesome/free-solid-svg-icons';
import SocialMediaPost from "./socialMediaPost";
import AnimatedPieChart from './animatedPieChart';
import AnimatedLineGraph from './animatedLineGraph';

import { BrowserRouter, Route, Routes, Link } from 'react-router-dom';
import { getFunctions, httpsCallable } from "firebase/functions";



const TeacherStudentDashboard = ({ values, schoolData, areasData, socialMediaPosts, changeParentTab }) => {


    const calculateStatistics = (values, schoolData, areasData, socialMediaPosts) => {

        let totalAwards = 0;
        let worldClassAwardsCount = 0;
        let subjectCounts = {};
        let achievementsAffectingOthersCount = 0;
        let totalAchievements = 0;
        let yearGroupAwards = {}; // Tracks the total awards per year group
        let yearGroupCounts = {}; // Tracks the count of awards per year group
        let createSocialMediaPosts = false;
        let socialMediaPostsArray = []

        // Assuming schoolData is available in the current scope
        if (false) {//!(socialMediaPosts && Object.keys(socialMediaPosts).length > 0)) {
            createSocialMediaPosts = true;
        }

        let topAwardInfo = [];

        if (createSocialMediaPosts) {

            async function callCreateSocialMediaPost(schoolData, description, characteristic, uid, key, area) {
                const functions = getFunctions(); // Assuming getFunctions() is already defined/imported
                const createSocialMediaPost = httpsCallable(functions, 'createSocialMediaPost');
                try {
                    console.log("creating posts with this info", { schoolData, description, characteristic, uid, key, area },)
                    const result = await createSocialMediaPost({ schoolData, description, characteristic, uid, key, area });
                    console.log("Function call result:", result.data); // Accessing the data property for the result
                    return result.data; // Assuming the cloud function returns data in a data property
                } catch (error) {
                    console.error("Error calling createSocialMediaPost:", error);
                    throw error; // Rethrow to handle it in the calling context
                }
            }


            async function createPostsForTopAwards() {
                if (createSocialMediaPosts) { // Assuming this is correctly set based on your logic
                    for (const awardInfo of topAwardInfo) { // Assuming topAwardInfo is an array of award information
                        try {
                            const returnValue = await callCreateSocialMediaPost(schoolData, awardInfo.description, awardInfo.characteristic, awardInfo.uid, awardInfo.key, awardInfo.area);
                        } catch (error) {
                        }
                    }
                }
            }


            // Gather all awards with their corresponding value, uid, area, and key
            let allAwards = [];
            values.forEach(value => {
                if (value.grace) {
                    Object.keys(value.grace).forEach(key => {
                        const award = value.grace[key].award;
                        if (award !== undefined) { // Ensure the award exists
                            const description = value[`description${key}`]; // Concatenation to find description
                            const areaData = areasData.find(area => area.path === value.area); // Find matching area data
                            console.log("areaData dashboard", areaData)
                            const characteristic = areaData?.characteristics.find(char => char.id == key)?.name; // Find matching characteristic by key

                            allAwards.push({
                                uid: value.uid,
                                area: value.area,
                                key: key,
                                award: award,
                                description: description,
                                characteristic: characteristic
                            });
                        }
                    });
                }
            });

            // Sort and slice to get top awards
            allAwards.sort((a, b) => b.award - a.award);
            topAwardInfo = allAwards.slice(0, 10);

            createPostsForTopAwards();

        } else {

            socialMediaPostsArray = Object.values(socialMediaPosts).sort(() => 0.5 - Math.random());


        }

        // Now, each object in 'topAwardInfo' includes uid, area, key, award, description, and characteristic


        values.forEach(value => {
            // Process grace awards
            if (value.grace) {
                Object.keys(value.grace).forEach(key => {
                    const award = value.grace[key].award;
                    if (award !== undefined) {
                        totalAwards++;
                        if (award >= 80) {
                            worldClassAwardsCount++;
                        }

                        // Accumulate awards for the year group
                        const yearGroup = value.yearGroup;
                        if (yearGroupAwards[yearGroup]) {
                            yearGroupAwards[yearGroup] += award;
                            yearGroupCounts[yearGroup]++;
                        } else {
                            yearGroupAwards[yearGroup] = award;
                            yearGroupCounts[yearGroup] = 1;
                        }
                    }
                });
            }

            // Process embeddings for subjects and achievements
            if (value.embeddings) {
                Object.keys(value.embeddings).forEach(key => {
                    // Process subjects
                    const subjectArray = value.embeddings[key].embeddingData.subject;
                    if (subjectArray && subjectArray.length > 0) {
                        const subject = subjectArray[0];
                        if (subjectCounts[subject]) {
                            subjectCounts[subject]++;
                        } else {
                            subjectCounts[subject] = 1;
                        }
                    }

                    // Process achievements affecting others
                    const achievementArray = value.embeddings[key].embeddingData.achievement;
                    if (achievementArray && achievementArray.length > 0) {
                        const achievement = achievementArray[0];
                        totalAchievements++;
                        if (achievement.toLowerCase().includes("others")) {
                            achievementsAffectingOthersCount++;
                        }
                    }
                });
            }
        });

        const worldClassPercentage = (worldClassAwardsCount / totalAwards) * 100;

        // Convert subject counts to percentages
        let totalSubjects = Object.values(subjectCounts).reduce((acc, curr) => acc + curr, 0);
        let subjectPercentages = Object.keys(subjectCounts).reduce((acc, key) => {
            acc[key] = ((subjectCounts[key] / totalSubjects) * 100).toFixed(1);
            return acc;
        }, {});

        // Calculate the percentage of achievements affecting others
        const achievementsAffectingOthersPercentage = totalAchievements > 0
            ? ((achievementsAffectingOthersCount / totalAchievements) * 100).toFixed(1)
            : 0;

        // Calculate average award per year group
        let averageAwardsPerYearGroup = Object.keys(yearGroupAwards).map(yearGroup => ({
            yearGroup: parseInt(yearGroup), // Ensure year group is treated as a number
            averageAward: parseFloat((yearGroupAwards[yearGroup] / yearGroupCounts[yearGroup]).toFixed(2))
        }));

        // Sort by year group for the line graph
        averageAwardsPerYearGroup.sort((a, b) => a.yearGroup - b.yearGroup);

        return {
            worldClassPercentage: isNaN(worldClassPercentage) ? 0 : worldClassPercentage.toFixed(2),
            subjectPercentages,
            achievementsAffectingOthersPercentage, // Percentage of achievements affecting others
            averageAwardsPerYearGroup,
            socialMediaPostsArray // Reintegrated line graph data
        };
    };



    const { worldClassPercentage, subjectPercentages, averageAwardsPerYearGroup, achievementsAffectingOthersPercentage, socialMediaPostsArray } = calculateStatistics(values, schoolData, areasData, socialMediaPosts);

    const subjectDataForChart = Object.keys(subjectPercentages).map(key => ({
        name: key.replace("Learning", "").replace("Literature", "Lit"),
        percentage: parseFloat(subjectPercentages[key]) // Ensure it's a number
    }));

    const achievementsDataForChart = [
        { name: "Others", percentage: parseFloat(achievementsAffectingOthersPercentage) },
        { name: "Self", percentage: parseFloat((100 - achievementsAffectingOthersPercentage).toFixed(2)) },
    ];

    return (
        <div className="container-fluid p-4">
        <div className="row gx-4 gy-4">

             {/* Review Individuals Button */}
             <div className="col-lg-4">
                <button onClick={() => changeParentTab('allStudents')} className="btn btn-primary w-100 h-100 p-4 shadow-sm rounded d-flex flex-column align-items-center justify-content-center">
                    <FontAwesomeIcon size={"2x"} icon={"fa-users"} className="mb-2" />
                    <h3 className="mb-0">Review Individuals</h3>
                </button>
            </div>

            {/* Data Insights Button */}
            <div className="col-lg-4">
                <button onClick={() => changeParentTab('insight')} className="btn btn-outline-primary w-100 h-100 p-4 shadow-sm rounded d-flex flex-column align-items-center justify-content-center">
                    <FontAwesomeIcon size={"2x"} icon={"fa-chart-line"} className="mb-2" />
                    <h3 className="mb-0">Data Insights</h3>
                </button>
            </div>
    
           
    
            {/* Settings Button */}
            <div className="col-lg-4">
                <Link to="/settings" className="btn btn-secondary w-100 h-100 p-4 shadow-sm rounded d-flex flex-column align-items-center justify-content-center text-decoration-none text-white">
                    <FontAwesomeIcon size={"2x"} icon={"fa-gear"} className="mb-2" />
                    <h3 className="mb-0">Settings</h3>
                </Link>
            </div>
    
            {/* Getting Started Card */}
            <div className="col-lg-6">
                <div className="card shadow-sm rounded h-100">
                    <div className="card-body d-flex flex-column align-items-start justify-content-center">
                        <h3 className="card-title">Getting Started</h3>
                        <p><strong>Important!</strong> Students need to create their own accounts using the link below:</p>
                        <a target="_blank" rel="noopener noreferrer" href={`https://app.beworldclass.org/student/${schoolData?.path}`} className="text-primary">
                            app.beworldclass.org/student/{schoolData?.path}
                        </a>
                    </div>
                </div>
            </div>
    
            {/* Demonstrate Impact Card */}
            <div className="col-lg-6">
                <div className="card shadow-sm rounded h-100">
                    <div className="card-body d-flex flex-column">
                        <h3 className="card-title">Demonstrate Impact</h3>
                        <hr />
                        <div className="d-flex justify-content-between">
                            <button onClick={() => changeParentTab('socialMedia')} className="btn btn-link text-primary d-flex align-items-center">
                                <FontAwesomeIcon size={"2x"} icon={"fa-share-nodes"} className="me-2" /> Share top achievements
                            </button>
                            <button onClick={() => changeParentTab('widgets')} className="btn btn-link text-primary d-flex align-items-center">
                                <FontAwesomeIcon size={"2x"} icon={"fa-th-large"} className="me-2" /> Website Widgets
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    

    );
};

export default TeacherStudentDashboard;
