import React from "react";
import { Field, ErrorMessage } from "formik";
import EvidenceFile from "../student/areaPage/evidenceFile";
import { getDatabase, ref, update, onValue } from "firebase/database";
import app from "../firebase";
import { faStar, faUniversity, faShareNodes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
//import { Editor } from '@tinymce/tinymce-react';


class FeedbackCharacteristic extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            file: undefined,
            mceText: "",
            example: false,
            shareOpen: false,
            userPublic: false,
        }
    }

    componentDidMount() {


    }


    toggleExample = () => {
        let example = this.state.example
        this.setState({ example: example ? false : true })
    }

    toggleShare = () => {
        this.setState({ shareOpen: !this.state.shareOpen })

    }

    addToHallOfFame = () => {
        const database = getDatabase(app)
        const submissionPath = ref(database, 'schools/' + this.props.schoolData.path + '/public/hallOfFame/submissions/' + this.state.areaPath + this.props.characteristicData.id + this.state.uid);
        const usersPath = ref(database, 'schools/' + this.props.schoolData.path + '/public/hallOfFame/users/' + this.state.uid);
        const characteristicData = {
            area: this.props.areaPath,
            characteristic: this.props.characteristicData.name,
            characteristicId: this.props.characteristicData.id,
            description: this.props.submission["description" + this.props.id],
            uid: this.props.uid,
            // order: curent time as integer,
        }
        const userData = {
            firstName: "test",
            lastName: "test",
            yearGroup: 7
        }

        console.log(characteristicData)
        console.log(this.props.submission)


        update(submissionPath, characteristicData);


    }





    render() {

        const letter = (this.props.index + 10).toString(36).toUpperCase()

        const preStyle = { whiteSpace: "pre-wrap", fontFamily: "Arial", fontSize: "16px", color: "black" }

        return (
            <div className="card" style={{ marginBottom: "25px" }}>
                <div className="card-header text-dark row">
                    <h3 className="card-title col-9">{letter}... {this.props.characteristicData.name}</h3>
                    <div className="col-3" style={{ textAlign: "right" }} >
                        {false && this.props.submission["description" + this.props.id] &&
                            <button type="button" className="btn btn-outline-warning" onClick={this.toggleShare.bind(this)} ><FontAwesomeIcon icon={faShareNodes} /> </button>
                        }
                    </div>
                </div>
                <div className="card-body">
                    <div className="row">

                    </div>

                    {this.state.shareOpen &&
                        <div className="card bg-warning mt-3">
                            <div className="card-header text-dark">
                                <h4 className="mb-0">Share characteristic</h4>
                            </div>
                            <div className="card-body text-dark">
                                <div className="row">
                                    <div className="col-6">
                                        <div className="form-check">
                                            <h6>Information to share</h6>
                                            <p>The students description and any images for this characteristic will be made public</p>
                                            <label className="form-check-label" htmlFor="makeNameAndYearGroupPublic">
                                                Make name and year group public? (optional)
                                                <input
                                                    name="makeNameAndYearGroupPublic"
                                                    type="checkbox"
                                                    className="form-check-input"
                                                    id="makeNameAndYearGroupPublic"
                                                    onClick={() => {
                                                        this.setState({ userPublic: !this.state.userPublic })
                                                    }}
                                                />
                                            </label>
                                        </div>
                                    </div>
                                    <div className="col-6">
                                        <h6>Share with</h6>
                                        <div className="btn-group" role="group">
                                            <button
                                                type="button"
                                                className={`btn btn-light text-dark `}
                                                onClick={this.addToHallOfFame.bind(this)}
                                            >
                                                <FontAwesomeIcon icon={faUniversity} /> Your School's Hall of Fame
                                            </button>
                                            <button
                                                type="button"
                                                className={`btn btn-light text-dark ${true === "student" ? "active" : ""}`}
                                            >
                                                <FontAwesomeIcon icon={faStar} /> World Class Student of the Month
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    }

                    {this.props.submission["description" + this.props.id] ?
                        <>
                            <div className="row">
                                <div className="col-6">

                                    <h5>Description</h5>
                                    <pre style={preStyle}>
                                        {this.props.submission["description" + this.props.id]}
                                    </pre>


                                    <h5>Source</h5>
                                    <pre style={preStyle}>
                                        {this.props.submission["source" + this.props.id]}
                                    </pre>
                                    <h5>Date of evidence</h5>
                                    <pre style={preStyle}>
                                        {this.props.submission["date" + this.props.id]}
                                    </pre>

                                </div>

                                <div className="col-6">
                                    <div className="form-group">
                                        <div className="form-group">
                                            <h5>Evidence</h5>
                                            <EvidenceFile
                                                disabled={true}
                                                schoolData={this.props.schoolData}
                                                uid={this.props.uid}
                                                index={this.props.id}
                                                areaPath={this.props.areaPath}

                                            />
                                        </div>
                                    </div>
                                </div>

                            </div>



                            <div className="col-12">

                                <div className="form-group">
                                    <label for={"feedback" + this.props.id}>Feedback</label>
                                    <Field as="textarea" style={{ height: "100px" }} id={"feedback" + this.props.id} name={"feedback" + this.props.id} type="text" className="form-control" />
                                </div>

                            </div>
                        </>
                        :
                        <p>Not started</p>
                    }
                </div>
            </div>
        );
    }
}

export default FeedbackCharacteristic;