import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown, faChevronRight } from '@fortawesome/free-solid-svg-icons';

const ReportGeneratorSection = ({ section, sections, onUpdate, hasCircularDependency, onDependencyChange }) => {
    const [isOpen, setIsOpen] = useState(false);
    const [localSection, setLocalSection] = useState(section);
    const [dependencies, setDependencies] = useState(section.dependencies || []);

    useEffect(() => {
        setLocalSection(section);
        setDependencies(section.dependencies || []);
    }, [section]);

    const handleChange = (e) => {
        const { name, value } = e.target;
        const updatedSection = { ...localSection, [name]: value };
        setLocalSection(updatedSection);
        onUpdate(updatedSection);
    };

    const handleDependencyChange = (e) => {
        const { value, checked } = e.target;
        const newDependencyId = parseInt(value);
        let newDependencies = [...dependencies];

        if (checked) {
            newDependencies.push(newDependencyId);
        } else {
            newDependencies = newDependencies.filter(dep => dep !== newDependencyId);
        }

        setDependencies(newDependencies);
        onUpdate({ ...localSection, dependencies: newDependencies });
        onDependencyChange(section.id, newDependencies);
    };

    const isValidDependency = (depId) => {
        return !hasCircularDependency(sections, section.id, depId) && !hasCircularDependency(sections, depId, section.id);
    };

    const validDependencies = sections.filter(sec => sec.id !== section.id && isValidDependency(sec.id));

    const toggleOpen = () => {
        setIsOpen(!isOpen);
    };

    return (
        <div className="card mb-3">
            <div className="card-header" onClick={toggleOpen} style={{ cursor: 'pointer' }}>
                <FontAwesomeIcon icon={isOpen ? faChevronDown : faChevronRight} /> Section {section.id}: {section.title || 'Title'}
            </div>
            {isOpen && (
                <div className="card-body">
                    <div className="form-group">
                        <label htmlFor={`title-${section.id}`}>Title</label>
                        <input
                            type="text"
                            className="form-control"
                            id={`title-${section.id}`}
                            name="title"
                            value={localSection.title}
                            onChange={handleChange}
                        />
                    </div>
                    <div className="form-group">
                        <label htmlFor={`researchGoal-${section.id}`}>Research Goal</label>
                        <input
                            type="text"
                            className="form-control"
                            id={`researchGoal-${section.id}`}
                            name="researchGoal"
                            value={localSection.researchGoal}
                            onChange={handleChange}
                        />
                    </div>
                    <div className="form-group">
                        <label htmlFor={`dataInstructions-${section.id}`}>Data Gathering Instructions (optional)</label>
                        <textarea
                            className="form-control"
                            id={`dataInstructions-${section.id}`}
                            name="dataInstructions"
                            value={localSection.dataInstructions}
                            onChange={handleChange}
                        ></textarea>
                    </div>
                    <div className="form-group">
                        <label htmlFor={`structureInstructions-${section.id}`}>Section Structure Instructions</label>
                        <textarea
                            className="form-control"
                            id={`structureInstructions-${section.id}`}
                            name="structureInstructions"
                            value={localSection.structureInstructions}
                            onChange={handleChange}
                        ></textarea>
                    </div>
                    <div className="form-group">
                        <label htmlFor={`dependencies-${section.id}`}>Dependencies (optional)</label>
                        {sections.filter(sec => sec.id !== section.id).map(sec => (
                            <div key={sec.id} className="form-check">
                                <input
                                    className="form-check-input"
                                    type="checkbox"
                                    value={sec.id}
                                    id={`dep-${sec.id}-${section.id}`}
                                    checked={dependencies.includes(sec.id)}
                                    onChange={handleDependencyChange}
                                    disabled={!isValidDependency(sec.id) && !dependencies.includes(sec.id)} // Disable only if it's invalid and not already selected
                                />
                                <label className={`form-check-label ${!isValidDependency(sec.id) ? 'text-muted' : ''}`} htmlFor={`dep-${sec.id}-${section.id}`}>
                                    Section {sec.id}: {sec.title}
                                </label>
                            </div>
                        ))}
                    </div>
                </div>
            )}
        </div>
    );
};

export default ReportGeneratorSection;
