import React, { createRef } from "react";
import { getFunctions, httpsCallable } from "firebase/functions";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ReactMarkdown from 'react-markdown';
import 'bootstrap/dist/css/bootstrap.min.css';

class ReplicateChat extends React.Component {
    constructor(props) {
        super(props);
        this.chatEndRef = createRef(); // Ref for scrolling
        this.state = {
            userMessage: "",
            conversationHistory: [],
            sendingMessage: false
        };
    }

    handleMessageChange = (event) => {
        this.setState({ userMessage: event.target.value });
    }

    scrollToBottom = () => {
        if (this.chatEndRef.current) {
            this.chatEndRef.current.scrollIntoView({ behavior: 'smooth' });
        }
    }

    sendMessage = () => {
        const functions = getFunctions();
        const runReplicateModel = httpsCallable(functions, 'runReplicateModel');
        const { userMessage, conversationHistory } = this.state;

        if (!userMessage.trim()) {
            alert('Please enter a message.');
            return;
        }

        this.setState({ sendingMessage: true });

        // Append user message to conversation history
        this.setState(prevState => ({
            conversationHistory: [...prevState.conversationHistory, { role: 'user', message: userMessage }],
            userMessage: "" // Clear the input field immediately after appending
        }), this.scrollToBottom); // Scroll to bottom after appending

        // Create conversation history string
        const formattedHistory = conversationHistory.map(entry => {
            return `<|start_header_id|>${entry.role === 'user' ? 'user' : 'assistant'}<|end_header_id|>\n\n${entry.message}<|eot_id|>`;
        }).join('\n\n');


        const systemPrompt = "You are a helpful assistant"; // Adjust as needed

        runReplicateModel({
            prompt: formattedHistory + `\n\nuser\n\n${userMessage}`, // Including the current user message
            systemPrompt
        }).then((result) => {
            const messages = result.data.result.replace("\n\n"," \n\n ");

            this.setState(prevState => ({
                conversationHistory: [...prevState.conversationHistory, { role: 'assistant', message: messages }],
                sendingMessage: false // Reset sendingMessage to false
            }), this.scrollToBottom); // Scroll to bottom after adding assistant message
        }).catch((error) => {
            console.error('Error sending message:', error);
            alert('Failed to send message.');
            this.setState({ sendingMessage: false }); // Reset sendingMessage to false
        });
    }


    render() {
        const { userMessage, conversationHistory, sendingMessage } = this.state;

        return (
            <div className="container mt-5">
                <div className="text-center mb-4">
                    <img src="https://portalvhdspj4vyrwb46p2b.blob.core.windows.net/dingdongdiary/logos/image_40ccc015-7405-4696-b596-2889415be201.png"
                        style={{ height: "10vh" }} alt="Logo"></img>
                </div>
                <div className="card mb-3" style={{ height: "65vh", overflowY: "auto" }}>
                    <div className="card-body">
                        {conversationHistory.length > 0 ? (
                            conversationHistory.map((entry, index) => (
                                <div key={index} className={`d-flex mb-3 ${entry.role === 'user' ? 'justify-content-end' : 'justify-content-start'}`}>
                                    <div className={`card ${entry.role === 'user' ? 'bg-primary text-white' : 'bg-light text-dark'}`} style={{ maxWidth: '75%' }}>
                                        <div className="card-body">
                                            <ReactMarkdown>{entry.message}</ReactMarkdown>
                                        </div>
                                    </div>
                                </div>
                            ))
                        ) : (
                            <p className="text-muted">How can I help you today?</p>
                        )}
                        {sendingMessage &&
                            <div className="d-flex mb-3 justify-content-start">
                                <div className="card bg-light text-dark">
                                    <div className="card-body">
                                        <div className="typing">
                                            <div className="dot"></div>
                                            <div className="dot"></div>
                                            <div className="dot"></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        }
                        <div ref={this.chatEndRef}></div>
                    </div>
                </div>
                <div className="input-group my-3">
                    <input
                        type="text"
                        value={userMessage}
                        onChange={this.handleMessageChange}
                        placeholder="Type your message here"
                        className="form-control"
                    />
                    <button className="btn btn-primary" onClick={this.sendMessage}>
                        <FontAwesomeIcon icon="paper-plane" className="me-2" />
                    </button>
                </div>

            </div>
        );
    }
}

export default ReplicateChat;
