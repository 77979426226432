import React from "react";
import { Field } from "formik";

export function columnHeader(column, values) {
    if (column.type === "select") {


        console.log("values in column", values)
        let selectOptions = []

        for (let i = 0; i < values?.length; i++) {
            if (selectOptions.indexOf(values[i][column.key]) < 0 &&
                values[i][column.key] &&
                values[i][column.key] != "" &&
                values[i][column.key] != "false") {
                selectOptions.push(
                    values[i][column.key]
                )
            }
        }

        let selectOptionsJsx = []
        for (let i = 0; i < selectOptions.length; i++) {

            selectOptionsJsx.push(
                <option style={{ color: "black" }} value={selectOptions[i]}>{selectOptions[i]}</option>
            )

        }

        return <th scope="col">
            <Field style={{ color: "grey" }} as="select" id={column.key} name={column.key} placeholder={column.label} type="text" className="form-control input-sm" >
                <option value="" selected>{column.label}</option>
                {selectOptionsJsx}
            </Field>
        </th>
    } else {

        return <th scope="col"><Field id={column.key} name={column.key} placeholder={column.label} type="text" className="form-control input-sm" /></th>;
    }
}
