import React, { useState, useEffect } from "react";
import './robot.css';

const Robot = ({ messageText, thinking }) => {
    const [isTalking, setIsTalking] = useState(false);
    const [utterance, setUtterance] = useState(null);

    const synth = window.speechSynthesis;

    let voices = [];
    const populateVoices = () => {
        voices = synth.getVoices();
    };
    populateVoices();
    synth.onvoiceschanged = populateVoices;

    const speak = (text) => {
        console.log("speak", text)

        // Split text into sentences or chunks
        const sentences = text.split(/\.(?=\s|$)/g);

        let i = 0;
        speakSentence();
        function speakSentence() {
            console.log("speaking sentence", i, "out of", i >= sentences )
            if (i >= sentences.length) {
                console.log("i'm done")
                setIsTalking(false);
                return;
            }
            const utterance = new SpeechSynthesisUtterance(sentences[i]);

            // Look for a voice that has 'Google' in the name and use that voice if available
            const googleVoice = voices.find(voice => voice.name.includes('Google UK English Female') && voice.lang.includes('en'));
            if (googleVoice) {
                utterance.voice = googleVoice;
            }
            utterance.onstart = () => {
                setIsTalking(true);
            };

            utterance.onend = () => {
                i++;
                setIsTalking(false);
                speakSentence();
            };

            synth.speak(utterance);
            setUtterance(utterance);
        }
        //setIsTalking(true);

    };


    const stopSpeaking = () => {
        if (utterance) {
            window.speechSynthesis.cancel();
            setIsTalking(false);
        }
    };

    const handleRobotClick = () => {
        if (isTalking) {
            stopSpeaking();
        } else {
            speak(messageText);
        }
    };

    useEffect(() => {
        if (isTalking) {
            // Add your animation logic here
            console.log("Robot starts talking");
        } else {
            // Add your logic to stop the animation here
            console.log("Robot stops talking");
        }
    }, [isTalking]);

    const robotHead = 'https://firebasestorage.googleapis.com/v0/b/beworldclass-801f5.appspot.com/o/staticImages%2FGRACE%20head.png?alt=media&token=1197660e-9d0e-4f57-b02a-5ea32613668e'
    const robotHeadEyesOff = 'https://firebasestorage.googleapis.com/v0/b/beworldclass-801f5.appspot.com/o/staticImages%2FGRACE%20head%20eyes%20off.png?alt=media&token=858bf479-b322-4e27-a751-500afc23e8f8'
    const robotBody = ' https://firebasestorage.googleapis.com/v0/b/beworldclass-801f5.appspot.com/o/staticImages%2FGRACE%20bodylogo.png?alt=media&token=f6eac0f6-cb20-4b3b-a0b5-228ebc331206'
    const headClass = isTalking || thinking ? 'robot-head talking' : 'robot-head';
    return (
        <div className={thinking && !messageText ? "enter-from-left" : ""} style={{ marginLeft: "-15rem", cursor: "pointer" }} onClick={handleRobotClick}>
            <div className={`robot  ${thinking ? "no-move" : ""}`}>
                <div className="robot-body">
                    <img src={robotBody} alt="Robot body" />
                </div>
                <div className="robot-head-wrapper">
                    <div className={headClass}>
                        <img className="head-image head-image-1" src={robotHead} alt="Robot head 1" />
                        <img className="head-image head-image-2" src={robotHeadEyesOff} alt="Robot head 2" />
                    </div>
                </div>
            </div>


        </div>
    );
};

export default Robot;