import React from "react";


class StudentPrivacyPolicy extends React.Component {
    constructor(props) {
        super(props);
    }
    state = {}

    render() {
        return ( <div style={{ textAlign: "center", padding: "2rem" }}>
        <div className="model" style={{ textAlign: "left", padding: "2rem", backgroundColor:"white" }}>
            <h1 style={{textAlign:"center"}}>{this.props.schoolData.name} Privacy Notice</h1><br/>
            <h3 />
            <span style={{ fontWeight: 400 }}>
                This privacy notice is given on behalf of {this.props.schoolData.name} (referred to in this privacy
                notice as “
            </span>
            <b>the School</b>
            <span style={{ fontWeight: 400 }}>”, “</span>
            <b>we</b>
            <span style={{ fontWeight: 400 }}>”, “</span>
            <b>us</b>
            <span style={{ fontWeight: 400 }}>” and “</span>
            <b>our</b>
            <span style={{ fontWeight: 400 }}>
                ”).&nbsp; This privacy notice is provided to parents or guardians of School
                pupils who may use the BeWorldClass Platform (the “
            </span>
            <b>Platform</b>
            <span style={{ fontWeight: 400 }}>
                ”) and other adults with access to the App, such as School teachers and
                staff. Please read this privacy notice carefully and ensure that you
                understand it.&nbsp; If you are a parent or guardian, it is important that
                you review this notice carefully and explain it to your child.
            </span>
            <span style={{ fontWeight: 400 }}>When we refer to “</span>
            <b>you</b>
            <span style={{ fontWeight: 400 }}>” or “</span>
            <b>your</b>
            <span style={{ fontWeight: 400 }}>
                ”, we mean the person whose personal data we are processing.&nbsp; The
                School is the controller of your personal data.&nbsp; We are committed to
                protecting your personal data in line with data protection law. If you have
                any questions about this notice, you can contact the School using our
                regular contact details.
            </span>
            <span style={{ fontWeight: 400 }}>
                This privacy notice aims to give you information on how we collect and
                process your personal data through your use of the BeWorldClass
                Platform.&nbsp; It is important that you read this privacy notice together
                with any other privacy policy you have been provided. This privacy notice
                supplements other notices and is not intended to override them.
            </span>

            <h3>Changes to the privacy notice and your duty to inform us of changes</h3>
            <span style={{ fontWeight: 400 }}>
                We keep our privacy notice under regular review. This notice was last
                updated on 29th May 2023.
            </span>
            <span style={{ fontWeight: 400 }}>
                It is important that the personal data we hold about you is accurate and
                current. Please keep us informed if your personal data changes during your
                relationship with us.
            </span>
            <h3>What personal data do we collect and how?</h3>
            <span style={{ fontWeight: 400 }}>
                Personal data, or personal information, means any information about an
                individual from which that person can be identified. It does not include
                data where the identity has been removed (anonymous data).
            </span>
            <span style={{ fontWeight: 400 }}>We process personal data relating to:</span>
            <ul>
                <li style={{ fontWeight: 400 }} aria-level={1}>
                    <span style={{ fontWeight: 400 }}>
                        Each student using the BeWorldClass Platform, including their name,
                        gender, email address, school, year group/class, and information about
                        BeWorldClass characteristics; and
                    </span>
                </li>
                <li style={{ fontWeight: 400 }} aria-level={1}>
                    <span style={{ fontWeight: 400 }}>
                        Each member of staff using the BeWorldClass Platform, including their
                        name, role/job title and school.&nbsp;
                    </span>
                </li>
            </ul>
            <span style={{ fontWeight: 400 }}>
                We will process the following categories of personal data:
            </span>
            <ul>
                <li style={{ fontWeight: 400 }} aria-level={1}>
                    <b>personal details and identifiers</b>
                    <span style={{ fontWeight: 400 }}>
                        , such as name, title, addresses, telephone numbers, personal email
                        addresses and date of birth;
                    </span>
                </li>
                <li style={{ fontWeight: 400 }} aria-level={1}>
                    <b>education details</b>
                    <span style={{ fontWeight: 400 }}>
                        , such as School, year group, School email address, biography and UCAS
                        information;
                    </span>
                </li>
                <li style={{ fontWeight: 400 }} aria-level={1}>
                    <b>inclusion data</b>
                    <span style={{ fontWeight: 400 }}>
                        , which may include special educational needs status, pupil premium
                        eligibility, and ethnicity; and
                    </span>
                </li>
                <li style={{ fontWeight: 400 }} aria-level={1}>
                    <b>technical data</b>
                    <span style={{ fontWeight: 400 }}>
                        , such as internet protocol (IP) address, your login data, browser type
                        and version, time zone setting and location, browser plug-in types and
                        versions, operating system and platform, and other technology on the
                        devices you use to access our website.
                    </span>
                </li>
            </ul>
            <span style={{ fontWeight: 400 }}>
                We use different methods to collect data from and about you including
                through:
            </span>
            <ul>
                <li style={{ fontWeight: 400 }} aria-level={1}>
                    <b>Direct interactions. </b>
                    <span style={{ fontWeight: 400 }}>
                        You may give us your personal data through signing up and using the
                        BeWorldClass Platform; and
                    </span>
                </li>
                <li style={{ fontWeight: 400 }} aria-level={1}>
                    <b>Automated technologies and interactions. </b>
                    <span style={{ fontWeight: 400 }}>
                        As you interact with the BeWorldClass Platform, we will automatically
                        collect Technical Data about your equipment, browsing actions and
                        patterns. We collect this personal data by using cookies and other
                        similar technologies. Please see the BeWorldClass {" "}
                    </span>
                    <a style={{ fontWeight: 400 }} href="https://beworldclass.org/privacy-policy/" target="_blank">Cookie Policy </a>
                    <span style={{ fontWeight: 400 }}>for further details.&nbsp;</span>
                </li>
            </ul>
            <h3 />
            <h3>What do we do with your data?</h3>
            <span style={{ fontWeight: 400 }}>
                We process your personal data in order to:&nbsp;
            </span>
            <ul>
                <li style={{ fontWeight: 400 }} aria-level={1}>
                    <span style={{ fontWeight: 400 }}>
                        provide and manage access to the BeWorldClass Platform and accounts; and
                    </span>
                </li>
                <li style={{ fontWeight: 400 }} aria-level={1}>
                    <span style={{ fontWeight: 400 }}>
                        assess student participation and complete assessments within the
                        BeWorldClass Platform.
                    </span>
                </li>
            </ul>
            <h3 />
            <span style={{ fontWeight: 400 }}>
                We have set this out in more detail below, by type of data subject.
            </span>
            <i>
                <span style={{ fontWeight: 400 }}>School students&nbsp;</span>
            </i>
            <ul>
                <li style={{ fontWeight: 400 }} aria-level={1}>
                    <span style={{ fontWeight: 400 }}>
                        for students undertaking the assessment process we will share your name,
                        year group and, where this is provided, your School email address, with
                        your assessors, the assessment team and your BeWorldClass Assessment
                        Centre peers, in order to facilitate the assessment process.
                    </span>
                </li>
            </ul>
            <i>
                <span style={{ fontWeight: 400 }}>Staff members</span>
            </i>
            <ul>
                <li style={{ fontWeight: 400 }} aria-level={1}>
                    <span style={{ fontWeight: 400 }}>
                        we use your email address to share relevant information relating to the
                        BeWorldClass platform;
                    </span>
                </li>
                <li style={{ fontWeight: 400 }} aria-level={1}>
                    <span style={{ fontWeight: 400 }}>
                        we use your email address to contact you in relation to any matters
                        arising relating to your BeWorldClass Platform subscription;
                    </span>
                </li>
                <li style={{ fontWeight: 400 }} aria-level={1}>
                    <span style={{ fontWeight: 400 }}>
                        we will use your information to identify the relevant level of training
                        required, to match you with the students you will be assessing, and to
                        contact you with information and guidance on your role as a BeWorldClass
                        assessor. Your name, age, the name of your School and your email address
                        will be shared with the students you will be assessing; and
                    </span>
                </li>
                <li style={{ fontWeight: 400 }} aria-level={1}>
                    <span style={{ fontWeight: 400 }}>
                        where you have provided us with a telephone number we may contact you
                        directly about opportunities that may arise to help the School and
                        students capitalise on your BeWorldClass subscription. Whether this is
                        your personal or work telephone number will depend on the number you
                        have provided.&nbsp;
                    </span>
                </li>
            </ul>
            <h3>What is the legal basis for processing your personal data?</h3>
            <span style={{ fontWeight: 400 }}>
                We rely on the following grounds to process your personal data:
            </span>
            <ul>
                <li style={{ fontWeight: 400 }} aria-level={1}>
                    <span style={{ fontWeight: 400 }}>
                        Consent (for students, consent from their parent or guardian);
                    </span>
                </li>
                <li style={{ fontWeight: 400 }} aria-level={1}>
                    <span style={{ fontWeight: 400 }}>
                        Necessary for the performance of a contract;
                    </span>
                </li>
                <li style={{ fontWeight: 400 }} aria-level={1}>
                    <span style={{ fontWeight: 400 }}>
                        Where we need to comply with a legal obligation; and
                    </span>
                </li>
            </ul>
            <ul>
                <li style={{ fontWeight: 400 }} aria-level={1}>
                    <span style={{ fontWeight: 400 }}>
                        Legitimate interests. We may process personal data where, when weighed
                        against your rights as a data subject, it is in our legitimate interest
                        to do so. Specifically, the school uses this basis in the following
                        situations and for the following legitimate interests:
                    </span>
                </li>
            </ul>
            <h3 />
            <ul>
                <li style={{ fontWeight: 400 }} aria-level={1}>
                    <span style={{ fontWeight: 400 }}>
                        To facilitate successful use of the BeWorldClass Platform in order to
                        improve World Class characteristics in students;
                    </span>
                </li>
                <li style={{ fontWeight: 400 }} aria-level={1}>
                    <span style={{ fontWeight: 400 }}>
                        To monitor student progress, provide appropriate educational support,
                        and identify students who may require additional assistance or
                        accommodations.
                    </span>
                </li>
                <li style={{ fontWeight: 400 }} aria-level={1}>
                    <span style={{ fontWeight: 400 }}>
                        To undertake research and analysis to improve the effectiveness of our
                        educational methods, tools, and techniques, and to develop new ones.
                    </span>
                </li>
                <li style={{ fontWeight: 400 }} aria-level={1}>
                    <span style={{ fontWeight: 400 }}>
                        To comply with applicable laws, exercise legal rights, and respond to
                        legal claims.
                    </span>
                </li>
            </ul>
            <h3>Cookies</h3>
            <span style={{ fontWeight: 400 }}>
                You can set your browser to refuse all or some browser cookies, or to alert
                you when websites set or access cookies. If you disable or refuse cookies,
                please note that some parts of this website may become inaccessible or not
                function properly. For more information about the cookies we use, please see
                the BeWorldClass
            </span>
            <a style={{ fontWeight: 400 }} href="https://beworldclass.org/privacy-policy/" target="_blank">Cookie Policy </a>
            <span style={{ fontWeight: 400 }}>.</span>
            <h3>Disclosures of your personal data</h3>
            <span style={{ fontWeight: 400 }}>
                We may share your personal data with the parties set out below for the
                purposes set out above. This includes:
            </span>
            <ul>
                <li style={{ fontWeight: 400 }} aria-level={1}>
                    <span style={{ fontWeight: 400 }}>our service providers; and</span>
                </li>
                <li style={{ fontWeight: 400 }} aria-level={1}>
                    <span style={{ fontWeight: 400 }}>
                        professional advisers including lawyers, bankers, auditors and insurers.
                    </span>
                </li>
            </ul>
            <span style={{ fontWeight: 400 }}>
                Your data will not be shared with any unauthorized third parties. In order
                to provide our services, we will share your data with our data processor,
                BeWorldClass Education Ltd, who will process your data according to our
                instructions and in compliance with all applicable laws and regulations,
                including GDPR. Your data will be accessed by BeWorldClass Education Ltd,
                only when necessary to provide our services, at the request of the school,
                or to maintain or improve these services, including resolving issues that
                may affect the functionality or security of our services.
            </span>
            <h3 />
            <span style={{ fontWeight: 400 }}>
                Additionally, you will have the option to share your data yourself,
                including with BeWorldClass Education Ltd, for certain purposes such as
                entering competitions. Once you have chosen to make specific data public
                (e.g., an answer to a characteristic), the school or BeWorldClass Education
                Ltd may share this information on social media or on the beworldclass.org
                website, but only with your explicit consent.
            </span>
            <h3>International transfers</h3>
            <span style={{ fontWeight: 400 }}>
                Many of our external third parties are based outside the UK so their
                processing of your personal data will involve a transfer of data outside the
                UK.&nbsp; Whenever we transfer your personal data out of the UK, we ensure a
                similar degree of protection is afforded to it by ensuring at least one of
                the following safeguards is implemented:&nbsp;
            </span>
            <ul>
                <li style={{ fontWeight: 400 }} aria-level={1}>
                    <span style={{ fontWeight: 400 }}>
                        We will only transfer your personal data to countries that have been
                        deemed to provide an adequate level of protection for personal data; and
                    </span>
                </li>
                <li style={{ fontWeight: 400 }} aria-level={1}>
                    <span style={{ fontWeight: 400 }}>
                        Where we use certain service providers, we may use specific contracts
                        approved for use in the UK which give personal data the same protection
                        it has in the UK.&nbsp;
                    </span>
                </li>
            </ul>
            <span style={{ fontWeight: 400 }}>
                Please contact us if you want further information on the specific mechanism
                used by us when transferring your personal data out of the UK.&nbsp;
            </span>
            <h3>Data security</h3>
            <span style={{ fontWeight: 400 }}>
                We have put in place appropriate security measures to prevent your personal
                data from being accidentally lost, used or accessed in an unauthorised way,
                altered or disclosed. In addition, we limit access to your personal data to
                those employees, agents, contractors and other third parties who have a
                business need to know. They will only process your personal data on our
                instructions and they are subject to a duty of confidentiality.&nbsp;
            </span>
            <span style={{ fontWeight: 400 }}>
                We have put in place procedures to deal with any suspected personal data
                breach and will notify you and any applicable regulator of a breach where we
                are legally required to do so.
            </span>
            <h3>Data retention</h3>
            <span style={{ fontWeight: 400 }}>
                We will only retain your personal data for as long as reasonably necessary
                to fulfil the purposes we collected it for, including for the purposes of
                satisfying any legal, regulatory, tax, accounting or reporting requirements.
                We may retain your personal data for a longer period in the event of a
                complaint or if we reasonably believe there is a prospect of litigation in
                respect to our relationship with you.
            </span>
            <span style={{ fontWeight: 400 }}>
                To determine the appropriate retention period for personal data, we consider
                the amount, nature and sensitivity of the personal data, the potential risk
                of harm from unauthorised use or disclosure of your personal data, the
                purposes for which we process your personal data and whether we can achieve
                those purposes through other means, and the applicable legal, regulatory,
                tax, accounting or other requirements.
            </span>
            <h3>Your rights and your personal data</h3>
            <span style={{ fontWeight: 400 }}>
                By law, you have a number of rights when it comes to your personal data.
                Further information and advice about your rights can be obtained from the
                data protection regulator in your country. In the UK this is the Information
                Commissioner’s Office.&nbsp; If you wish to exercise any of the rights set
                out below, please contact us.
            </span>
            <ul>
                <li style={{ fontWeight: 400 }} aria-level={1}>
                    <b>The right to be informed</b>
                    <span style={{ fontWeight: 400 }}>
                        : You have the right to be provided with clear, transparent and easily
                        understandable information about how we use your information and your
                        rights. This is why we’re providing you with the information in this
                        privacy notice.
                    </span>
                </li>
                <li style={{ fontWeight: 400 }} aria-level={1}>
                    <b>The right of access</b>
                    <span style={{ fontWeight: 400 }}>
                        : You have the right to obtain access to your information (if we are
                        processing it), and certain other information (similar to that provided
                        in this privacy notice). This is so you’re aware and can check that
                        we’re using your information in accordance with data protection law.
                    </span>
                </li>
                <li style={{ fontWeight: 400 }} aria-level={1}>
                    <b>The right to rectification</b>
                    <span style={{ fontWeight: 400 }}>
                        : You are entitled to have your information corrected if its inaccurate
                        or incomplete.
                    </span>
                </li>
                <li style={{ fontWeight: 400 }} aria-level={1}>
                    <b>The right to erasure</b>
                    <span style={{ fontWeight: 400 }}>
                        : This is also known as the right to be forgotten’ and, in simple terms,
                        enables you to request the deletion or removal of your information where
                        there is a compelling reason for us to keep using it. This is not a
                        general right, there are exceptions.
                    </span>
                </li>
                <li style={{ fontWeight: 400 }} aria-level={1}>
                    <b>The right to restrict processing</b>
                    <span style={{ fontWeight: 400 }}>
                        : You have rights to ‘block’ or suppress further use of your
                        information. When processing is restricted, we can still store your
                        information, but may not use it further. We keep lists of people who
                        have asked for further use of their information to be ‘blocked’ to make
                        sure the restriction is respected in future.
                    </span>
                </li>
                <li style={{ fontWeight: 400 }} aria-level={1}>
                    <b>The right to data portability</b>
                    <span style={{ fontWeight: 400 }}>
                        : You have rights to obtain and reuse your personal data for your own
                        purposes across different services. This is not a normal scenario for
                        companies of our nature but if you have any questions you can contact
                        us.
                    </span>
                </li>
                <li style={{ fontWeight: 400 }} aria-level={1}>
                    <b>The right to object to processing</b>
                    <span style={{ fontWeight: 400 }}>
                        : You have the right to object to certain types of processing, including
                        processing for direct marketing or where we are relying on our
                        legitimate interests for processing.
                    </span>
                </li>
                <li style={{ fontWeight: 400 }} aria-level={1}>
                    <b>The right to lodge a complaint</b>
                    <span style={{ fontWeight: 400 }}>
                        : You have the right to lodge a complaint about the way we handle or
                        process your personal data with your national data protection regulator.
                    </span>
                </li>
                <li style={{ fontWeight: 400 }} aria-level={1}>
                    <b>The right to withdraw consent</b>
                    <span style={{ fontWeight: 400 }}>
                        : If you have given your consent to anything we do with your personal
                        data, you have the right to withdraw your consent at any time (although
                        if you do so, it does not mean that anything we have done with your
                        personal data with your consent up to that point is unlawful). This
                        includes your right to withdraw consent to us using your personal data
                        for marketing purposes.&nbsp;
                    </span>
                </li>
            </ul>
            <h3 />
            <span style={{ fontWeight: 400 }}>
                Where appropriate, we will respond to your requests relating to your
                personal data in writing or by email.&nbsp; If you require our response in a
                different format, please let us know. Please note that upon receipt of such
                a request, we may come back to you requesting clarification. If you have a
                concern about the way we are collecting or using your personal data, you
                should raise your concern with us or directly to the Information
                Commissioner’s Office at https://ico.org.uk/concerns/.
            </span>
            <h3 />
            <span style={{ fontWeight: 400 }}>
                You will not have to pay a fee to access your personal data. However, we may
                charge a reasonable fee if your request is clearly unfounded, repetitive or
                excessive. Alternatively, we could refuse to comply with your request in
                these circumstances.
            </span>
            <h3 />
            <span style={{ fontWeight: 400 }}>
                We try to respond to all legitimate requests within one month. Occasionally
                it could take us longer than a month if your request is particularly complex
                or you have made a number of requests. In this case, we will notify you and
                keep you updated.
            </span>
            <h3>How to contact us</h3>
            <span style={{ fontWeight: 400 }}>
                If you have any queries regarding how we process your data, please contact
                the School using our regular contact details.
            </span>  </div> </div>);
    }
}

export default StudentPrivacyPolicy;