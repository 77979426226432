import React from "react";
import app from "../firebase";
import { onValue, ref, getDatabase } from "firebase/database";
import EvidenceFile from "./areaPage/evidenceFile";

class CustomPortfolio extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loaded: true,
            values: {},
            foundFiles: [],
            grace: null,
            portfolioData: [
            ]
        };
    }

    componentDidMount() {
        const database = getDatabase(app);
        const portfolioData = this.props.schoolData.customPortfolios?.[this.props.areaPath]?.characteristics || [];
        this.setState({ portfolioData: portfolioData })
        console.log("portfolio data", portfolioData)
        console.log("all portfolios", this.props.schoolData.customPortfolios?.[this.props.areaPath])
        console.log("area path", this.props.areaPath)

        // Loop over the portfolioData and fetch the necessary information for each area and characteristic

        let updatedValues = {}; // To store multiple updates
        portfolioData.forEach((item) => {
            const areaStatePath = ref(
                database,
                `schools/${this.props.schoolData.path}/submissions/${item.areaId}/${this.props.user.uid}`
            );

            onValue(areaStatePath, (snapshot) => {
                const data = snapshot.val();
                if (data && data.values && data.values[`description${item.characteristicId}`]) {
                    // Collect all updates first
                    updatedValues[`description${item.areaId}_${item.characteristicId}`] = data.values[`description${item.characteristicId}`];

                    // Batch the state update after all characteristics have been processed
                    this.setState((prevState) => ({
                        values: {
                            ...prevState.values,
                            [`description${item.areaId}_${item.characteristicId}`]: data.values[`description${item.characteristicId}`]
                        },
                        grace: data.grace || prevState.grace,
                        loaded: true
                    }));

                }
            });
        });

    }

    findCharacteristicName = (areaId, characteristicId) => {
        console.log("looking for area", areaId)
        const area = this.props.areaData.find(area => area.path === areaId);

        console.log("area:", area)

        if (area) {
            console.log("area found")
            const characteristic = area.characteristics.find(char => char.id == characteristicId);
            console.log("characteristic", characteristic)
            return characteristic ? characteristic.name : null;
        }
        return null;
    }

    printPage = () => {
        window.print();
    };

    handleFileFound = (index) => {
        this.setState((prevState) => ({
            foundFiles: [...prevState.foundFiles, index]
        }));
    };

    render() {
        if (!this.state.loaded) {
            return <div>Loading...</div>;
        }

        const { portfolioData, values, foundFiles } = this.state;

        // Get total award value
        let totalAwardValue = 0;
        if (this.state.grace) {
            for (let key in this.state.grace) {
                totalAwardValue += this.state.grace[key].award;
            }
        }

        // Check if total award value is greater than characteristics length * 70
        const areaComplete = totalAwardValue >= (portfolioData.length * 70);

        const characteristics = portfolioData.map((item, index) => {
            const fileFound = foundFiles.includes(item.characteristicId);
            const description = values[`description${item.areaId}_${item.characteristicId}`];
            const characteristicName = this.findCharacteristicName(item.areaId, item.characteristicId);

            return (
                description ? (
                    <div key={index} style={{
                        border: "1px solid #ddd",
                        marginBottom: "10px",
                        padding: "10px",
                        boxShadow: "0 4px 8px 0 rgba(0, 0, 0, 0.2)",
                        pageBreakInside: "avoid"
                    }}
                        className={`row ${fileFound ? "" : "no-file-found"}`}>
                        <h3 style={{ fontFamily: "Georgia, serif", fontSize: "1.5em" }}>
                            {characteristicName && characteristicName}
                        </h3>
                        <div style={{
                            fontFamily: "Arial, sans-serif",
                            marginBottom: "10px",
                            width: fileFound ? "50%" : "100%"
                        }}>
                            <p>{description}</p>
                        </div>

                        <div className="col-6">
                            <EvidenceFile
                                disabled={true}
                                schoolData={this.props.schoolData}
                                uid={this.props.user.uid}
                                index={item.characteristicId}
                                areaPath={item.areaId}
                                onFileFound={this.handleFileFound}
                            />
                        </div>

                    </div>
                ) : null
            );
        });

        return (
            <div style={{ padding: "2rem", backgroundColor: "#f9f9f9", borderRadius: "10px", boxShadow: "0px 0px 10px rgba(0,0,0,0.1)" }}>
                <div className="row no-gutters justify-content-center no-print">
                    <div className="col-auto">
                        <button className="btn btn-primary" onClick={this.printPage}>
                            Save/Print
                        </button>
                    </div>
                </div>



                {areaComplete && (
                    <>
                        <div className="row no-gutters justify-content-center mt-5">
                            {this.props.schoolData.portfolioHeader &&
                                <div className="col-auto text-center">
                                    <img src={this.props.schoolData.portfolioHeader} alt="Certificate Seal" style={{ height: "100px" }} />
                                </div>
                            }
                        </div>
                        <div className="row no-gutters justify-content-center mt-5">
                            <div className="col-md-12 text-center" style={{ paddingBottom: "5rem" }}>
                                <p style={{ fontFamily: "Times New Roman, serif", fontSize: "1.2rem", fontStyle: "italic" }}>
                                    In recognition of outstanding commitment and significant accomplishment, we hereby recognise
                                </p>
                                <h2 style={{ fontFamily: "Georgia, serif", fontSize: "2.5rem" }}>
                                    {this.props.user.firstName} {this.props.user.lastName}
                                </h2>
                                <h3 className="text-light-color" style={{ fontFamily: "Arial, sans-serif", fontSize: "2rem" }}>
                                    {this.props.schoolData.name}
                                </h3>
                                <h4 className="text-light-color" style={{ fontFamily: "Arial, sans-serif", fontSize: "1.5rem" }}>
                                    Year {this.props.user.yearGroup}
                                </h4>
                                <p style={{ fontFamily: "Times New Roman, serif", fontSize: "1.2rem", fontStyle: "italic" }}>
                                    for successfully completing the <strong>{this.props.schoolData.customPortfolios?.[this.props.areaPath]?.name}</strong> program.
                                    This achievement is a testament to their exemplary skills and characteristics demonstrated in this area.
                                </p>
                            </div>
                            <div className="col-4 text-center" style={{ margin: "auto" }}>

                                <p style={{ fontFamily: "Times New Roman, serif", fontSize: "1.2rem", fontStyle: "italic", margin: "auto" }}>
                                    {this.props.area?.motto.replace("because I", "")}
                                </p>

                            </div>
                            {this.props.schoolData.customPortfolios?.[this.props.areaPath]?.imageURL &&
                                <div className="col-4 text-center" style={{ padding: "2rem", }}>
                                    <img width="175px" src={this.props.schoolData.customPortfolios?.[this.props.areaPath]?.imageURL} alt="Area" />
                                </div>
                            }
                            <div className="col-4 text-center" style={{ margin: "auto" }}>
                                <p style={{ fontFamily: "Times New Roman, serif", fontSize: "1.2rem", fontStyle: "italic" }}>
                                    Awarded this year of {new Date().getFullYear()}
                                </p>
                            </div>
                        </div>

                        <div className="page-break"></div>
                    </>
                )}

                <h4 style={{ fontFamily: "Times New Roman, serif", fontSize: "1.5rem", fontStyle: "italic" }}>
                    {this.props.area?.motto}
                </h4>

                <div className="row no-gutters justify-content-center mt-3">
                    {characteristics}
                </div>
            </div>
        );
    }
}

export default CustomPortfolio;
