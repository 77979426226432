import React, { createRef } from "react";
import { getFunctions, httpsCallable } from "firebase/functions";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ReactMarkdown from 'react-markdown';
import 'bootstrap/dist/css/bootstrap.min.css';

import Whiteboard from "./whiteboard";

class StudentChat extends React.Component {
    constructor(props) {
        super(props);
        this.chatEndRef = createRef(); // Ref for scrolling
        this.state = {
            userMessage: "",
            conversationHistory: [],
            sendingMessage: false,
            threadId: null, // New state to store the thread ID
            whiteboardData: null // State to store the whiteboard data if valid JSON is received

        };
    }

    handleMessageChange = (event) => {
        this.setState({ userMessage: event.target.value });
    }

    scrollToBottom = () => {
        if (this.chatEndRef.current) {
            this.chatEndRef.current.scrollIntoView({ behavior: 'smooth' });
        }
    }

    sendMessage = () => {
        const functions = getFunctions();
        const useAssistant = httpsCallable(functions, 'useAssistant');
        const { schoolPath, assistantId } = this.props;
        const { userMessage, conversationHistory, threadId } = this.state;

        if (!userMessage.trim()) {
            alert('Please enter a message.');
            return;
        }

        this.setState({ sendingMessage: true });

        this.setState(prevState => ({
            conversationHistory: [...prevState.conversationHistory, { role: 'user', message: userMessage }],
            userMessage: "" // Clear the input field immediately after appending
        }), this.scrollToBottom); // Scroll to bottom after appending

        useAssistant({
            schoolId: schoolPath,
            assistantId: assistantId,
            userMessage: userMessage,
            conversationHistory: JSON.stringify(conversationHistory, null, 2),
            threadId: threadId
        }).then((result) => {
            const lastMessage = result.data.messages[result.data.messages.length - 1];
            const messages = lastMessage.content[0].text.value;

            let whiteboardData = null;
            try {
                whiteboardData = JSON.parse(messages);
                if (typeof whiteboardData !== 'object' || Array.isArray(whiteboardData) || !whiteboardData.hasOwnProperty('rows')) {
                    whiteboardData = null;
                }
            } catch (e) {
                whiteboardData = null;
            }

            this.setState(prevState => ({
                conversationHistory: [...prevState.conversationHistory, { role: 'assistant', message: whiteboardData? whiteboardData.speech : messages}],
                sendingMessage: false,
                threadId: result.data.threadId,
                whiteboardData // Save the parsed JSON or null if not valid
            }), this.scrollToBottom);
        }).catch((error) => {
            console.error('Error sending message:', error);
            alert('Failed to send message.');
            this.setState({ sendingMessage: false });
        });
    }



    render() {
        const { userMessage, conversationHistory, sendingMessage } = this.state;

        return (
            <div className="container mt-5">
                <div className="text-center mb-4">
                    {this.props.assistantId === "asst_Iijw8p2ym9HHDIEg2FYssbCF" ?
                        <img src="https://portalvhdspj4vyrwb46p2b.blob.core.windows.net/dingdongdiary/logos/image_40ccc015-7405-4696-b596-2889415be201.png"
                            style={{ height: "10vh" }} ></img>

                        :
                        <div style={{ height: "10vh" }}>
                        </div>
                    }
                </div>
                <div className="row">
                    {this.state.whiteboardData &&
                        <div className="col-8">
                            <Whiteboard jsonData={this.state.whiteboardData} />
                        </div>
                    }
                    <div className={this.state.whiteboardData ? "col-4" : ""}>
                        <div className="card mb-3" style={{ height: "65vh", overflowY: "auto" }}>
                            <div className="card-body">
                                {conversationHistory.length > 0 ? (
                                    conversationHistory.map((entry, index) => (
                                        <div key={index} className={`d-flex mb-3 ${entry.role === 'user' ? 'justify-content-end' : 'justify-content-start'}`}>
                                            <div className={`card ${entry.role === 'user' ? 'bg-primary text-white' : 'bg-light text-dark'}`} style={{ maxWidth: '75%' }}>
                                                <div className="card-body">
                                                    <ReactMarkdown>{entry.message}</ReactMarkdown>
                                                </div>
                                            </div>
                                        </div>
                                    ))
                                ) : (
                                    <p className="text-muted">How can I help you today?</p>
                                )}
                                {sendingMessage && <div className="d-flex mb-3 justify-content-start">
                                    <div className="card bg-light text-dark">
                                        <div className="card-body">
                                            <div className="typing">
                                                <div className="dot"></div>
                                                <div className="dot"></div>
                                                <div className="dot"></div>
                                            </div>
                                        </div>
                                    </div>
                                </div>}
                                <div ref={this.chatEndRef}></div>
                            </div>
                        </div>
                        <div className="input-group my-3">
                            <input
                                type="text"
                                value={userMessage}
                                onChange={this.handleMessageChange}
                                placeholder="Type your message here"
                                className="form-control"
                            />
                            <button className="btn btn-primary" onClick={this.sendMessage}>
                                <FontAwesomeIcon icon="paper-plane" className="me-2" />
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default StudentChat;
