import React, { Component } from 'react';
import { CSVLink } from 'react-csv';
import AnimatedPieChart from './animatedPieChart';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import IndividualSubmissionDetails from './individualSubmissionDetails';
import TalkToData from './talkToData';
import ReportGeneratorHome from './reportGenerator/reportGeneratorHome';

class FilterableCharts extends Component {
    constructor(props) {
        super(props);
        this.state = {
            tab: null,
            userSubmissions: [],
            filters: {
                areaName: null,
                characteristic: null,
                location: null,
                affects: null,
                durationOfImpact: null,
                subject: null,
                supportedBy: null,
                role: null,
                other: null,
                yearGroup: null, // New filter for Year Group
                class: null      // New filter for Class
            },
            isLoading: false,
            chartDataCache: {},
            topSubmissions: [],
            averageConfidence: 'N/A',
            averageUnderstanding: 'N/A',
            totalBadges: 0,
            totalSubmissions: 0,
            selectedSubmission: null // New state for tracking selected submission
        };
        this.filterSubmissionsTimeout = null;
    }

    componentDidMount() {
        this.filterSubmissions();
    }

    componentDidUpdate(prevProps, prevState) {
        if (
            prevState.filters !== this.state.filters ||
            prevProps.extendedSubmissions !== this.props.extendedSubmissions
        ) {
            if (this.filterSubmissionsTimeout) {
                clearTimeout(this.filterSubmissionsTimeout);
            }
            this.filterSubmissionsTimeout = setTimeout(() => {
                this.filterSubmissions();
            }, 300);
        }
    }

    // Helper method to get unique Year Groups
    getUniqueYearGroups = () => {
        const { extendedSubmissions } = this.props;
        const yearGroups = extendedSubmissions
            .map(sub => sub.yearGroup)
            .filter(Boolean);
        return Array.from(new Set(yearGroups));
    }

    filterSubmissions = () => {
        const { extendedSubmissions } = this.props;
        const { filters } = this.state;

        this.setState({ isLoading: true });

        let filteredSubmissions = Array.isArray(extendedSubmissions) ? [...extendedSubmissions] : [];

        const chartDataCache = {
            areaName: {},
            characteristic: {},
            location: {},
            affects: {},
            durationOfImpact: {},
            subject: {},
            supportedBy: {},
            role: {},
            other: {}
        };

        let topSubmissions = [];
        let totalConfidence = 0;
        let totalUnderstanding = 0;
        let confidenceCount = 0;
        let understandingCount = 0;
        let totalBadges = 0;

        filteredSubmissions = filteredSubmissions.filter(submission => {
            let isValid = true;
            Object.keys(filters).forEach(filterKey => {
                const filterValue = filters[filterKey];
                const submissionValue = submission[filterKey];
                if (filterValue) {
                    if (filterKey === 'class') {
                        // Exact match for class (case-insensitive)
                        if (
                            !submissionValue ||
                            typeof submissionValue !== 'string' ||
                            submissionValue.toLowerCase() !== filterValue.toLowerCase()
                        ) {
                            isValid = false;
                        }
                    } else if (filterKey === 'yearGroup') {
                        // Non-strict equality for yearGroup to handle type differences
                        if (
                            submissionValue == filterValue // Using '==' instead of '==='
                        ) {
                            // Do nothing, it's a match
                        } else {
                            isValid = false;
                        }
                    } else {
                        // Partial match for other string filters
                        if (
                            !submissionValue ||
                            typeof submissionValue !== 'string' ||
                            !submissionValue.toLowerCase().includes(filterValue.toLowerCase())
                        ) {
                            isValid = false;
                        }
                    }
                }
            });

            if (isValid) {
                Object.keys(chartDataCache).forEach(key => {
                    const groupName = submission[key];
                    if (groupName) {
                        if (!chartDataCache[key][groupName]) {
                            chartDataCache[key][groupName] = 0;
                        }
                        chartDataCache[key][groupName]++;
                    }
                });

                const score =
                    Number(submission.understanding || 0) +
                    Number(submission.impressiveSorting || 0) +
                    Number(submission.valueEstimate || 0) * 0.01;
                if (topSubmissions.length < 5) {
                    topSubmissions.push({ submission, score });
                    topSubmissions.sort((a, b) => b.score - a.score);
                } else if (score > topSubmissions[4].score) {
                    topSubmissions[4] = { submission, score };
                    topSubmissions.sort((a, b) => b.score - a.score);
                }

                if (submission.confidence !== undefined) {
                    totalConfidence += Number(submission.confidence);
                    confidenceCount++;
                }

                if (submission.understanding !== undefined) {
                    totalUnderstanding += Number(submission.understanding);
                    understandingCount++;
                }

                if (submission.badge !== false) {
                    totalBadges++;
                }
            }

            return isValid;
        });

        const formattedChartData = {};
        Object.keys(chartDataCache).forEach(key => {
            const groupedData = chartDataCache[key];
            const total = Object.values(groupedData).reduce((sum, value) => sum + value, 0);

            if (total > 0) {
                const noDataCount = filteredSubmissions.length - total;
                formattedChartData[key] = [
                    ...Object.keys(groupedData).map(name => ({
                        name,
                        percentage: ((groupedData[name] / filteredSubmissions.length) * 100).toFixed(2)
                    })),
                    ...(noDataCount > 0
                        ? [{ name: "None", percentage: ((noDataCount / filteredSubmissions.length) * 100).toFixed(2) }]
                        : [])
                ];
            } else {
                formattedChartData[key] = [{ name: "None", percentage: "100.00" }];
            }
        });

        const averageConfidence = confidenceCount > 0 ? (totalConfidence / confidenceCount).toFixed(2) : 'N/A';
        const averageUnderstanding = understandingCount > 0 ? (totalUnderstanding / understandingCount).toFixed(2) : 'N/A';

        this.setState({
            userSubmissions: filteredSubmissions,
            chartDataCache: formattedChartData,
            isLoading: false,
            topSubmissions: topSubmissions.map(item => item.submission),
            averageConfidence,
            averageUnderstanding,
            totalBadges,
            totalSubmissions: filteredSubmissions.length
        });
    };

    handleFilterChange = (filterKey, value) => {
        this.setState(prevState => ({
            filters: {
                ...prevState.filters,
                [filterKey]: value || null
            }
        }));
    };

    handleSubmissionClick = (submission) => {
        this.setState({ selectedSubmission: submission });
    };

    handleBackToCharts = () => {
        this.setState({ selectedSubmission: null });
    };

    renderFilterableChart = (filterKey, title) => {
        const { filters, isLoading, chartDataCache } = this.state;
        const uniqueValues = chartDataCache[filterKey] ? chartDataCache[filterKey].map(data => data.name) : [];
        return (
            <div className="col-md-4 mb-4" key={filterKey}>
                <div className="card h-100">
                    <div className="card-header">
                        <h5 className="card-title">{`Submissions by ${title}`}</h5>
                    </div>
                    <div className="card-body">
                        <select
                            className="form-select mb-3"
                            value={filters[filterKey] || ''}
                            onChange={(e) => this.handleFilterChange(filterKey, e.target.value)}
                            disabled={isLoading}
                        >
                            <option value="">{`All ${title}`}</option>
                            {uniqueValues.map(option => (
                                <option key={option} value={option}>{option}</option>
                            ))}
                        </select>
                        <AnimatedPieChart title={""} data={chartDataCache[filterKey]} labels={true} />
                    </div>
                </div>
            </div>
        );
    };

    handleTabClick = (path) => {
        this.setState({ tab: path });
    }

    render() {
        const {
            userSubmissions,
            isLoading,
            topSubmissions,
            averageConfidence,
            averageUnderstanding,
            totalBadges,
            totalSubmissions,
            selectedSubmission,
            filters
        } = this.state;

        if (selectedSubmission) {
            return <IndividualSubmissionDetails submission={selectedSubmission} onBack={this.handleBackToCharts} />;
        }

        const filterKeys = [
            { key: 'areaName', title: 'Area' },
            { key: 'characteristic', title: 'Characteristic' },
            { key: 'location', title: 'Location' },
            { key: 'affects', title: 'Affects' },
            { key: 'durationOfImpact', title: 'Duration of Impact' },
            { key: 'subject', title: 'Subject' },
            { key: 'supportedBy', title: 'Supported By' },
            { key: 'role', title: 'Role' },
            { key: 'other', title: 'Other' }
        ];

        return (
            <div className="container">
                {this.state.tab === "talkToData" &&
                    <div className="card card-dashboard">
                        <div className="card-body">
                            <h3 className="card-title">Talk to Data</h3>
                            <hr />
                            <TalkToData
                                schoolPath={this.props.schoolData.path}
                                extendedSubmissions={this.props.extendedSubmissions} />
                        </div>
                    </div>
                }

                {this.state.tab === "reportGen" &&
                    <div className="card card-dashboard">
                        <div className="card-body">
                            <h3 className="card-title">Report Generator</h3>
                            <hr />
                            <ReportGeneratorHome
                                schoolData={this.props.schoolData}
                                extendedSubmissions={this.props.extendedSubmissions} />
                        </div>
                    </div>
                }
                {isLoading && <div>Loading...</div>}

                {!this.state.tab &&
                    <>
                        <div className='row'>
                            <div className="col-md-4">
                                {/* Filters Card */}
                                <div className="card mb-4">
                                    <div className="card-header">
                                        <h5 className="card-title">Filters</h5>
                                    </div>
                                    <div className="card-body">
                                        {/* Year Group Filter */}
                                        <div className="mb-3">
                                            <label htmlFor="yearGroup" className="form-label">Year Group</label>
                                            <select
                                                id="yearGroup"
                                                className="form-select"
                                                value={filters.yearGroup || ''}
                                                onChange={(e) => this.handleFilterChange('yearGroup', e.target.value)}
                                                disabled={isLoading}
                                            >
                                                <option value="">All Year Groups</option>
                                                {this.getUniqueYearGroups().map(yg => (
                                                    <option key={yg} value={yg}>{yg}</option>
                                                ))}
                                            </select>
                                        </div>
                                        {/* Class Filter */}
                                        <div className="mb-3">
                                            <label htmlFor="class" className="form-label">Class</label>
                                            <input
                                                type="text"
                                                id="class"
                                                className="form-control"
                                                value={filters.class || ''}
                                                onChange={(e) => this.handleFilterChange('class', e.target.value)}
                                                disabled={isLoading}
                                                placeholder="Enter class"
                                            />
                                        </div>
                                    </div>
                                </div>

                                {/* Top Achievements Card */}
                                <div className="card mb-4 shadow-sm">
                                    <div className="card-body">
                                        <div className="my-4">
                                            <h3 className="text-primary">
                                                <FontAwesomeIcon icon="fa-trophy" />
                                                &nbsp; Top Achievements
                                            </h3>
                                            <ul className="list-group list-group-flush">
                                                {topSubmissions.map((submission, index) => (
                                                    <li
                                                        key={index}
                                                        className="list-group-item d-flex justify-content-between align-items-center"
                                                        onClick={() => this.handleSubmissionClick(submission)} // Handle click to select submission
                                                        style={{ cursor: 'pointer' }}
                                                    >
                                                        <span>{`${submission.firstName} ${submission.lastName}`}</span>
                                                        <span className="text-muted">{submission.source}</span>
                                                    </li>
                                                ))}
                                            </ul>
                                        </div>
                                    </div>
                                </div>

                                {/* Conditional Rendering for CSV and Tabs */}
                                {!this.props.mini &&
                                    <div className="card mb-4">
                                        <div className="card-body text-center">
                                            <div className="d-flex justify-content-around mt-4">
                                                <CSVLink
                                                    data={userSubmissions}
                                                    headers={[
                                                        { label: 'First Name', key: 'firstName' },
                                                        { label: 'Last Name', key: 'lastName' },
                                                        { label: 'Area', key: 'areaName' },
                                                        { label: 'Characteristic', key: 'characteristic' },
                                                        { label: 'Location', key: 'location' },
                                                        { label: 'Affects', key: 'affects' },
                                                        { label: 'Duration of Impact', key: 'durationOfImpact' },
                                                        { label: 'Subject', key: 'subject' },
                                                        { label: 'Supported By', key: 'supportedBy' },
                                                        { label: 'Role', key: 'role' },
                                                        { label: 'Other', key: 'other' }
                                                    ]}
                                                    filename={"filtered_submissions.csv"}
                                                    className="btn btn-outline-success"
                                                    disabled={isLoading}
                                                >
                                                    Download CSV
                                                </CSVLink>
                                                <button className="btn btn-outline-primary" onClick={() => this.handleTabClick("talkToData")}>
                                                    Talk to Data
                                                </button>
                                                {/* <button className="btn btn-outline-secondary" onClick={() => this.handleTabClick('reportGen')}>
                                                    Report Generator
                                                </button> */}
                                            </div>
                                        </div>
                                    </div>
                                }

                                {/* Statistics Cards */}
                                <div className="card shadow-sm">
                                    <div className="card-body">
                                        <div className="my-4 text-center">
                                            <h3 className="text-success">
                                                <FontAwesomeIcon icon="fa-chart-line" />
                                                Confidence
                                            </h3>
                                            <p className="display-4 font-weight-bold text-success">{averageConfidence}/5</p>
                                        </div>

                                        <div className="my-4 text-center">
                                            <h3 className="text-warning">
                                                <FontAwesomeIcon icon="fa-award" />
                                                Badges
                                            </h3>
                                            <p className="display-4 font-weight-bold text-warning">{totalBadges}</p>
                                        </div>
                                        <div className="my-4 text-center">
                                            <h3 className="text-danger">
                                                <FontAwesomeIcon icon="fa-file-alt" />
                                                Achievements
                                            </h3>
                                            <p className="display-4 font-weight-bold text-danger">{totalSubmissions}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            {/* Charts Section */}
                            <div className="col-md-8">
                                <div className="row">
                                    {filterKeys.map(({ key, title }) => this.renderFilterableChart(key, title))}
                                </div>
                            </div>
                        </div>
                    </>
                }
            </div>
        );
    }
}

export default FilterableCharts;
