import React from "react";
import { Formik, Field, Form } from "formik";
import { signInWithEmailAndPassword, sendPasswordResetEmail, getAuth } from "firebase/auth";
import app from './firebase.js';

class SignIn extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            errors: {},
            submitAttempt: false,
            values: {},
            showForgotPassword: false // New state to toggle view
        }
    }

    signIn = () => {
        const auth = getAuth(app);
        signInWithEmailAndPassword(auth, this.state.values.email, this.state.values.password)
            .then((userCredential) => {
                const user = userCredential.user;
            })
            .catch((error) => {
                const errorMessage = error.message;
                let errors = this.state.errors;
                errors.signIn = errorMessage.replace("Firebase: Error (auth/", "").replace(")", "");
                this.setState({ errors: errors });
            });
    }

    // Function to send password reset email
    sendResetEmail = () => {
        const auth = getAuth(app);
        sendPasswordResetEmail(auth, this.state.values.email)
            .then(() => {
                alert('Password reset email sent!');
            })
            .catch((error) => {
                alert(error.message);
            });
    }

    // Function to toggle view
    toggleForgotPassword = () => {
        this.setState({ showForgotPassword: !this.state.showForgotPassword });
    }

    render() {
        return (
            <div className="sign-in-form card">
                <Formik
                    initialValues={{}}
                    onSubmit={async values => {
                        await new Promise(resolve => setTimeout(resolve, 500));
                        this.setState({ submitAttempt: true, values: values }, () => {
                            this.signIn();
                        });
                    }}
                    validate={values => {
                        const errors = {};


                        if (!values.email) {
                            errors.email = 'Required';
                        } else if (
                            !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(
                                values.email
                            )
                        ) {
                            errors.email = 'Invalid email address';
                        }

                        if (!values.password) {
                            errors.password = 'Required'
                        }

                        this.setState({ errors: errors, values:values })
                        console.log(errors)
                        console.log(values)
                    }
                    }
                >
                    <Form>
                        {/* Render based on the state */}
                        {!this.state.showForgotPassword ? (
                            <>
                                {/* Normal Sign In */}


                                <img src="https://i0.wp.com/beworldclass.org/wp-content/uploads/2022/06/cropped-BeWorldClass-transparent-rgb.png?resize=144%2C42&ssl=1" />
                                <div style={{ textAlign: "left", paddingTop: "2rem" }}>
                                    <h4 className="form-heading">Log in</h4>

                                    <div className="form-field">
                                        <label >Email Address</label>
                                        <Field name="email" type="email" className={`${this.state.errors.email && this.state.submitAttempt ? "is-invalid" : ""} form-control`} placeholder=" " />
                                        <div class="invalid-feedback">{this.state.errors.email}</div>
                                    </div>

                                    <div className="form-field">
                                        <label >Password</label>
                                        <Field name="password" type="password" className={`${this.state.errors.password && this.state.submitAttempt ? "is-invalid" : ""} form-control`} placeholder=" " />
                                        <div class="invalid-feedback">{this.state.errors.password}</div>
                                    </div>

                                    <div className="form-field">
                                        <button className="btn btn-primary" type="submit">Login</button>
                                        {this.state.errors.signIn && <div className="alert alert-danger">{this.state.errors.signIn}</div>}
                                    </div>

                                    <div className="form-field">
                                        <button type="button" className="btn btn-link" onClick={this.toggleForgotPassword}>
                                            Forgot Password?
                                        </button>
                                    </div>

                                </div>
                            </>
                        ) : (
                            <>
                                {/* Forgot Password View */}
                                <div className="form-field">
                                    <label>Email Address</label>
                                    <Field name="email" type="email" className="form-control" placeholder=" " />
                                </div>
                                <div className="form-field">
                                    <button type="button" className="btn btn-primary" onClick={this.sendResetEmail}>
                                        Send Recovery Email
                                    </button>
                                </div>
                                <div className="form-field">
                                    <button type="button" className="btn btn-link" onClick={this.toggleForgotPassword}>
                                        Back to Sign In
                                    </button>
                                </div>
                            </>
                        )}
                    </Form>
                </Formik>
            </div>
        );
    }
}

export default SignIn;







// import React from "react";
// import { Formik, Field, Form, ErrorMessage } from "formik";
// import { signInWithEmailAndPassword, sendPasswordResetEmail, getAuth } from "firebase/auth";
// import app from './firebase.js';



// class SignIn extends React.Component {

//     constructor(props) {
//         super(props);
//         this.state = {
//             errors: {},
//             submitAttempt: false,
//             values: {}

//         }
//     }

//     componentDidMount() {



//     }

//     signIn = () => {

//         const auth = getAuth(app);
//         signInWithEmailAndPassword(auth, this.state.values.email, this.state.values.password)
//             .then((userCredential) => {
//                 // Signed in
//                 const user = userCredential.user;
//                 // ...
//             })
//             .catch((error) => {
//                 const errorCode = error.code;
//                 const errorMessage = error.message;
//                 let errors = this.state.errors
//                 errors.signIn = errorMessage
//                     .replace("Firebase: Error (auth/", "")
//                     .replace(")", "")

//                 this.setState({ errors: errors })


//             });
//     }


//     sendResetEmail = () => {
//         const auth = getAuth(app);
//         sendPasswordResetEmail(auth, this.state.values.email)
//             .then(() => {
//                 alert('Password reset email sent!');
//             })
//             .catch((error) => {
//                 alert(error.message);
//             });
//     }

//     render() {

//         return (
//             <div className="sign-in-form card">
//                 <Formik
//                     initialValues={{}}
//                     onSubmit={async values => {
//                         await new Promise(resolve => setTimeout(resolve, 500));
//                         if (Object.keys(this.state.errors).length === 0) {

//                             console.log("submit")
//                             this.setState({ submitAttempt: true, values: values }, () => {
//                                 this.signIn();
//                             });

//                         }
//                         else {
//                             console.log("invalid fields, this is my state", this.state.errors)
//                             this.setState({ submitAttempt: true })
//                         }

//                     }}
//                     validate={values => {
//                         const errors = {};


//                         if (!values.email) {
//                             errors.email = 'Required';
//                         } else if (
//                             !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(
//                                 values.email
//                             )
//                         ) {
//                             errors.email = 'Invalid email address';
//                         }

//                         if (!values.password) {
//                             errors.password = 'Required'
//                         }

//                         this.setState({ errors: errors })
//                         console.log(errors)
//                         console.log(values)
//                     }
//                     }
//                 >

//                     <Form >
//                         <img src="https://i0.wp.com/beworldclass.org/wp-content/uploads/2022/06/cropped-BeWorldClass-transparent-rgb.png?resize=144%2C42&ssl=1" />
//                         <div style={{ textAlign: "left", paddingTop: "2rem" }}>
//                             <h4 className="form-heading">Log in</h4>

//                             <div className="form-field">
//                                 <label >Email Address</label>
//                                 <Field name="email" type="email" className={`${this.state.errors.email && this.state.submitAttempt ? "is-invalid" : ""} form-control`} placeholder=" " />
//                                 <div class="invalid-feedback">{this.state.errors.email}</div>
//                             </div>

//                             <div className="form-field">
//                                 <label >Password</label>
//                                 <Field name="password" type="password" className={`${this.state.errors.password && this.state.submitAttempt ? "is-invalid" : ""} form-control`} placeholder=" " />
//                                 <div class="invalid-feedback">{this.state.errors.password}</div>
//                             </div>

//                             <div className="form-field">
//                                 <button className="btn btn-primary" type="submit">Login</button>
//                                 {this.state.errors.signIn && <div className="alert alert-danger">{this.state.errors.signIn}</div>}
//                             </div>

//                             <div className="form-field">
//                                 <button type="button" className="btn btn-link" onClick={this.sendResetEmail}>
//                                     Forgot Password?
//                                 </button>
//                             </div>

//                         </div>
//                     </Form >
//                 </Formik >
//             </div >
//         );
//     }
// }

// export default SignIn;