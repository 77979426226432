import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { getDatabase, ref, child, get } from "firebase/database";
import { Carousel, Card } from 'react-bootstrap';
import './widget.css';

const WebsiteWidgetBanner = () => {
    const [quotes, setQuotes] = useState([]);
    const { schoolPath } = useParams();
    const database = getDatabase();

    useEffect(() => {
        const fetchWidgetData = async () => {
            const widgetPath = `schools/${schoolPath}/public/websiteWidget`;
            const snapshot = await get(child(ref(database), widgetPath));
            if (snapshot.exists()) {
                setQuotes(snapshot.val());
            } else {
                console.log("No data available");
            }
        };

        fetchWidgetData();
    }, [schoolPath]);

    return (
        <div style={{ backgroundColor: 'transparent' }}>
            <Carousel indicators={false}>
                {quotes.map((quote, index) => (
                    <Carousel.Item key={index}>
                        <div className="d-flex justify-content-center align-items-center" style={{ minHeight: "100vh" }}>
                            <Card className="card-custom">
                                <Card.Body>
                                    <Card.Text style={{ fontSize: "0.9rem" }}> "{quote}" </Card.Text>
                                </Card.Body>
                            </Card>
                        </div>
                    </Carousel.Item>
                ))}
            </Carousel>
        </div>
    );
};

export default WebsiteWidgetBanner;
