import React from "react";
import TopCharacteristics from './TopCharacteristics';
import Badge from "../../student/badge";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faHandRock, faStar, faBalanceScale, faAward, faTrophy, faRobot } from '@fortawesome/free-solid-svg-icons'
import SpiderChart from "./SpiderChart";
import WordCloud from "./wordCloud";
import FullReport from "./fullReport";
//import ViewsingleCharacteristic from "../../viewSingleCharacteristic";
import ViewSingleCharacteristic from "../../viewSingleCharacteristic";
import BarGraph from "../barGraph";
import DateLineGraph from "../dateLineGraph";
import AnimatedPieChart from "../animatedPieChart";
import TalkToData from "../talkToData";
import ReportGeneratorHome from "../reportGenerator/reportGeneratorHome";


class Insight extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedArea: null,
            areasData: [],
            createFullReport: false,
            aiTab: null,
        }
    }

    extractConfidenceAndMarksValues = (values) => {
        const totalConfidenceValues = [];
        const totalMarksValues = [];
        const uids = [];

        values.forEach(value => {
            Object.entries(value).forEach(([key, val]) => {
                if (key.includes("confidence")) totalConfidenceValues.push(parseInt(val));
                if (value.grace && value.grace.award) totalMarksValues.push(parseFloat(value.grace.award));
            });

            if (!uids.includes(value.uid)) uids.push(value.uid);
        });

        return { totalConfidenceValues, totalMarksValues, uids };
    }

    calculateAreaValues = (values, areaPath) => {
        const areaValues = this.filterAreaValues(values, areaPath);
        const { confidenceValues, dateAggregates } = this.processConfidenceValues(areaValues);
        const { marksValues, badgesCount, specialBadgesCount } = this.processGraceValues(areaValues, dateAggregates);
        const descriptions = this.extractDescriptions(areaValues);
        const wordCounts = this.tokenizeAndCountWords(descriptions);

        // Calculate averages for confidence and marks
        const confidenceAverage = this.calculateAverage(confidenceValues);
        const marksAverage = this.calculateAverage(marksValues);

        // Initialize counters for subjects and achievements
        let subjectCounts = {};
        let totalAchievements = 0;
        let achievementsAffectingOthersCount = {};
        let didCounts = {}


        // Process embeddings and update counts
        areaValues.forEach(value => {
            if (value.embeddings) {
                this.processEmbeddings(value.embeddings, subjectCounts, didCounts, totalAchievements, achievementsAffectingOthersCount);
            }
        });
        console.log("did counts", didCounts)

        console.log("word counts", wordCounts);
        console.log("subject counts", subjectCounts);
        console.log("total achievements", totalAchievements);
        console.log("achievements affecting others", achievementsAffectingOthersCount);
        console.log("confidence average", confidenceAverage);
        console.log("marks average", marksAverage);

        const dateDetails = this.calculateDateDetails(dateAggregates);

        console.log("date details", dateDetails);
        return {
            areaValues,
            confidenceAverage,
            marksAverage,
            badgesCount,
            specialBadgesCount,
            dateDetails,
            subjectCounts,
            totalAchievements,
            achievementsAffectingOthersCount,
            didCounts
        };
    }

    processEmbeddings = (embeddings, subjectCounts, didCounts, totalAchievements, achievementsAffectingOthersCount) => {
        Object.keys(embeddings).forEach(key => {
            const embeddingData = embeddings[key].embeddingData;
            const subjectArray = embeddingData.subject;
            if (subjectArray && subjectArray.length > 0) {
                const subject = subjectArray[0];
                subjectCounts[subject] = (subjectCounts[subject] || 0) + 1;
            }


            const didArray = embeddingData.did;
            if (didArray && didArray.length > 0) {
                const did = didArray[0];
                didCounts[did] = (didCounts[did] || 0) + 1;
            }


            const achievementArray = embeddingData.achievement;
            if (didArray && didArray.length > 0) {
                const achievement = achievementArray[0];
                achievementsAffectingOthersCount[achievement] = (achievementsAffectingOthersCount[achievement] || 0) + 1;
            }


        });
    }

    filterAreaValues = (values, areaPath) => values.filter(value => value.area === areaPath);

    processConfidenceValues = (areaValues) => {
        let confidenceValues = [];
        let dateAggregates = {};

        areaValues.forEach(value => {
            Object.keys(value)
                .filter(key => key.includes("confidence"))
                .forEach(confidenceKey => {
                    const confidenceValue = parseInt(value[confidenceKey], 10);
                    confidenceValues.push(confidenceValue);
                    this.updateDateAggregates(confidenceKey, confidenceValue, dateAggregates, value);
                });
        });

        return { confidenceValues, dateAggregates };
    }

    processGraceValues = (areaValues, dateAggregates) => {
        let marksValues = [];
        let badgesCount = 0;
        let specialBadgesCount = 0;

        areaValues.forEach(value => {
            if (value.grace) {
                Object.entries(value.grace).forEach(([graceKey, { award }]) => {
                    if (award) {
                        const mark = parseFloat(award);
                        marksValues.push(mark);
                        badgesCount += mark >= 70 && mark < 80 ? 1 : 0;
                        specialBadgesCount += mark >= 80 ? 1 : 0;
                        this.updateDateAggregates(graceKey, mark, dateAggregates, value, true);
                    }
                });
            }
        });

        return { marksValues, badgesCount, specialBadgesCount };
    }

    updateDateAggregates = (key, value, aggregates, originalValue, isMark = false) => {
        const dateKey = `date${key.match(/\d+/)[0]}`;
        const dateValue = originalValue[dateKey];
        if (dateValue) {
            if (!aggregates[dateValue]) {
                aggregates[dateValue] = { count: 0, totalConfidence: 0, totalMarks: 0 };
            }
            if (isMark) {
                aggregates[dateValue].totalMarks += value;
            } else {
                aggregates[dateValue].totalConfidence += value;
                aggregates[dateValue].count++;
            }
        }
    }

    extractDescriptions = (areaValues) => {
        return areaValues.flatMap(value =>
            Object.keys(value)
                .filter(key => key.includes("description"))
                .map(descriptionKey => value[descriptionKey])
        );
    }

    tokenizeAndCountWords = (descriptions) => {
        const stopwords = [
            "a"];
        return descriptions
            .join(" ")
            .split(/\s+/)
            .map(word => word.toLowerCase().replace(/[.,!?]/g, ''))
            .filter(word => !stopwords.includes(word))
            .reduce((acc, word) => {
                acc[word] = (acc[word] || 0) + 1;
                return acc;
            }, {});
    }

    calculateAverage = (values) => values.length ? values.reduce((a, b) => a + b, 0) / values.length : 0;

    calculateDateDetails = (dateAggregates) => {
        return Object.keys(dateAggregates).sort().map(date => ({
            date,
            count: dateAggregates[date].count,
            averageConfidence: dateAggregates[date].totalConfidence / dateAggregates[date].count,
            averageMarks: dateAggregates[date].totalMarks / dateAggregates[date].count
        }));
    }


    calculateCharacteristicValues = (areaValues, characterId) => {
        let confidenceValues = [];
        let marksValues = [];
        let badgesCount = 0;
        let specialBadgesCount = 0;
        let totalGap = 0; // Keep track of the total confidence gap
        let gapCount = 0; // Keep track of how many gaps we've measured

        areaValues.forEach(value => {
            let confidence = null
            if (value["confidence" + characterId]) {
                confidence = parseInt(value["confidence" + characterId])
                const date = value["date" + characterId]
                confidenceValues.push(confidence);
            }

            if (value.grace && value.grace[characterId] && value.grace[characterId].award) {
                let mark = parseInt(value.grace[characterId].award);
                marksValues.push(mark);

                if (mark >= 70 && mark < 80) {
                    badgesCount++;
                } else if (mark >= 80) {
                    specialBadgesCount++;
                }

                // If confidence value is present, calculate the gap using the separate function
                if (confidence !== null) {
                    let gap = this.calculateConfidenceGap(confidence, mark);
                    console.log("con gap", gap)
                    totalGap += gap;  // Collect the raw gap
                    gapCount++;
                }
            }
        });

        const confidenceAverage = this.calculateAverage(confidenceValues);

        const marksAverage = this.calculateAverage(marksValues);
        const averageGap = gapCount > 0 ? totalGap / gapCount : 0;  // Average of the gaps

        return {
            confidenceAverage,
            marksAverage,
            badgesCount,
            specialBadgesCount,
            averageGap  // Add this to the returned object
        };
    }



    calculateConfidenceGap = (confidence, mark) => {
        const expectedMark = 20 + (confidence * 13);  // Maps confidence [0, 5] to marks [20, 85]
        return mark - expectedMark;
    };

    computeSpiderChartData = (values) => {
        const spiderData = [];
        const allWordCounts = {}; // to store consolidated word counts
        let allNumberOfMarks = 0
        let allConfidenceCount = 0
        const computedData = this.props.areasData.map((areaConfig) => {
            const {
                confidenceAverage,
                marksAverage,
                badgesCount,
                specialBadgesCount,
                topWords,
                numberOfMarks,
                confidenceCount,
                dateDetails,
                achievementsAffectingOthersCount,
                subjectCounts,
                didCounts
            } = this.calculateAreaValues(values, areaConfig.path);


            allNumberOfMarks += numberOfMarks
            allConfidenceCount += confidenceCount
            // Accumulate word counts from each area:
            topWords?.forEach(({ text, size }) => {
                allWordCounts[text] = (allWordCounts[text] || 0) + size;
            });



            spiderData.push({ label: areaConfig.path, value: marksAverage });

            console.log("spider mark", marksAverage)

            return {
                path: areaConfig.path,
                imageUrl: areaConfig.imageUrl,
                confidenceAverage,
                marksAverage,
                badgesCount,
                specialBadgesCount,
                numberOfMarks,
                confidenceCount,
                dateDetails,
                achievementsAffectingOthersCount,
                subjectCounts,
                didCounts
            };
        });

        // Sort and get top 200 words:
        const top200Words = Object.entries(allWordCounts)
            .sort((a, b) => b[1] - a[1])
            .slice(0, 200)
            .map(([word, count]) => ({ text: word, size: count }));

        console.log("top 200", top200Words);

        return { spiderData, computedData, top200Words, allNumberOfMarks, allConfidenceCount }; // added top200Words to the return object
    }







    calculateAverage = (values) => {
        return values.length > 0 ? Math.round(values.reduce((a, b) => a + b, 0) / values.length) : null;
    }

    handleAreaClick = (path) => {
        this.setState({ selectedArea: path === this.state.selectedArea ? null : path });
    }

    handleAITabClick = (path) => {
        this.setState({ aiTab: path });
    }


    combineDateData = (computedData) => {
        const combinedData = {};

        computedData.forEach(area => {
            area.dateDetails.forEach(dateDetail => {
                const { date, count, averageConfidence, averageMarks } = dateDetail;
                if (combinedData[date]) {
                    combinedData[date].count += count;
                    combinedData[date].averageConfidence += averageConfidence;
                    combinedData[date].averageMarks += averageMarks;
                } else {
                    combinedData[date] = { count, averageConfidence, averageMarks };
                }
            });
        });

        return Object.entries(combinedData).map(([date, details]) => ({
            date,
            count: details.count,
            averageConfidence: details.averageConfidence / computedData.length, // average if needed
            averageMarks: details.averageMarks / computedData.length // average if needed
        }));
    }





    render() {
        console.log("school data in insight", this.props.schoolData)
        const { values } = this.props;
        const { selectedArea } = this.state;
        const { totalConfidenceValues, totalMarksValues, uids } = this.extractConfidenceAndMarksValues(values);
        const { spiderData, computedData, top200Words, allNumberOfMarks, allConfidenceCount } = this.computeSpiderChartData(values);
        const dateData = this.combineDateData(computedData)
        console.log("computed data", computedData)

        let computedCombinedIntegers = {};

        function addValues(source, target) {
            for (let key in source) {
                let value = source[key];
                if (Number.isInteger(value)) { // Check if the value is an integer
                    if (!target[key]) {
                        target[key] = 0; // Initialize if the key doesn't exist in target
                    }
                    target[key] += value; // Add the value to the corresponding key in target
                } else if (typeof value === 'object' && value !== null && !Array.isArray(value)) {
                    // Check if every key within the object contains numeric values
                    if (Object.values(value).every(val => typeof val === 'number')) {
                        if (!target[key]) {
                            target[key] = {}; // Initialize if the key doesn't exist
                        }
                        addValues(value, target[key]); // Recursively sum numeric values in nested objects
                    }
                }
            }
        }

        computedData.forEach(item => addValues(item, computedCombinedIntegers));

        console.log("computedCombinedIntegers", computedCombinedIntegers); // Output will be { a: 22, c: { d: 8, e: 18 } }


        const achievementsDataForChart = [
            { name: "Others", percentage: parseFloat(30) },
            { name: "Self", percentage: parseFloat((100 - 70).toFixed(2)) },
        ];

        function transformDataForPieChart(data) {
            // Calculate the total sum of the values
            let total = Object.values(data).reduce((sum, current) => sum + current, 0);

            // Create a new array with objects containing modified keys and percentage values
            const transformedDataForChart = Object.entries(data).map(([key, value]) => {
                // Remove 'Learning' and 'Literature' from the key and trim extra spaces
                const newKey = key.replace(/Learning|Literature|\(|\)|Church, Mosque, Etc\.|Something I did/g, '').trim();

                // Calculate the percentage of the total
                const percentageValue = parseFloat((value / total * 100).toFixed(2));
                // Return a new object for the chart
                return { name: newKey, percentage: percentageValue };
            });

            return transformedDataForChart;
        }

        // Example usage
        const inputData = {
            "Learning English Literature": 1,
            "Learning Art": 2,
            "Learning Music": 1
        };
        console.log("subject counts", computedCombinedIntegers.subjectCounts)
        const subjectDataForChart = transformDataForPieChart(computedCombinedIntegers.subjectCounts);
        const locationDataForChart = transformDataForPieChart(computedCombinedIntegers.didCounts);
        const affectingOthersForChart = transformDataForPieChart(computedCombinedIntegers.achievementsAffectingOthersCount)
        console.log("subjectDataForChart", subjectDataForChart)


        return (
            <div style={{ textAlign: "center" }}>
                {this.state.selectedArea === null &&

                    <>
                        {this.state.aiTab === "talkToData" &&
                            <div className="card card-dashboard">
                                <div className="card-body ">
                                    <h3 className="card-title">Talk to data
                                    </h3>
                                    <hr />
                                    <TalkToData
                                        schoolPath={this.props.schoolData.path}
                                        extendedSubmissions={this.props.extendedSubmissions} />
                                </div>
                            </div>
                        }

                        {this.state.aiTab === "reportGen" &&
                            <div className="card card-dashboard">
                                <div className="card-body ">
                                    <ReportGeneratorHome
                                        schoolData={this.props.schoolData}
                                        extendedSubmissions={this.props.extendedSubmissions} />
                                </div>
                            </div>
                        }

                        {this.state.aiTab == null &&

                            <>


                                <div className="row">
                                    <div className="col-md-3 ">
                                        <div className="card card-dashboard" >

                                            <div className="card-body ">
                                                <h3 className="card-title">Characteristics
                                                </h3>
                                                <hr />

                                                <div className="row"  >
                                                    <div style={{ textAlign: "left" }} className="col-12">
                                                        <span style={{ color: "#4e79a7" }} >●</span>  Understanding <br />

                                                        <span style={{ color: "#f28e2c" }} >●</span>  Confidence
                                                    </div>
                                                </div>
                                                <div style={{ paddingTop: "1rem" }}>
                                                </div>


                                                {computedData.map((data) => (
                                                    <div className="row" onClick={() => this.handleAreaClick(data.path)} style={{ cursor: "pointer", marginBottom: "0.5rem" }}>
                                                        <div style={{ textAlign: "left" }} className="col">
                                                            <h6> {data.path} </h6>

                                                        </div>

                                                        <div className="col" >
                                                            <BarGraph data={[
                                                                { label: 'Mark', value: data.marksAverage, maxValue: 100 },
                                                                { label: 'confidence', value: data.confidenceAverage, maxValue: 5 },
                                                            ]} showPercentageLabel={false} />
                                                        </div>
                                                    </div>
                                                ))}
                                            </div>
                                        </div>

                                    </div>
                                    <div style={{ paddingLeft: "4rem" }} className="col-md-9 ">

                                        <div className="card card-dashboard">
                                            <div className="card-body text-center">
                                                <h3 className="card-title">
                                                    <FontAwesomeIcon icon={faRobot} className="mr-2" />
                                                    Auto Analysis
                                                </h3>
                                                <hr />
                                                <div className="d-flex justify-content-around mt-4">
                                                    <button className="btn btn-primary" onClick={() => this.handleAITabClick("talkToData")}>
                                                        Talk to Data
                                                    </button>
                                                    <button className="btn btn-secondary" onClick={() => this.handleAITabClick('reportGen')}>
                                                        Report Generator
                                                    </button>
                                                </div>
                                            </div>
                                        </div>

                                        <div style={{ paddingTop: "4rem" }} >

                                        </div>

                                        <div className="card  card-dashboard">

                                            <div className="card-body ">
                                                <h3 className="card-title">When
                                                </h3>
                                                <hr />
                                                <DateLineGraph data={dateData} metric="count" />

                                            </div>
                                        </div>

                                        <div style={{ paddingTop: "4rem" }} >

                                        </div>

                                        <div className="card card-shadow card-dashboard">

                                            <div className="card-body ">
                                                <div className="card-body ">
                                                    <h3 className="card-title">How</h3>
                                                    <hr />

                                                    <div className="row">
                                                        <div className="col-6 card" >
                                                            <AnimatedPieChart title={`Subjects`} data={subjectDataForChart} labels={true} />
                                                        </div>
                                                        <div style={{ textAlign: "left" }} className="col-6 card">

                                                            <AnimatedPieChart title={`Affecting others`} data={affectingOthersForChart} labels={true} />
                                                        </div>

                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div style={{ paddingTop: "4rem" }} >

                                        </div>
                                        <div className="row">
                                            <div className="col-6">

                                                <div className="card card-shadow card-dashboard">

                                                    <div className="card-body ">
                                                        <div className="card-body ">
                                                            <h3 className="card-title">Where</h3>
                                                            <hr />

                                                            <AnimatedPieChart title={`Location`} data={locationDataForChart} labels={true} />



                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </>}

                        {this.state.selectedArea &&
                            <>
                                <div style={{ textAlign: "left" }} >

                                    {(!this.state.createFullReport === true) &&
                                        <button onClick={() => this.setState({ createFullReport: true })} className="btn btn-primary">Create AI analysis report with GRACE</button>
                                    }
                                    {this.state.createFullReport === true &&
                                        <FullReport
                                            allNumberOfMarks={allNumberOfMarks}
                                            allConfidenceCount={allConfidenceCount}
                                            uids={uids}
                                            computedData={computedData}
                                            spiderData={spiderData}
                                            top200Words={top200Words}
                                            areaData={this.props.areasData}
                                            values={this.props.values}
                                            schoolData={this.props.schoolData}
                                        />
                                    }




                                    <div className="row">
                                        {computedData.map((data) => (
                                            <div className="col" onClick={() => this.handleAreaClick(data.path)} style={{ cursor: "pointer" }}>
                                                <img width="150rem" src={data.imageUrl} />
                                                <div className="row">
                                                    <div className="col-6">
                                                        <p>{data.confidenceAverage > 0 ? <><FontAwesomeIcon icon={faHandRock} />  {data.confidenceAverage}/5</> : <></>}</p>
                                                        <p>{data.marksAverage > 0 ? <><FontAwesomeIcon icon={faStar} />  {data.marksAverage}/100</> : <></>}</p>

                                                    </div>
                                                    <div className="col-6">
                                                        <p>{data.badgesCount > 0 ? <><FontAwesomeIcon icon={faAward} />  {data.badgesCount}</> : <></>}</p>
                                                        <p>{data.specialBadgesCount > 0 ? <><FontAwesomeIcon icon={faTrophy} />  {data.specialBadgesCount}</> : <></>}</p>
                                                    </div>
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                </div>

                                {selectedArea &&
                                    <div>
                                        {this.props.areasData.find(area => area.path === selectedArea).characteristics.map((characteristic) => {
                                            const { confidenceAverage, marksAverage, badgesCount, specialBadgesCount, averageGap } = this.calculateCharacteristicValues(values, characteristic.id);


                                            let cardColorClass = 'bg-success'; // default color is success (green)

                                            if (marksAverage < 60) {
                                                cardColorClass = 'bg-danger'; // red
                                            } else if (marksAverage < 70) {
                                                cardColorClass = 'bg-warning'; // yellow
                                            }

                                            return (
                                                <div className={`card text-white ${cardColorClass} mb-3`} >
                                                    <div className="card-header">
                                                        <h3>I {characteristic.name}</h3>
                                                    </div>
                                                    <div className="card-body row">
                                                        <div className="col-6 mb-3">
                                                            <div className="card text-dark bg-light">
                                                                <div className="card-body text-center">
                                                                    <FontAwesomeIcon icon={faStar} size="3x" className="mb-3" />
                                                                    <h5 className="card-text">Average mark: {marksAverage || "No values"}/100</h5>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div className="col-6 mb-3">
                                                            <div className="card text-dark bg-light">
                                                                <div className="card-body text-center">
                                                                    <FontAwesomeIcon icon={faHandRock} size="3x" className="mb-3" />
                                                                    <h5 className="card-text">Average confidence: {confidenceAverage || "No values"}/5</h5>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div className="col-6 mb-3">
                                                            <div className="card text-dark bg-light">
                                                                <div className="card-body text-center">
                                                                    <FontAwesomeIcon icon={faBalanceScale} size="3x" className="mb-3" /> {/* You might want a different icon here */}
                                                                    <h5 className="card-text"> {averageGap + 13 < 0 && "Overconfident"}{averageGap - 13 > 0 && "Underconfident"}  {averageGap * -1}</h5>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div className="col-6 mb-3">
                                                            <div className="card text-dark bg-light">
                                                                <div className="card-body text-center">
                                                                    <FontAwesomeIcon icon={faAward} size="3x" className="mb-3" /> {/* You might want a different icon here */}
                                                                    <h5 className="card-text">  Badges <FontAwesomeIcon icon={faAward} />:  {badgesCount > 0 ? + badgesCount : "0"}  -   Special <FontAwesomeIcon icon={faTrophy} />: {specialBadgesCount > 0 ? specialBadgesCount : "0"}</h5>

                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            );



                                        })}
                                    </div>
                                }
                                <div className="row">
                                    <TopCharacteristics areaData={this.props.areasData} values={this.props.values} schoolData={this.props.schoolData} />
                                </div>

                                <div className="row">
                                    {/* <div className="col-12">
                        <SpiderChart data={spiderData} />
                    </div>
                    <div className="col-12">
                        <WordCloud words={top200Words} />
                    </div> */}
                                </div>
                            </>}
                    </>
                }
            </div >
        );
    }
}

export default Insight;