import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ReactMarkdown from 'react-markdown';
import { getFunctions, httpsCallable } from "firebase/functions";

class ChatBot extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            userMessage: "",
            conversationHistory: [],
            sendingMessage: false
        };
    }

    handleMessageChange = (event) => {
        this.setState({ userMessage: event.target.value });
    }


    sendMessage = async () => {
        const { userMessage } = this.state;

        if (!userMessage.trim()) {
            alert('Please enter a message.');
            return;
        }

        this.setState({ sendingMessage: true });

        // Prepare the payload to send to the cloud function
        const payload = {
            schoolId: this.props.schoolData.path,
            messages: [
                ...this.state.conversationHistory, // Spread all existing messages from conversationHistory
                {
                    role: 'user', // The role for the new message
                    content: userMessage // The content of the new user message
                }
            ]


        };

        try {
            // Replace 'yourCloudFunctionName' with the actual name of your callable function
            const functions = getFunctions();
            const sendMessageFunction = httpsCallable(functions, 'teacherChat');
            const result = await sendMessageFunction(payload);
            console.log("result", result)
            const responseMessage = result.data.choices[0].message.content; // Assuming this is the format of your response

            this.setState(prevState => ({
                conversationHistory: [...prevState.conversationHistory, { role: 'user', content: userMessage }, { role: 'assistant', content: responseMessage }],
                userMessage: "", // Clear the message input after sending
                sendingMessage: false
            }));
        } catch (error) {
            console.error("Error sending message: ", error);
            this.setState({ sendingMessage: false });
            alert("Failed to send message. Please try again.");
        }
    }

    render() {
        const { userMessage, conversationHistory, sendingMessage } = this.state;

        return (
            <div className="" >
                <div className="" >
                    <div className="">
                        <div style={{paddingTop:"1rem"}}>

                         
                            <div className="card" style={{ overflow: "auto", height: "80vh" }} >
                                <div className="card-body" style={{padding:"2rem"}} >


                                    {conversationHistory.length > 0 ? (
                                        conversationHistory.map((entry, index) => (
                                            <div key={index}><strong>{entry.role==="user"? "You":"GPT" }:</strong> <ReactMarkdown>{entry.content}</ReactMarkdown></div>
                                        ))
                                    ) : (
                                        <p className="text-muted"> How can I help you today? </p>
                                    )}
                                    <div className="input-group my-3">
                                        <textarea
                                            type="text"
                                            value={userMessage}
                                            onChange={this.handleMessageChange}
                                            placeholder={this.state.conversationHistory.length > 0 ? "Follow on the conversation. Do you want anything changing? Or do you need anything new related to this?" : "Type a message."}
                                            className="form-control"
                                            disabled={sendingMessage}
                                        />
                                        <button className="btn btn-primary" onClick={this.sendMessage} disabled={sendingMessage}>
                                            <FontAwesomeIcon icon="paper-plane" className="me-2" />
                                            {sendingMessage ? 'Sending...' : ''}
                                        </button>
                                    </div>
                                    <p style={{ textAlign: "center" }} className="text-muted">AI can make mistakes. Always check results.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default ChatBot;
