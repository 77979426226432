import React from "react";
import { Formik, Form, Field } from "formik";
import { getDatabase, set, ref } from "firebase/database";
import app from "../firebase";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faXmark } from '@fortawesome/free-solid-svg-icons';

class EditStudentDetails extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            showModal: false,
            message: "",
        };
    }

    toggleModal = () => {
        this.setState((prevState) => ({ showModal: !prevState.showModal, message: "" }));
    };

    updateUser = (values) => {
        const database = getDatabase(app);
        const studentRef = ref(database, 'schools/' + this.props.schoolData.path + '/students/' + this.props.user.uid);

        set(studentRef, {
            details: {
                firstName: values.firstName || "",
                lastName: values.lastName || "",
                yearGroup: values.yearGroup || 0,
                class: values.class || "",
                gender: values.gender || "",
                sen: values.sen || false,
                pupilPremium: values.pupilPremium || false,
                moreAble: values.moreAble || false,
                lowAttaining: values.lowAttaining || false,
            },
        })
            .then(() => {
                this.setState({ message: "Student details updated successfully." });
            })
            .catch((error) => {
                console.error("Error updating student:", error);
                this.setState({ message: "Failed to update student details." });
            });
    };

    render() {
        const { user } = this.props;

        return (
            <div>
                <button className="btn btn-outline-secondary mb-3" onClick={this.toggleModal}>
                    Edit student details
                </button>

                {this.state.showModal && (
                    <div className="my-modal text-start">
                        <div className="my-modal-content">
                            <div className="my-modal-header">
                                <span className="close" onClick={this.toggleModal}>&times;</span>
                                <h2>Edit Student Details</h2>
                            </div>
                            <Formik
                                initialValues={{
                                    firstName: user.firstName || "",
                                    lastName: user.lastName || "",
                                    yearGroup: user.yearGroup || "",
                                    class: user.class || "",
                                    gender: user.gender || "",
                                    sen: user.sen || false,
                                    pupilPremium: user.pupilPremium || false,
                                    moreAble: user.moreAble || false,
                                    lowAttaining: user.lowAttaining || false,
                                }}
                                onSubmit={(values) => {
                                    this.updateUser(values);
                                    this.toggleModal();
                                }}
                            >
                                {({ errors, touched }) => (
                                    <Form>
                                        <div className="my-modal-body form-group">
                                            <div className="row">
                                                <div className="col-md-6">
                                                    <label>First Name</label>
                                                    <Field name="firstName" type="text" className="form-control" />
                                                </div>
                                                <div className="col-md-6">
                                                    <label>Last Name</label>
                                                    <Field name="lastName" type="text" className="form-control" />
                                                </div>
                                            </div>
                                            <div className="row mt-3">
                                                <div className="col-md-6">
                                                    <label>Year Group</label>
                                                    <Field name="yearGroup" type="number" className="form-control" />
                                                </div>
                                                <div className="col-md-6">
                                                    <label>Class/Tutor Group</label>
                                                    <Field name="class" type="text" className="form-control" />
                                                </div>
                                            </div>
                                            <div className="row mt-3">
                                                <div className="col-md-6">
                                                    <label>Gender</label>
                                                    <Field as="select" name="gender" className="form-control">
                                                        <option value="">Select Gender</option>
                                                        <option value="male">Male</option>
                                                        <option value="female">Female</option>
                                                        <option value="other">Other</option>
                                                    </Field>
                                                </div>
                                            </div>
                                            
                                            <div className="mt-4 text-end">
                                                <button type="button" className="btn btn-secondary me-2" onClick={this.toggleModal}>
                                                    Cancel
                                                </button>
                                                <button type="submit" className="btn btn-primary">
                                                    Save
                                                </button>
                                            </div>
                                            {this.state.message && <div className="mt-3">{this.state.message}</div>}
                                        </div>
                                    </Form>
                                )}
                            </Formik>
                        </div>
                    </div>
                )}
            </div>
        );
    }
}

export default EditStudentDetails;
