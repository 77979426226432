import React from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faStar, faArrowUpRightDots } from '@fortawesome/free-solid-svg-icons'
import { getDatabase, ref, set, onValue } from "firebase/database";
import EvidenceImprovement from "./evidenceImprovement.js";
import ViewImprovement from "./viewImprovement.js";
import app from "../../firebase.js"



class NextStepsPage extends React.Component {

    constructor(props) {
        super(props);
        this.state =
        {
            loaded: false,
            databaseValues: [],
            noValues: false,
            selectedImprovement: null,
            selectedViewImprovement: null,
        }

    }

    componentDidMount() {
        const database = getDatabase(app);

        this.props.areaData.forEach(area => {
            const areaStatePath = ref(database, 'schools/' + this.props.schoolData.path + '/submissions/' + area.path + '/' + this.props.user.uid);

            onValue(areaStatePath, (snapshot) => {
                const data = snapshot.val();
                const inProgress = data?.improvements || {};
                const improvements = Object.keys(data?.values || {})
                    .filter(key => key.includes('improvements'))
                    .map(key => {
                        const id = key.replace('improvements', '');
                        // Find the characteristic with the matching id in the area.characteristics array
                        const characteristic = area.characteristics.find(char => char.id == id);

                        return {
                            id,
                            text: data?.values[key],
                            confidence: parseInt(data?.values['confidence' + id], 10) ? parseInt(data?.values['confidence' + id], 10) : 0,
                            graceAward: parseInt(data?.grace?.[id]?.award, 10) ? parseInt(data?.grace?.[id]?.award, 10) : 0,
                            area: area.path,
                            characteristic: characteristic?.name // Add the name of the characteristic here
                        };
                    });

                const result = { area: area.path, data, improvements, inProgress };

                this.setState(prevState => {
                    // Check if the area already exists in the state
                    const existingAreaIndex = prevState.databaseValues.findIndex(dbValue => dbValue.area === result.area);

                    // If the area already exists, replace it with the updated result; otherwise, add the new result
                    const updatedDatabaseValues = existingAreaIndex >= 0
                        ? [...prevState.databaseValues.slice(0, existingAreaIndex), result, ...prevState.databaseValues.slice(existingAreaIndex + 1)]
                        : [...prevState.databaseValues, result];

                    return { loaded: true, databaseValues: updatedDatabaseValues };
                })
            });
        });
    }

    renderInProgress() {
        const { databaseValues } = this.state;

        if (!databaseValues) return null;

        return (
            <div className="col-6">
                <div className="card" style={{ marginBottom: "25px" }}>
                    <div className="card-body">
                        <h3 className="card-title col-9">In Progress</h3>
                        <hr />
                        {this.props.areaData.map((area) => {
                            const areaData = databaseValues.find(result => result.area === area.path);
                            const inProgressItems = Object.values(areaData?.inProgress || {})
                                .filter(item => item.state === "in progress");

                            return (
                                <>
                                    {inProgressItems.map((item, id) => (
                                        <div draggable="true" className="alert alert-primary" role="alert" key={id}>
                                            <h6><strong>Task: </strong> {item.text}</h6>
                                            <div className="row">
                                                <button type="button" className="btn btn-outline-danger col">
                                                    <span aria-hidden="true">Cancel</span>
                                                </button>
                                                <button onClick={() => { this.openEvidenceImprovement(item) }} type="button" className="btn btn-outline-success col">
                                                    <span aria-hidden="true">Complete</span>
                                                </button>
                                            </div>
                                        </div>
                                    ))}
                                </>
                            );
                        })}
                    </div>
                </div>
            </div>
        );
    }

    renderComplete() {
        const { databaseValues } = this.state;

        if (!databaseValues) return null;

        return (
            <div className="col-6">
                <div className="card" style={{ marginBottom: "25px" }}>
                    <div className="card-body">
                        <h3 className="card-title col-9">Complete</h3>
                        <hr />
                        {this.props.areaData.map((area) => {
                            const areaData = databaseValues.find(result => result.area === area.path);
                            const completeItems = Object.values(areaData?.inProgress || {})
                                .filter(item => item.state === "complete");

                            return (
                                <>
                                    {completeItems.map((item, id) => (
                                        <div className="alert alert-success" role="alert" key={id}>
                                            <h6><strong>Task: </strong> {item.text}</h6>
                                            <button className="btn btn-outline-primary" onClick={() => {this.setState({selectedViewImprovement:item})}} >View</button>
                                        </div>
                                    ))}
                                </>
                            );
                        })}
                    </div>
                </div>
            </div>
        );
    }





    renderImprovements() {
        const { databaseValues } = this.state;

        if (!databaseValues) return null;

        return databaseValues.map((result, index) => (
            <div key={index}>
                <h3>Area Name: {result.area}</h3>
                {result.improvements.map((improvement, impIndex) => {
                    // Find the matching characteristic name based on the improvement ID
                    return (
                        <div key={impIndex}>
                            <h4>I {improvement.characteristic}</h4> {/* Displaying the characteristic name */}

                            <p>{improvement.text}</p>
                            {/* <p><strong>ID:</strong> {improvement.id}</p>
                            <p><strong>Confidence:</strong> {improvement.confidence}</p>
                            <p><strong>Grace Award:</strong> {improvement.graceAward}</p> */}
                        </div>
                    );
                })}
            </div>
        ));
    }

    renderImprovementsByArea() {
        const { databaseValues } = this.state;

        if (!databaseValues) return null;

        return (
            <div className="row">
                {databaseValues.map((result, index) => {
                    const areaObject = this.props.areaData.find(area => area.path === result.area);
                    //const areaName = areaObject ? areaObject.name : "Unknown Area"; // Default to "Unknown Area" if not found
                    return (
                        <div key={index} className="col-6">
                            <div className="card bg-white mb-3">
                                <div className="card-body">
                                    <h5 className="card-title"><img width="100px" src={areaObject.imageUrl} /> </h5>
                                    <hr />
                                    {result.improvements.map((improvement, index) => (
                                        <div key={index}>
                                            <h6>I {improvement.characteristic}</h6>
                                            <p><strong>Improvement: </strong> {improvement.text}</p>
                                            <p> {improvement.confidence} </p>
                                            {this.renderSetInProgressButton(improvement.id, improvement)}
                                            <hr />
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>
                    );
                })}
            </div>
        );
    }





    renderTopImprovements() {
        const { databaseValues } = this.state;

        if (!databaseValues) return null;

        const allImprovements = databaseValues.flatMap(result => result.improvements);
        const sortedImprovements = [...allImprovements].sort((a, b) => (a.confidence * 10 + a.graceAward) - (b.confidence * 10 + b.graceAward));
        const least5Confident = sortedImprovements.slice(0, 5);
        const top5Confident = sortedImprovements.slice(-5).reverse();

        const renderImprovements = (improvements) => (
            improvements.map((improvement, index) => (
                <div key={index}>
                    <h5>I {improvement.characteristic}</h5>
                    <p><strong>Task: </strong> {improvement.text}</p>
                    <p><strong>Confidence + Excellence Points: </strong> {improvement.confidence * 10 + improvement.graceAward}
                        </p>
                        {this.renderSetInProgressButton(improvement.id, improvement)}
                    <hr />
                </div>
            ))
        );

        return (
            <div className="row">
                <div className="col-6">
                    <div className="card text-white bg-success mb-3">
                        <div className="card-body">
                            <h5 className="card-title"><FontAwesomeIcon icon={faStar} /> My strongest characteristics</h5>
                            <hr />
                            {renderImprovements(top5Confident)}
                        </div>
                    </div>
                </div>
                <div className="col-6">
                    <div className="card text-white bg-danger mb-3">
                        <div className="card-body">
                            <h5 className="card-title"><FontAwesomeIcon icon={faArrowUpRightDots} /> My top characteristics to improve</h5>
                            <hr />
                            {renderImprovements(least5Confident)}
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    setInProgress(improvementId, improvementData) {
        const database = getDatabase(app);
        const areaStatePath = ref(database, 'schools/' + this.props.schoolData.path + '/submissions/' + improvementData.area + "/" + this.props.user.uid + '/improvements/' + improvementId);
        let improvement = improvementData
        improvement.state = "in progress"
        set(areaStatePath, improvement);
    }

    renderSetInProgressButton(improvementId, improvementData) {
        return (
            <button onClick={() => this.setInProgress(improvementId, improvementData)} className="btn btn-primary">
                Set task
            </button>
        );
    }

    openEvidenceImprovement(improvementData) {
        this.setState({ selectedImprovement: improvementData })
    }

    closeEvidenceImprovement = () => {
      
        this.setState({ selectedImprovement: null })
    }

    closeViewImprovement = () => {
      
        this.setState({ selectedViewImprovement: null })
    }



    render() {

        return (
            <div className="container">
                <div className="row">
                    <div className="col-12">
                        <div class="jumbotron jumbotron-fluid">
                            <div class="container">
                                <h1 className="display-4">Next steps </h1>
                            </div>
                        </div>
                    </div>
                    {this.renderInProgress()}
                    {this.renderComplete()}
                    {this.renderTopImprovements()}
                    {this.renderImprovementsByArea()}
                    {this.renderImprovements()}
                    {this.state.selectedImprovement && <EvidenceImprovement onCompletion={this.closeEvidenceImprovement} improvementData={this.state.selectedImprovement} schoolData={this.props.schoolData} user={this.props.user} areaData={this.state.areaData} />}
                    {this.state.selectedViewImprovement && <ViewImprovement onCompletion={this.closeViewImprovement} improvementData={this.state.selectedViewImprovement} schoolData={this.props.schoolData} user={this.props.user} areaData={this.state.areaData} />}

                </div>
                
            </div>
        );
    }
}

export default NextStepsPage;


