import React from "react";
import { getDatabase, ref, get } from "firebase/database";
import app from "../firebase";
import StudentPage from "./studentPage";
import { Spinner } from 'react-bootstrap'; // You should import Spinner from react-bootstrap.

class FindSchoolDatabase extends React.Component {

    constructor(props) {
        super(props)
        this.state = { schoolData: null, loaded: false }
    }

    componentDidMount() {

        const database = getDatabase(app);
        const schoolDatabasePath = ref(database, 'schools/' + this.props.schoolPath + '/public')

        get(schoolDatabasePath).then((snapshot) => {
            if (snapshot.exists()) {
                console.log("School from database", snapshot.val());
                this.setState({ schoolData: snapshot.val(), loaded: true })
            } else {
                console.log("No School data available");
                this.setState({ loaded: true })
            }
        }).catch((error) => {
            console.error(error);
        });

    }

    render() {

        return (
            <div>
                {this.state.schoolData &&
                    <div>
                        <StudentPage schoolData={this.state.schoolData} user={this.props.user} />
                    </div>
                }

                {!this.state.loaded &&
                    // Change the <p> tag to a Bootstrap Spinner and make it centered.
                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
                        <Spinner animation="border" role="status">
                            <span className="visually-hidden">Loading...</span>
                        </Spinner>
                    </div>
                }

                {!this.state.schoolData && this.state.loaded &&
                    <h2>This is not a valid school link</h2>
                }
            </div>
        );

    }
}

export default FindSchoolDatabase;
