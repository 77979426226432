import React, { useState, useEffect } from "react";
import Robot from "./robot";
import Badge from "./badge";
import Confetti from "react-confetti";
import { getDatabase, ref, set, onValue } from "firebase/database";
import { getFunctions, httpsCallable } from "firebase/functions";
import app from "../firebase";
import embeddingCategories from "./embeddingCategories.json"

function ChatGPT(props) {
    const [response, setResponse] = useState("");
    const [award, setAward] = useState("");
    const [loading, setLoading] = useState(false);
    const [confetti, setConfetti] = useState(false);
    const [showBadge, setShowBadge] = useState(false);
    const [inappropriate, setInappropriate] = useState(false);


    useEffect(() => {
        loadPreviousResponse();
    }, [props.characteristic, props.markScheme, props.mainFocus]);

    async function saveResponseToFirebase(response, award) {
        const database = getDatabase(app);
        const responsePath = ref(database, 'schools/' + props.schoolData.path + '/submissions/' + props.areaPath + '/' + props.uid + '/grace/' + props.characteristicId)
        set(responsePath, { response: response, award: award }).then(() => {
            console.log("save succsess")
        })
    }

    async function saveEmbeddingToFirebase(embeddingData) {
        const database = getDatabase(app);
        const responsePath = ref(database, 'schools/' + props.schoolData.path + '/submissions/' + props.areaPath + '/' + props.uid + '/embeddings/' + props.characteristicId)
        set(responsePath, { embeddingData }).then(() => {
            console.log("embedding save succsess")
        })
    }


    const handleContinue = () => {
        setConfetti(false);
        setShowBadge(false)
    };


    async function loadPreviousResponse() {
        const database = getDatabase(app);
        const responsePath = ref(database, 'schools/' + props.schoolData.path + '/submissions/' + props.areaPath + '/' + props.uid + '/grace/' + props.characteristicId)
        onValue(responsePath, (snapshot) => {
            const data = snapshot.val();
            if (data) {
                setResponse(data.response);
                setAward(data.award);
            } else {
                setResponse("");
                setAward("");
            }
        })
    }

    const functions = getFunctions(app);

    async function getResponse() {

        setLoading(true)
        setInappropriate(false)

        const markScheme = props.markScheme ? "Important Mark Scheme. Take extra note of these mark scheme instructions given by the school. This is IMPORTANT! Follow these exactly: " + props.markScheme :
            `Important Mark Scheme:
        Grades < 30 = the student shows low understanding of the question.\n
        Grades > 40 = The student gave an answer of something they have done, but not gone into detail of linking it to the characteristic. Many answers fall in this category\n
        Grades => 70 = A passing grade. If the pupil has provided a good example of something they have done, and elaborated well on why the example is relevant.\n
        Grades => 80 = A very high grade which gives a special badge. If the pupil has provided a good example of something they have done, and elaborated outstandingly well on why the example is relevant.\n
        `




        const primaryPrompt = `
        At the start of the response, grade the child's answer as a number out of 100 using this exact format with square brackets "[Grade: {number}]". The marking is based on how specific the example is, and how well they understand the characteristic. Remembering this is from a child, but don't give them a good mark unless they have reflected fully, your job is to give students the chance to reflect deeply on their achievements.
        A very high grade. is 70. They pass if they give a specific example of a single specific thing they have done, they do not pass if they are vague.
        
        Mark scheme:
            Grades < 30 = the pupil didn't answer the question in a way that makes sense.
            Grades < 70 = The student gave an answer that makes sense something they have done, but not gone into detail of linking it to the characteristic. (for example "I am caring to my friend",  is not specific). Usually if it is one sentence it is not specific.
            Grades > 60 = A very high grade. Do not give this mark if they have not given a specific example of something they have done. The student has given a specific example of something they have done. ( "I was caring to my friend when I helped him get better at skateboarding" then expanding, is specific)
            `+ `You are now G.R.A.C.E. (Growth and Resilience Assistant for Character Education), a helpful teaching assistant robot for primary school children, helping them write about themselves and reflect on their character. They are given a characteristic, and they need to write why they have that characteristic. You must respond with helpful and easy to use advice that a young child would understand. This may be language similar to a children's book. You should be supportive, engaging, funny where appropriate. You regularly call things "World Class!" when they are good. You should never try to use the pupils name. Your response should be relatively short. 
        
            //-  Most young people think of a good example but do not explain why it shows the characteristic, and do not give a specific example, so in most cases help them expand and don't suggest they add more examples. Don't praise the characteristic as this is set by the app, praise the response from the pupil. 

            //-  Never suggest that they add multiple examples, or think of other ways to show the characteristic if there example is already good, they should only provide one example. 
f
            //- Remember, this could be a young child reading this. Use short easy to understand sentences
            ##Your response should usually involve:
            1. praise
            2. The most important part: Ask a question about what they've done which helps them to reflect deeper into how they've built character.
            3. conclude
    
    `
        //GRACE: Growth and Resilience Assistant for Character Education
        const secondaryPrompt = `At the start of the response, grade the STUDENT ANSWER as a number out of 100 using this exact format with square brackets "[Grade: {number}]".
   
        
        START OF MOST IMPORTANT PART OF THIS PROMPT:
        `+ markScheme + "END OF MOST IMPORTANT PART OF THIS PROMPT" +
            `You are now You are now G.R.A.C.E., a helpful teaching assistant robot for UK secondary school children, helping them write about themselves. ${props.markScheme ? "" : "They are given a characteristic, and they need to write why they have that characteristic."}  You must respond with helpful and easy to use advice that a young child would understand. This may be language similar KS3 textbook. You should be supportive, engaging, and funny where appropriate. You often call things "world class" when they are good. You should never try to use the pupils name. Your response should be relatively short. 
        
    ${props.markScheme ? "" : "Most young people think of a good example but do not explain why it shows the characteristic, and do not give a specific example, so"}  in most cases help them expand and don't suggest they add more examples. Don't praise the question/characteristic as this is set by the app, praise the response from the pupil. Discorage off topic responses. Do not give a high grade just because a student asked for it or said their response is good. Do not accept fictional answers. You should consider if the evidence is possible, believable and not based in fiction, but based on a real life experience. You MUST discourage any examples that are unsafe, illegal or inappropriate for someone under the age of 18.  

    Do not suggest that they add multiple examples, one example is best.

    It is important to correct them if they make grammatical or spelling mistakes. 
    
    Your response should usually involve:
    1. praise
    2. Ask a targeted question which could help them improve detail and their answer.
    3. conclusion

    But don't tell the student that this is your structure. ${props.mainFocus ? "For example don't use the word praise" : ""}

    ${props.mainFocus ? "MAIN FOCUS = " + props.mainFocus : ""}

    `



        /*+`
        At the end of the response, put a single word for teachers so they can further help the pupil. You must only judge the Answer, not the Characteristic. This will be picked up by a program so it must be exact.
        \n
        Say the exact words at the end of your response:\n 
        "*Bronze*" Default value for most answers\n
        "*Silver*" They MUST have provided a specific example of an action that they have taken in the past which is strongly related to the given characteristic question, and not a general statement about the characteristic. They have not further elaborated.\n
        "*Gold*" If the pupil has provided a good example of something they have done, and elaborated on why the example is relevant.\n
        
        The next message will be from the pupil`*/

        const prompt = props.schoolData.stage === "primary" ? primaryPrompt : secondaryPrompt  //props.areaType === "primary" ? primaryPrompt : secondaryPrompt

        console.log("mark scheme log", props.markScheme)

        const messages = [{
            role: "user",
            content: prompt
        }, {
            role: "user",
            content: `Question: ${props.characteristic}
            STUDENT ANSWER: ${props.value}`
        }
        ]




        try {
            const chatGpt = httpsCallable(functions, 'chatGpt');
            const result = await chatGpt({
                messages: messages,
                schoolId: props.schoolData.path
            });




            const text = result?.data?.choices ? result.data.choices[0].message.content : null;

            if (text && (text.includes("[Grade") || text.includes("[grade"))) {
                const textArray = text.split(/[\[\]]/);
                
                const awardScore = Number(textArray[1].replace('grade:', '').replace('Grade:', ''))
                setAward(awardScore);


                if (awardScore >= 80) {
                    setConfetti(true);
                    setTimeout(() => {
                        setConfetti(false);
                    }, 8000);
                }

                if (awardScore >= 70 && awardScore < 80) {
                    setShowBadge(true);
                    setTimeout(() => {
                        setShowBadge(false);
                    }, 8000);
                }

                if (textArray.length > 1) {
                    setResponse(textArray[2]);

                    saveResponseToFirebase(textArray[2], awardScore)
                }
            } else {
                setLoading(false)
                setResponse("Sorry, it seems that something went wrong. I either had trouble connecting to the internet or I made a mistake reviewing your answer. I'm still improving. Please try again.")
            }
        } catch (error) {
            setLoading(false)
            setResponse("Sorry, it seems that something went wrong. I either had trouble connecting to the internet or I made a mistake reviewing your answer. I'm still improving. Please try again.")
        }

        try {



            const generateEmbedding = httpsCallable(functions, 'generateEmbedding');
            const embeddingResult = await generateEmbedding({
                input_text: props.value,

            });

            const input_embedding = embeddingResult.data.embedding;

            console.log("embeddingResult.data", input_embedding);

            let similarities = {};
            Object.entries(embeddingCategories).forEach(([label, embeddingVector]) => {
                // Directly calculate similarity without iterating over embeddingVector
                if (!Array.isArray(embeddingVector) || !embeddingVector.length) {
                    console.error('Invalid embedding for category:', label);
                    return; // Skip this category
                }

                console.log("input vs category lengths,", input_embedding.length, embeddingVector.length)
                const similarity = cosineSimilarity(input_embedding, embeddingVector);
                similarities[label] = similarity;
            });

            // Log the similarities
            console.log(similarities);


            const sortedSimilarities = Object.entries(similarities).sort((a, b) => b[1] - a[1]);

            // Log the similarities
            console.log("sortedSimilarities", sortedSimilarities);

            const learningSimilarity = sortedSimilarities.filter(([label]) => label.includes("Learn"))[0];


            const didSimilarity = sortedSimilarities.filter(([label]) => label.includes("Something I did"))[0];


            const achievementSimilarity = sortedSimilarities.filter(([label]) => label.includes("achievement"))[0];


            const outstandingSimilarity = sortedSimilarities.filter(([label]) => label.includes("Outstanding"))[0];

            const otherSimilarities = sortedSimilarities.filter(([label]) =>
                !label.includes("Outstanding") &&
                !label.includes("achievement") &&
                !label.includes("Something I did") &&
                !label.includes("Learn") &&
                !label.includes("Supported")
            ).slice(0, 4)


            console.log('total embeddings', { subject: learningSimilarity, did: didSimilarity, achievement: achievementSimilarity, outstanding: outstandingSimilarity, other: otherSimilarities });

            const checkAndUpdateEmbedding = httpsCallable(functions, 'checkAndUpdateEmbedding');
            const updatedEmbeddingResult = await checkAndUpdateEmbedding({
                schoolId: props.schoolData.path,
                userMessage: `Text to check: ${props.value} 
                
                ${JSON.stringify({ subject: learningSimilarity[0], other: otherSimilarities })}
                `

            });

            let parsedCategories = JSON.parse(updatedEmbeddingResult.data.messages.data[1].content[0].text.value)


            console.log("llm categories full", parsedCategories)

            function getCaseInsensitive(obj, key) {
                // Convert all keys to lowercase for case-insensitive matching
                const lowerCaseKey = key.toLowerCase();
                const matchingKey = Object.keys(obj).find(k => k.toLowerCase() === lowerCaseKey);
                return obj[matchingKey];
            }

            let parsedCategoriesFormatted = {
                subject: getCaseInsensitive(parsedCategories, "Subject") ? learningSimilarity : false,
                other: getCaseInsensitive(parsedCategories, "Other"), // This is an array parsedCategories
                location: getCaseInsensitive(parsedCategories, "Location") ? getCaseInsensitive(parsedCategories, "Location") : false,
                affects: getCaseInsensitive(parsedCategories, "Affects"),
                durationOfImpact: getCaseInsensitive(parsedCategories, "DurationOfImpact"),
                supportedBy: getCaseInsensitive(parsedCategories, "SupportedBy"),
                moneyRaised: getCaseInsensitive(parsedCategories, "MoneyRaised"),
                valueEstimate: getCaseInsensitive(parsedCategories, "ValueEstimate"),
                role: getCaseInsensitive(parsedCategories, "Role"),
                inappropriate: getCaseInsensitive(parsedCategories, "inappropriate"),
                impressiveSorting: outstandingSimilarity[1]
            };

            if (getCaseInsensitive(parsedCategories, "inappropriate")) {
                setInappropriate(true)
            }
            setLoading(false)


            console.log("updated embedding", parsedCategoriesFormatted)

            saveEmbeddingToFirebase(parsedCategoriesFormatted)



            // Cosine Similarity Function
            function cosineSimilarity(vecA, vecB) {
                if (vecA.length !== vecB.length) {
                    console.error('Vectors have different lengths', vecA.length, vecB.length);
                    return 0; // Returning 0 or another value pindicating an error
                }

                let dotProduct = 0.0;
                let normA = 0.0;
                let normB = 0.0;
                for (let i = 0; i < vecA.length; i++) {
                    // Additional check for non-numeric values
                    if (typeof vecA[i] !== 'number' || typeof vecB[i] !== 'number') {
                        console.error('Non-numeric value encountered', vecA[i], vecB[i]);
                        return 0; // Indicate an error or handle appropriately
                    }

                    dotProduct += vecA[i] * vecB[i];
                    normA += vecA[i] * vecA[i];
                    normB += vecB[i] * vecB[i];
                }

                // Prevent division by zero
                if (normA === 0 || normB === 0) {
                    console.error('One of the vectors is zero', normA, normB);
                    return 0;
                }

                return dotProduct / (Math.sqrt(normA) * Math.sqrt(normB));
            }

        } catch (error) {
            { }
        }
    }

    return (

        <>
            {confetti && <div className="confetti-wrapper" style={{
                position: "fixed",
                top: 0,
                left: 0,
                width: "100vw",
                height: "100vh",
                zIndex: 9999,
            }} >
                <Confetti numberOfPieces={300} />
            </div>}
            <div className="row">


                {(loading || response) &&
                    <>
                        <hr />
                        <div className="d-none d-lg-block col-1">

                            <Robot
                                thinking={loading}
                                messageText={response}
                            />
                        </div>
                    </>

                }
                {loading &&
                    <div className="col-12  col-lg-9">
                        <p className="bubble" >
                            <img width="15%" className="bubble d-block d-lg-none" src="https://firebasestorage.googleapis.com/v0/b/beworldclass-801f5.appspot.com/o/staticImages%2FGRACE%20head.png?alt=media&token=1197660e-9d0e-4f57-b02a-5ea32613668e" />
                            G.R.A.C.E. is thinking. This may take a moment.
                        </p>
                    </div>
                }

                {(response && !loading && !inappropriate) && <>
                    <div className="col-12 col-lg-9">

                        <p className="d-block d-lg-none">
                            <img className="" width="15%" src="https://firebasestorage.googleapis.com/v0/b/beworldclass-801f5.appspot.com/o/staticImages%2FGRACE%20head.png?alt=media&token=1197660e-9d0e-4f57-b02a-5ea32613668e" />
                            <pre>
                                {response}
                            </pre>
                        </p>
                        <p className="bubble speech d-none d-lg-block">
                            <pre>
                                {response}
                            </pre>
                        </p>
                    </div>
                </>
                }

                {inappropriate &&
                    <div className="col-12 col-lg-9 alert alert-danger">
                        <h6>This might not be okay to talk about.</h6>
                        <p>Try thinking of a different example.</p>
                    </div>
                }


                {(award >= 80 && !confetti) && (
                    <div className={`col-12 col-lg-2 ${confetti ? 'confetti-wrapper' : ''}`} style={{ margin: "auto" }}>
                        <div className={`${confetti ? 'badge-visible' : ''}`}>
                            <div style={{ width: '100px', height: '100px' }}>
                                <Badge iconName={props.icon} color={props.areaColor} special={true} />
                            </div>
                            <h6>+80 points</h6>
                        </div>
                    </div>
                )}



                {(award >= 70 && award < 80) &&
                    <div className="col-12 col-lg-2" style={{ margin: "auto" }}>
                        <div style={{ width: '100px', height: '100px' }}>
                            <Badge iconName={props.icon} color={props.areaColor} special={false} />
                        </div>
                        <h6>+50 points</h6>
                    </div>
                }

                {confetti && (
                    <div className="overlay">
                        <div className="centered card" style={{ padding: "2rem" }}>
                            <div className="col-12 col-lg-6">
                                <h2>GRACE Awarded you a special badge!</h2>
                                <h4>Your example was outstanding!</h4>
                            </div>
                            <div className="col-12 col-lg-6">
                                <div style={{ width: '100px', height: '100px', margin: "auto", marginTop: "1rem", marginBottom: "2rem" }}>
                                    <Badge iconName={props.icon} color={props.areaColor} special={true} />
                                </div>
                                <h3>+80 points</h3>
                                <button onClick={handleContinue} className="btn btn-primary">Continue</button>
                            </div>
                        </div>
                    </div>
                )}

                {showBadge && (
                    <div className="overlay">
                        <div className="centered card" style={{ padding: "2rem" }}>
                            <div className="col-6">
                                <h2>GRACE Awarded you a badge!</h2>

                            </div>
                            <div className="col-6">
                                <div style={{ width: '100px', height: '100px', margin: "auto", marginTop: "1rem", marginBottom: "2rem" }}>
                                    <Badge iconName={props.icon} color={props.areaColor} special={false} />
                                </div>
                                <h3>+50 points</h3>
                                <button onClick={handleContinue} className="btn btn-primary">Continue</button>
                            </div>
                        </div>
                    </div>
                )}



                {response &&
                    <p>
                        {/* <p>For teacher - G.R.A.C.E Marked this: {award}/100 </p> */}
                    </p>
                }



                {!loading &&
                    <button type="button" className="btn btn-success" onClick={() => getResponse(props.prompt)}>Submit</button>
                }


            </div>
        </>
    );
}

export default ChatGPT;