import { ref, set, getDatabase } from 'firebase/database';
import app from '../../firebase';
import React, { Component } from 'react';

class EvidenceImprovement extends Component {
    constructor(props) {
        super(props);
        this.state = {
            evidence: ''
        };
    }

    handleEvidenceChange = (e) => {
        this.setState({ evidence: e.target.value });
    };

    markAsComplete = () => {
        const { improvementData, schoolData, user } = this.props;
        const improvementId = improvementData.id;
        const database = getDatabase(app);
        const areaStatePath = ref(database, 'schools/' + schoolData.path + '/submissions/'+ improvementData.area +"/" + user.uid + '/improvements/' + improvementId);
        const updatedImprovementData = {
            ...improvementData,
            state: "complete",
            evidence: this.state.evidence,
        };

        set(areaStatePath, updatedImprovementData)
            .then(() => {
                // Write is complete, now you can close or perform any other action
                if (this.props.onCompletion) {
                    this.props.onCompletion();
                }
            })
            .catch(error => {
                // Handle any errors
                console.error("An error occurred:", error);
            });
    };

    render() {
        return (
            <div className="my-modal">
                <div className="my-modal-content">
                    <div className="my-modal-header">
                        <h2>Mark as complete</h2>
                    </div>
                    <div className="my-modal-body">

                        <h6>I {this.props.improvementData.characteristic} </h6>

                        <h3>Task: {this.props.improvementData.text} </h3>

                        <label>Describe what you have done, and how this shows the characteristic:</label>
                        <textarea className="form-control" value={this.state.evidence} onChange={this.handleEvidenceChange}></textarea>
                        <button className="btn btn-success mt-3" onClick={this.markAsComplete}>Mark as Complete</button>
                        <button className="btn btn-secondary mt-3 ml-2" onClick={this.props.onCompletion}>Cancel</button>
                    </div>
                </div>
            </div>
        );
    }
}

export default EvidenceImprovement;
