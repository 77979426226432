import React, { useEffect, useRef, useState } from 'react';
import * as d3 from 'd3';
import cloud from 'd3-cloud';

function WordCloud({ words: propWords }) {
    const svgRef = useRef(null);
    const [localWords, setLocalWords] = useState(propWords); // Initialize local state with propWords

    useEffect(() => {
        if (!localWords) return;

        const svg = d3.select(svgRef.current);
        svg.selectAll('*').remove();
        const width = +svg.attr('width');
        const height = +svg.attr('height');

        const wordSizeRange = d3.extent(localWords, d => d.size);
        const fontSizeScale = d3.scaleLinear()
            .domain(wordSizeRange)
            .range([10, 100]); // Adjust the min and max font size as needed

        cloud()
            .size([width, height])
            .words(localWords.map(d => ({...d, size: fontSizeScale(d.size)}))) // Scale font size here
            .padding(5) // Adjust padding
            .rotate(() => ~~(Math.random() * 2) * 90)
            .fontSize(d => d.size)
            .on('end', words => draw(words, width, height))
            .start();

        function draw(words, layoutWidth, layoutHeight) {
            const colorScale = d3.scaleOrdinal(d3.schemeCategory10);

            svg.append('rect')
                .attr('width', layoutWidth)
                .attr('height', layoutHeight)
                .attr('fill', 'white');

            svg
                .selectAll('text')
                .data(words)
                .enter().append('text')
                .style('font-size', d => `${d.size}px`)
                .style('fill', (d, i) => colorScale(i))
                .attr('text-anchor', 'middle')
                .attr('transform', d => `translate(${d.x + layoutWidth / 2}, ${d.y + layoutHeight / 2})rotate(${d.rotate})`)
                .text(d => d.text);
        }

    }, [localWords]);

    useEffect(() => {
        setLocalWords(propWords);
    }, []); // Dependency array ensures this only runs once

    useEffect(() => {
        if (propWords.length !== localWords.length) {
            setLocalWords(propWords);
        }
    }, [propWords, localWords]);

    function saveAsPNG() {
        const svgNode = svgRef.current;
        const serializer = new XMLSerializer();
        const stringifiedSvg = serializer.serializeToString(svgNode);

        const canvas = document.createElement("canvas");
        canvas.width = 1200;  // Ensure this matches the SVG width
        canvas.height = 600;  // Ensure this matches the SVG height

        const ctx = canvas.getContext("2d");
        const img = new Image();
        img.onload = function () {
            ctx.drawImage(img, 0, 0);
            const imgURL = canvas.toDataURL("image/png");
            triggerDownload(imgURL, "wordcloud.png");
        };

        img.src = `data:image/svg+xml;base64,${btoa(stringifiedSvg)}`;
    }

    function triggerDownload(imgURI, fileName) {
        const link = document.createElement('a');
        link.href = imgURI;
        link.download = fileName;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    }

    return (
        <>
            <svg ref={svgRef} width="1200" height="600"></svg>
            <button onClick={saveAsPNG}>Save as PNG</button>
        </>
    );
}

export default WordCloud;
