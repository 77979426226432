import React, { createRef } from "react";
import { getFunctions, httpsCallable } from "firebase/functions";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ReactMarkdown from 'react-markdown';
import 'bootstrap/dist/css/bootstrap.min.css';

class TalkToData extends React.Component {
    constructor(props) {
        super(props);
        this.chatEndRef = createRef(); // Ref for scrolling
        this.state = {
            userMessage: "",
            conversationHistory: [],
            sendingMessage: false,
            threadId: null // New state to store the thread ID
        };
    }

    handleMessageChange = (event) => {
        this.setState({ userMessage: event.target.value });
    }

    scrollToBottom = () => {
        if (this.chatEndRef.current) {
            this.chatEndRef.current.scrollIntoView({ behavior: 'smooth' });
        }
    }

    sendMessage = () => {
        const functions = getFunctions();
        const talkToData = httpsCallable(functions, 'talkToData');
        const { schoolPath, assistantId } = this.props;
        const { userMessage, conversationHistory, threadId } = this.state;
        console.log("School path", schoolPath)

        if (!userMessage.trim()) {
            alert('Please enter a message.');
            return;
        }

        this.setState({ sendingMessage: true });

        // Append user message to conversation history
        this.setState(prevState => ({
            conversationHistory: [...prevState.conversationHistory, { role: 'user', message: userMessage }],
            userMessage: "" // Clear the input field immediately after appending
        }), this.scrollToBottom); // Scroll to bottom after appending

        const sanitizedSubmissions = this.props.extendedSubmissions.map(submission => {
            const { firstName, lastName, ...rest } = submission; // Destructure and remove firstName, lastName
            return { ...rest }; // Return the rest of the fields
        });

        talkToData({
            schoolId: schoolPath,
            userMessage: userMessage,
            conversationHistory: JSON.stringify(conversationHistory, null, 2),
            threadId: threadId,
            fileData
            : sanitizedSubmissions
        }).then((result) => {
            const messages = result.data.message;

            this.setState(prevState => ({
                conversationHistory: [...prevState.conversationHistory, { role: 'assistant', message: messages }],
                sendingMessage: false, // Reset sendingMessage to false
                threadId: result.data.threadId // Update the thread ID from the server response
            }), this.scrollToBottom); // Scroll to bottom after adding assistant message
        }).catch((error) => {
            console.error('Error sending message:', error);
            alert('Failed to send message.');
            this.setState({ sendingMessage: false }); // Reset sendingMessage to false
        });
    }

    render() {
        const { userMessage, conversationHistory, sendingMessage } = this.state;

        return (
            <div className="container mt-5">
            
                <div className="card mb-3" style={{ height: "65vh", overflowY: "auto", textAlign:"left" }}>
                    <div className="card-body">
                        {conversationHistory.length > 0 ? (
                            conversationHistory.map((entry, index) => (
                                <div key={index} className={`d-flex mb-3 ${entry.role === 'user' ? 'justify-content-end' : 'justify-content-start'}`}>
                                    <div className={`card ${entry.role === 'user' ? 'bg-primary text-white' : 'bg-light text-dark'}`} style={{ maxWidth: '75%' }}>
                                        <div className="card-body">
                                            <ReactMarkdown>{entry.message}</ReactMarkdown>
                                        </div>
                                    </div>
                                </div>
                            ))
                        ) : (
                            <p className="text-muted">How can I help you today?</p>
                        )}
                        {sendingMessage && <div className="d-flex mb-3 justify-content-start">
                            <div className="card bg-light text-dark">
                                <div className="card-body">
                                    <div className="typing">
                                        <div className="dot"></div>
                                        <div className="dot"></div>
                                        <div className="dot"></div>
                                    </div>
                                </div>
                            </div>
                        </div>}
                        <div ref={this.chatEndRef}></div>
                    </div>
                </div>
                <div className="input-group my-3">
                    <input
                        type="text"
                        value={userMessage}
                        onChange={this.handleMessageChange}
                        placeholder="Type your message here"
                        className="form-control"
                    />
                    <button className="btn btn-primary" onClick={this.sendMessage}>
                        <FontAwesomeIcon icon="paper-plane" className="me-2" />
                    </button>
                </div>

            </div>
        );
    }
}

export default TalkToData;
