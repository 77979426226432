import React, { useState } from 'react';
import { getDatabase, ref, set } from "firebase/database";
import app from '../../firebase';

const CreateGroups = (props) => {
    // State to manage the input value for the new group
    const [groupName, setGroupName] = useState('');
    // State to manage the success or error messages
    const [message, setMessage] = useState('');

    // Handler for the form submission to add a new group
    const handleAddGroup = (e) => {
        e.preventDefault();

        const db = getDatabase(app);
        const schoolId = props.schoolData.path;
        const groupsRef = ref(db, `schools/${schoolId}/public/groups`);
        
        // Get the existing groups, or use an empty array if undefined
        const existingGroups = props.schoolData.groups || [];
        const newGroups = [...existingGroups, groupName];

        // Set the groups in the database
        set(groupsRef, newGroups).then(() => {
            setMessage("Group added successfully!");
            setGroupName(''); // Clear input field
        }).catch((error) => {
            console.error("Error adding group: ", error);
            setMessage("Error adding group.");
        });
    };

    // Handler to delete a group
    const handleDeleteGroup = (index) => {
        let groups = props.schoolData.groups || [];
        groups.splice(index, 1);

        const db = getDatabase(app);
        const schoolId = props.schoolData.path;
        const groupsRef = ref(db, `schools/${schoolId}/public/groups`);

        // Set the groups in the database
        set(groupsRef, groups).then(() => {
            setMessage("Group deleted successfully!");
        }).catch((error) => {
            console.error("Error deleting group: ", error);
            setMessage("Error deleting group.");
        });
    };

    return (
        <div className="container">
            <h3>Create Groups (class/tutor group)</h3>
            <p>If you add groups to this list, every student will need to select one of these groups when they sign up. Leave this list blank if you want students to type in their group name themselves.</p>
            {message && <div className="alert alert-info">{message}</div>}
            <form onSubmit={handleAddGroup}>
                <div className="mb-3">
                    <label className="form-label">Group Name:</label>
                    <input type="text" className="form-control" value={groupName} onChange={e => setGroupName(e.target.value)} />
                </div>
                <button className="btn btn-primary">Add Group</button>
            </form>
            <div className="mt-3">
                <h4>Existing Groups</h4>
                <ul className="list-group">
                    {(props.schoolData.groups || []).map((group, index) => (
                        <li key={index} className="list-group-item d-flex justify-content-between align-items-center">
                            {group}
                            <button className="btn btn-danger btn-sm" onClick={() => handleDeleteGroup(index)}>Delete</button>
                        </li>
                    ))}
                </ul>
            </div>
        </div>
    );
};

export default CreateGroups;
