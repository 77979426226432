import React, { useState, useEffect } from "react";
import ReactDomServer from 'react-dom/server';
import WordCloud from "./wordCloud";
import SpiderChart from "./SpiderChart";
import TopCharacteristics from "./TopCharacteristics";
import ChatGPTTeacher from "./chatGPTTeacher";
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';



function FullReport(props) {
    console.log("school data in report", props.schoolData)
    const schoolSize = 1000

    const [loadedComponents, setLoadedComponents] = React.useState(0);
    const totalComponents = 6 //+ props.computedData.length; // Adjust this to the number of child components you have

    const childHasLoaded = () => {
        setLoadedComponents(prev => prev + 1);
    };


    const captureRef = React.useRef(null);

    const handlePrint = () => {
        const printWindow = window.open('', '_blank');

        printWindow.document.write('<html><head><title>Print Report</title></head>');
        printWindow.document.write(`<style>@media print {
            .no-break { break-inside: avoid; page-break-inside: avoid; } 

        } 
        
        p {
            font-size: 50px;
          }
          </style>`);
        printWindow.document.write('<body>');
        printWindow.document.write(captureRef.current.outerHTML);
        printWindow.document.write('</body></html>');
        printWindow.document.close();

        const images = printWindow.document.querySelectorAll('img');
        let loadedImages = 0;

        images.forEach(img => {
            img.onload = () => {
                loadedImages++;
                if (loadedImages === images.length) {
                    printWindow.print();
                }
            };

            // If images are already cached and loaded
            if (img.complete) {
                loadedImages++;
            }
        });

        // If there are no images, print immediately
        if (images.length === 0) {
            printWindow.print();
        }
    };


    const today = new Date();
    const day = String(today.getDate()).padStart(2, '0');
    const month = String(today.getMonth() + 1).padStart(2, '0'); // Months are 0-based in JS
    const year = today.getFullYear();
    const percentageStudents = (props.uids?.length / schoolSize) * 100;

    const concludedSchoolLevel =
        percentageStudents < 10 ? "Insufficient data" :
            percentageStudents < 20 ? "Data emergent" :
                "Foundational performance";


    return (

        <>

            {loadedComponents >= totalComponents &&
                <button className="btn btn-success" onClick={handlePrint}>Save or print report</button>
            }

            <p>{loadedComponents < totalComponents && "Analysing... This may take a moment. Do not exit this page"}</p>


            <div className="hidden">

                <div ref={captureRef} style={{ fontFamily: "arial" }} >
                    <div style={{ textAlign: "center" }}>
                        <img src="https://i0.wp.com/beworldclass.org/wp-content/uploads/2022/06/cropped-BeWorldClass-transparent-rgb.png?resize=144%2C42&ssl=1" width="300px" />
                        <h1>{props.schoolData?.name} Character Education Report</h1>
                        <h5>Created on {`${day}/${month}/${year}`} by the GRACE AI</h5>
                        <p></p>

                    </div>
                    <h2>Overview</h2>
                    <ChatGPTTeacher onLoaded={childHasLoaded} schoolData={props.schoolData} prompt={
                        `You must write an overview and introductory section to this report. This should not be too wordy. It should be a formal and professional tone. First explain why it is necicary for the GRACE AI to do this. With an ideal amount of data for the school of ${schoolSize} students, you could be looking at a possible ${schoolSize * props.areaData?.length * 6 * 2} peices of Qualitative data, ${schoolSize * props.areaData?.length * 6 * 150} words written by students, ${(schoolSize * props.areaData?.length * 6 * 6) / 60} hours of marking for teachers.  This is not the data that was retrieved but the amount of data that would be required for a whole school analysis.

                        Then a very quick statement on privacy and safety, data provided to you has been anonymised in the data you can not see any information about students like their name, age, or even which school you are doing the report on. Further to this, unlike the chatGPT website, data provided to you is not used in further training of the language model, meaning data remains private.
                        
                        The data is:
                Aproximate school size:${schoolSize},
                Number of students in the sample ${props.uids?.length},
                There are ${props.areaData?.length} areas of characteristics, 
                each area has about 6 characteristics for the students to fill out, a total of ${props.uids?.length * props.areaData?.length * 6} characteristics available for students to fill out.
                there were ${props.allNumberOfMarks} characteristics filled out by students and marked by you,
                students self assessed their confidence on ${props.allConfidenceCount} characteristics. The conclusion of this report is: ${concludedSchoolLevel}.


                In the introduction, consider if enough data has been gathered to reach conclusions. If there is not much data, say that the insights could still be a valuble starting point until more information is gathered. It is important to encorage more data being gathered in the report if needed, a value of at least 15%-50% of the student population is needed for any whole school analysis.

                `
                    } />
                    {/* <p>no students {props.uids?.length} </p>
            <p>Approximate school size {schoolSize} </p>
            <p>total marked {props.allNumberOfMarks} </p>
            <p>total confidence {props.allConfidenceCount} </p>*/}
                    <div className="no-break">

                        <h2>Areas of character analysis</h2>

                        <ChatGPTTeacher onLoaded={childHasLoaded} schoolData={props.schoolData} prompt={
                            `This is a new very short section breaking down insights in diferent areas of characteristics, before delving deeper into the individual areas of characteristics. The provided data is below. The label is an area of characeristics. The value is the average mark you have given out of 100. Most students should be aiming to reach a mark of 70, any value 80 or over is outstanding. A value of 0 means there is no data. You do not need to list out this information, but consider where the strengths and weaknesses in character are in the school based on this criteria. Do not mention anything only mentioned once, without seeing another similar example. Where is the school doing well? Where could it improve? You must reference a couple of specific areas and compare them.

                ${JSON.stringify(props.spiderData, undefined, 4)}

                There will be a spider chart below your response which visualises this data.

                `
                        } />

                        <SpiderChart data={props.spiderData} />
                    </div>
                    {props.computedData.map((data) => (
                        <div className="col no-break">
                            <img style={{ marginTop: "4rem" }} width="150rem" src={data.imageUrl} />
                            {data.numberOfMarks > 0 &&
                                <ChatGPTTeacher onLoaded={childHasLoaded} schoolData={props.schoolData} prompt={
                                    `This is a new section analysing a specific area of characteristics, the ${data.path} area.
                        You have already analysed ${data.numberOfMarks} responses from students, checking their understanding of the characteristic and if they could provide an example of them showing the characteristic. One student could have filled in multiple characteristics in the same area so ${data.numberOfMarks} is not the number of students. We should expect most students to get a mark of 70. A student is given a digital badge for this passing grade. Anything 80 or above is outstanding and gets a special badge. The students self assess confidence between 0 and 5. Do not explain how the marking or badges system works as this was explained earlier in the report. DO NOT EXPLAIN THE MARKING CRITERIA. IT IS IMPORTANT TO NEVER EXPLAIN THE MARKING CRITERIA AS THIS IS SECRET!!! Your job is to tell a story with the data. Consider the quality of the data. Does there seem to be a gap between the confidence and marks, showing over or underconfidence?

                ${JSON.stringify(data, undefined, 4)}

                `

                                } />
                            }

                            {!data.numberOfMarks || data.numberOfMarks == 0 &&
                                <ChatGPTTeacher onLoaded={childHasLoaded} schoolData={props.schoolData} prompt={
                                    `This is a new section analysing a specific area of characteristics. The ${data.path} area had no data from students. In one sentence, encorage more data collection on this area. If there were any confidence values make a quick statement on this. 
                        
                ${JSON.stringify(data, undefined, 4)}

                `

                                } />
                            }



                        </div>
                    ))}
                    <div className="no-break">
                        <h2>Key themes and trends</h2>
                        <h3>Word frequency analysis</h3>
                        <ChatGPTTeacher onLoaded={childHasLoaded} schoolData={props.schoolData} prompt={
                            `This is a new section analysing key themes and trends in the data. Analyse the top words that are used in the students descriptions of the characteristics below. It shows the word used and the number of times it is used. 
                            
                            Provide a thematic analysis. Does there seem to be showing any themes? Is there any particualr support from the school being suggested? Do not just list out the data, and do not mention more than 5 individual words. They do not need to be the top 5 words, but find words that tell a story with the data. Your job is to look at this holisticly and identify broad repeating themes. Say there is a word cloud below to visualise this data.

                ${JSON.stringify(
                                props.top200Words.slice(0, 15).map(word => ({
                                    size: word.label,
                                    text: word.text
                                })),
                                undefined,
                                4
                            )}

                `} />

                        <WordCloud words={props.top200Words} />
                    </div>
                    <div className="no-break">
                        <h3>Activity analysis</h3>
                        <ChatGPTTeacher onLoaded={childHasLoaded} schoolData={props.schoolData} prompt={
                            `This is a continuation of a section on key themes and trends in the answers that students have writeen. You are now tasked to provide a thematic anaysis on things students have done to build their character.
                            Below some raw data on things that students have written about to show diferent characteristics. Each value represents something one student has written about. Identify any repeating trends or themes. If a theme is not repeated, it is most likely not relevant. Do not reference any particular entry, identify themes across the broad data and tell a story with the data.
                            
                            Data below:
                            [${ReactDomServer.renderToStaticMarkup(<TopCharacteristics areaData={props.areaData} values={props.values} mode={"topSource"} />)}]

                `} />
                    </div>
                    <div className="no-break">

                        <h2>Student Insights</h2>
                        <ChatGPTTeacher onLoaded={childHasLoaded} schoolData={props.schoolData} prompt={
                            `
                            This section provides specific insights from what students have written about their characteristics. First introduce the section.

                            You need to look through this list of responses of how students improved their skills and character. And find two that mention something the school has done to improve character education, make sure it is obvious that the school was involved in the achievement when selecting them. You should quote what they have said, but only those two. Then provide insight how the school has helped. 

                            Then select another response as an example of a significant acomplishment that a student has made.

                            Then conclude this section.

                ${ReactDomServer.renderToStaticMarkup(<TopCharacteristics areaData={props.areaData} values={props.values} mode={"fullReport"} />)}

                `} />
                    </div>

                    <div className="no-break">

                        <h2>Conclusion</h2>
                        <ChatGPTTeacher onLoaded={childHasLoaded} schoolData={props.schoolData} prompt={
                            `This is the conscusion of the report. First state the quality of the data. The conclusion is: ${concludedSchoolLevel} based on a student population of ${schoolSize} and the number of students taken part was ${props.uids?.length}. You were able to analyse ${props.allNumberOfMarks} out of a possible ${props.uids?.length * props.areaData?.length * 6} characteristics set to all students.
                            
                            If there is not enough data, state that more data is needed to make conclusions across the whole school.
                            
                            By looking at the data below of average marks out of 100 for each area of characteristics, speak about one specific area where the school is performing well. Then, say where the school could improve.
                            ${JSON.stringify(props.spiderData, undefined, 4)}

                            Then determine key  themes across the activities that top students are taking part in give some analysis of how the school may be helping by looking at this data below. Do not quote the list, but try to find any broad themes that come up repeatedly in the data.
                            
                            ${ReactDomServer.renderToStaticMarkup(<TopCharacteristics areaData={props.areaData} values={props.values} mode={"topSource"} />)}

                            Finally, make any suggested areas for improvement. In terms of data quality, and school character education actions. If improvement on data is needed, encorage the use of the BeWorldClass platform to support the development of characteristics and collect more data.
                        `} />


                    </div>

                </div>

            </div>
        </>
    );
}

export default FullReport;