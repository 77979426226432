import React from "react";
import { Field, ErrorMessage } from "formik";
//import { Editor } from '@tinymce/tinymce-react';
import Activity from "./activity.js"
import EvidenceFile from "./evidenceFile.js";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowRightLong, faBook, faClipboardCheck } from '@fortawesome/free-solid-svg-icons'
import ChatGPT from "../chatGpt.js";
import Badge from "../badge.js";

class Characteristic extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      file: undefined,
      example: false,
      tab: null,
      showUnderstand: true
    }
  }

  componentDidMount() {

    //show understnad tab?
    if (this.props.characteristicData.understand) {
      const understand = this.props.characteristicData.understand
      if (understand === "" || understand === "<p></p>") {
        this.setState({ showUnderstand: false })
      }
    } else {
      this.setState({ showUnderstand: false })
    }

  }


  toggleExample = () => {
    let example = this.state.example
    this.setState({ example: example ? false : true })
  }



  render() {

    const letter = (this.props.index + 10).toString(36).toUpperCase()
    const confidence = this.props.values["confidence" + this.props.characteristicData.id]
    let improvements = []
    const improvementsData = this.props.characteristicData.improvements

    for (let i = 0; i < improvementsData?.length; i++) {
      improvements.push(<label style={{ marginTop: "5px", textAlign: "left" }} className="btn btn-outline-dark btn-small" >
        <Field className="hidden" type="radio" name={"improvements" + this.props.characteristicData.id} value={improvementsData[i]} />
        {improvementsData[i]}</label>)
    }



    return (
      <div className="card" style={{
        backgroundColor: this.props.characteristicData.color ? this.props.characteristicData.color : "#ffffff",
        marginBottom: "25px"
      }}>
        <div className="card-body">
          <div className="row">
            <h3 className="card-title col-10">{letter}... {this.props.characteristicData.name}</h3>
            <div className="col-2">
              {this.props.graceData?.award >= 70 &&
                <div style={{ width: '70px', height: '70px', margin: "auto" }} >
                  <Badge iconName={this.props.characteristicData.icon} color={this.props.area.color||"#E8D900"} special={this.props.graceData.award >= 80} />
                </div>
              }
            </div>

          </div>

          {/* Tabs */}
          <div className="row">
            <hr />
            {this.state.showUnderstand &&
              <div style={{ textAlign: "center" }} className="col-12 col-lg-3 characteristic-tab">
                <button
                  type="button"
                  onClick={() => this.setState({ tab: this.state.tab === "understand" ? null : "understand" })}
                  className={"btn btn" + (this.state.tab === "understand" ? "" : "-outline") + "-primary"}>
                  Understand
                </button>
              </div>

            }
            {this.state.showUnderstand &&
              <div style={{ textAlign: "center", marginTop: "0.5rem" }} className="hidden-sm col-1">
                <FontAwesomeIcon icon={faArrowRightLong} />
              </div>
            }

            <div style={{ textAlign: "center" }} className="col-12 col-lg-3 characteristic-tab">
              <button
                type="button"
                onClick={() => this.setState({ tab: this.state.tab === "evidence" ? null : "evidence" })}
                className={"btn btn" + (this.state.tab === "evidence" ? "" : "-outline") + "-primary"}>
                Evidence
              </button>
            </div>

            <div style={{ textAlign: "center", marginTop: "0.5rem" }} className="hidden-sm col-2">
              <FontAwesomeIcon icon={faArrowRightLong} />
            </div>

            <div style={{ textAlign: "center" }} className="col-12 col-lg-3 characteristic-tab">
              <button
                type="button"
                onClick={() => this.setState({ tab: this.state.tab === "improve" ? null : "improve" })}
                className={"btn btn" + (this.state.tab === "improve" ? "" : "-outline") + "-primary"}>
                Improve
              </button>
            </div>
            <hr style={{ marginTop: "1rem" }} />
          </div>

          {/* Understand */}
          <div style={{backgroundColor:"rgba(255, 255, 255, 0.5)"}} className={this.state.tab === "understand" ? "characteristic-body" : "hidden"}>


            {
              this.props.characteristicData.understand &&
              <div className="row">
                <div className={this.props.characteristicData.understandImage ? "col-12 col-lg-7" : ""}
                  dangerouslySetInnerHTML={{ __html: this.props.characteristicData.understand }}
                />

                {this.props.characteristicData.understandImage &&
                  <div className="col-12 col-lg-5">
                    <img width="100%" src={this.props.characteristicData.understandImage} ></img>
                  </div>
                }

                <div className="col-12">
                  <button onClick={() => { this.setState({ tab: "" }) }} className="btn btn-secondary">Close section</button>
                </div>
              </div>
            }

            {/*

            <button onClick={() => this.toggleExample()} className="btn btn-outline-primary">{this.state.example ? "Close example" : "View example"}</button>
            {this.state.example && <div className="slide-in-blurred-right"><h1>example!</h1><img src="https://app.plusier.com/branding/WCSfileStorage/event-organiser-logo?name=2a" /></div>}
          */}

          </div>
          {/* Evidence */}
          <div className={this.state.tab === "evidence" ? "characteristic-body" : "hidden"}>

            <div className="row">
              <h5></h5>

              <div className="col-12 col-lg-6">
                <div className="form-group">
                  <label>Description</label>
                  <Field id={"description" + this.props.characteristicData.id} name={"description" + this.props.characteristicData.id} as="textarea" className="form-control" style={{ height: "200px" }} spellCheck="true" />
                </div>
              </div>

              <div className="col-12 col-lg-6">
                <div className="form-group">
                  <label for={"source" + this.props.characteristicData.id}>Source (How can you prove this?)</label>
                  <Field id={"source" + this.props.characteristicData.id} name={"source" + this.props.characteristicData.id} type="text" className="form-control" />
                </div>
                <div className="form-group">
                  <label>Date of evidence</label>
                  <Field id={"date" + this.props.characteristicData.id} name={"date" + this.props.characteristicData.id} type="date" className="form-control" />
                </div>
                <div className="form-group">
                  <label>Upload image</label>


                  <EvidenceFile
                    schoolData={this.props.schoolData}
                    uid={this.props.user.uid}
                    index={this.props.characteristicData.id}
                    areaPath={this.props.area.path} />
                </div>

              </div>

              <p><b> {(!this.props.values["description" + this.props.characteristicData.id]
                || !this.props.values["source" + this.props.characteristicData.id]
                || !this.props.values["date" + this.props.characteristicData.id])
                && "Add a description, source and date before continuing"}</b></p>

              <p><b>{this.props.values["description" + this.props.characteristicData.id]
                && this.props.values["source" + this.props.characteristicData.id]
                && this.props.values["date" + this.props.characteristicData.id]
                && !this.props.values["confidence" + this.props.characteristicData.id]
                && "Select your confidence below before continuing"}</b></p>


              {this.props.values["description" + this.props.characteristicData.id]
                && this.props.values["source" + this.props.characteristicData.id]
                && this.props.values["date" + this.props.characteristicData.id]
                && this.props.values["confidence" + this.props.characteristicData.id]
                && <ChatGPT
                  characteristic={this.props.characteristicData.name}
                  icon={this.props.characteristicData.icon}
                  value={this.props.values["description" + this.props.characteristicData.id]}
                  markScheme={this.props.characteristicData.aiMarkScheme!=""? this.props.characteristicData.aiMarkScheme:null }
                  mainFocus={this.props.characteristicData.aiMainFocus !=""? this.props.characteristicData.aiMainFocus:null}
                  schoolData={this.props.schoolData}
                  uid={this.props.user.uid}
                  characteristicId={this.props.characteristicData.id}
                  areaPath={this.props.area.path}
                  areaType={this.props.area.stage}
                  areaColor={this.props.area.color||"#E8D900"}
                />
              }


            </div>
          </div>

          {/* Improve */}
          <div className={this.state.tab === "improve" ? "characteristic-body" : "hidden"} >

            <h5>What actions could you take to improve?</h5>
            <br />
            <h6></h6>
            <div className="form-group row">
              <label></label>
              <Field id={"improvements" + this.props.characteristicData.id} as="textarea" name={"improvements" + this.props.characteristicData.id} className="form-control" />
            </div>
            {improvementsData?.length > 0 &&
              <div>
                <br /><hr />
                <h6>Try to think of your own, but if you can't think of actions you could take, choose from an example</h6>
                {improvements}
              </div>
            }
          </div>

          {/* Confidence */}
          <div style={{backgroundColor:"rgba(255, 255, 255, 0.7)", padding:"1rem"}} >
            <h6 className="d-block d-md-none">How well do you feel you can you do this?</h6>
            <div className="row">
              <div className="btn-group  btn-group-toggle">
                <label className={`btn col form-check-label btn-${confidence == "1" ? "" : "outline-"}danger`} >
                  <Field className="form-check-input hidden" type="radio" name={"confidence" + this.props.characteristicData.id} value="1" />
                  <span className="d-block d-md-none">1</span><span className="d-none d-md-block ">I don't understand</span></label>
                <label className={`btn col form-check-label btn-${confidence == "2" ? "" : "outline-"}warning`} >
                  <Field className="form-check-input hidden" type="radio" name={"confidence" + this.props.characteristicData.id} value="2" />
                  <span className="d-block d-md-none">2</span><span className="d-none d-md-block ">I can't do this</span></label>

                <label className={`btn col form-check-label btn-${confidence == "3" ? "" : "outline-"}warning`} >
                  <Field className="form-check-input hidden" type="radio" name={"confidence" + this.props.characteristicData.id} value="3" />
                  <span className="d-block d-md-none">3</span><span className="d-none d-md-block ">I can sometimes do this</span></label>
                <label className={`btn col form-check-label btn-${confidence == "4" ? "" : "outline-"}success`}>
                  <Field className="form-check-input hidden" type="radio" name={"confidence" + this.props.characteristicData.id} value="4" />
                  <span className="d-block d-md-none">4</span><span className="d-none d-md-block "> I can do this</span></label>
                <label className={`btn col form-check-label btn-${confidence == "5" ? "" : "outline-"}success`} >
                  <Field className="form-check-input hidden" type="radio" name={"confidence" + this.props.characteristicData.id} value="5" />
                  <span className="d-block d-md-none">5</span><span className="d-none d-md-block ">I excel at this</span></label>
              </div>
            </div>
          </div>


          {this.props.values && this.props.values["feedback" + this.props.characteristicData.id] &&
            <div className="row">

              <div className="col-12">
                <div className="form-group">
                  <label for={"feedback" + this.props.characteristicData.id}>Feedback</label>
                  <Field disabled={true} as="textarea" style={{ height: "100px" }} id={"feedback" + this.props.characteristicData.id} name={"feedback" + this.props.characteristicData.id} type="text" className="form-control" />
                </div>
              </div>

            </div>
          }

        </div>
      </div>
    );

  }
}

export default Characteristic;


