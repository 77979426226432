import React from "react";

import { Formik, Form } from "formik";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSearch, faCheck } from '@fortawesome/free-solid-svg-icons'
import { columnHeader } from "./columnHeader";
import { checkboxTableCell } from "./checkboxTableCell";
import Insight from "./insight";




class Grid extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            selectedRows: [],
            selectedKeys: [],
            lastSelectedIndex: null,
            filteredValues: null,
            filterValues: [],
            mode: "insight"
        };
    }

    componentDidMount() {
        this.filter([])

    }

    selectRow = (i, key, event) => {
        let selectedRows = [...this.state.selectedRows];
        let selectedKeys = [...this.state.selectedKeys];

        const updateSelectedRowsAndKeys = (index) => {
            const dataIndex = selectedRows.indexOf(index);
            if (dataIndex !== -1) {
                selectedRows.splice(dataIndex, 1);
                selectedKeys.splice(dataIndex, 1);
            } else {
                selectedRows.push(index);
                selectedKeys.push(key);
            }
        };

        // CTRL key logic
        if (event.ctrlKey) {
            updateSelectedRowsAndKeys(i);
        }
        // SHIFT key logic
        else if (event.shiftKey && this.state.lastSelectedIndex !== null) {
            const start = Math.min(this.state.lastSelectedIndex, i);
            const end = Math.max(this.state.lastSelectedIndex, i);
            for (let j = start; j <= end; j++) {
                if (!selectedRows.includes(j)) {
                    // Get key of the j-th row from your data (assuming it's in this.props.values)
                    const rowKey = this.props.values[j].key;
                    updateSelectedRowsAndKeys(j, rowKey);
                }
            }
        }
        // Normal click logic
        else {
            selectedRows = [i];
            selectedKeys = [key];
        }

        this.setState({
            selectedRows: selectedRows,
            selectedKeys: selectedKeys,
            lastSelectedIndex: i
        });

        // Send selected keys to the parent
        this.props.selectStudent(selectedKeys);
    }



    filter = (filters) => {

        var filtered = this.addStartingFilters(this.props.values)
        let i = 0
        for (const [key, value] of Object.entries(filters)) {

            const columnInfo = this.props.columns.filter(function (column) {
                return column.key === key
            })[0]

            console.log("column info", columnInfo)

            if (columnInfo?.type === "select" && value && value !== "") {
                filtered = filtered.filter(function (row) {
                    return row[key] === value
                });

            }

            if (columnInfo?.type !== "select" && value && value !== "") {
                filtered = filtered.filter(function (row) {
                    return checkRowContainsFilterValue(row, key, value)
                });
            }
            i++
        }

        return filtered

    }

    addStartingFilters = (values) => {

        const filters = this.props.startingFilters
        let filtered = values

        for (let i = 0; i < filters.length; i++) {
            const filter = filters[i]
            filtered = filtered.filter(function (row) {

                return row[filter.key] === filter.value
            });
        }

        return filtered
    }

    createColumnHeaders(values) {
        const searchIconCell = <th style={{ verticalAlign: "middle" }} scope="col"><FontAwesomeIcon icon={faSearch} /></th>;
        let columnHeads = [searchIconCell];
        let columnDetails = []
        for (let i = 0; i < this.props.columns?.length; i++) {
            let column = this.props.columns[i];

            if (column.optional) {
                console.log("optional colimn")
                for (let i = 0; i < this.props.values?.length; i++) {

                    if (this.props.values[i][column.key] === "true") {
                        columnDetails.push(column)
                        columnHeads.push(columnHeader(column, this.props.values));
                        console.log("optional column value", values[i])
                        break
                    }

                }

            } else {
                columnDetails.push(column)
                columnHeads.push(columnHeader(column, this.props.values));
            }
        }
        return { columnHeads: columnHeads, columnDetails: columnDetails };
    }

    createRows(values, columnDetails) {
        let rows = [];

        console.log("values", values)
        for (let i = 0; i < values?.length; i++) {
            let value = values[i];

            let rowSelected = this.state.selectedRows.includes(i);

            let row = [checkboxTableCell(rowSelected)];

            addDisplayValueCellsToRow(row, value, columnDetails);

            const rowWithWrapper = <tr onClick={(e) => this.selectRow(i, value.key, e)} className={this.state.selectedRows.includes(i) ? "table-primary" : ""} scope="row">{row}</tr>;

            rows.push(rowWithWrapper);
        }

        console.log("rows", rows)
        return rows;



        function addDisplayValueCellsToRow(row, value, columns) {
            for (let j = 0; j < columns.length; j++) {

                const columnInfo = columns[j];
                const rowValue = value[columnInfo.key]
                //let displayValue = <span>{rowValue}</span>

                let displayValue = rowValue === "true" ?
                    <FontAwesomeIcon icon={faCheck} /> :
                    rowValue === "false" ? <></> :
                        <span>{rowValue}</span>


                row.push(<td> {displayValue}</td>);

            }
        }
    }


    render() {

        const values = this.filter(this.state.filterValues)

        const columnHeaderdetails = this.createColumnHeaders(values);
        const columnHeads = columnHeaderdetails.columnHeads

        let rows = this.createRows(values, columnHeaderdetails.columnDetails);
        console.log("grid school data", this.props.schoolData)

        return (
            <div>
                {this.state.mode !== "insight" &&
                    <p>Total: {values?.length}</p>
                }
                <div className="table-responsive table-fixed-head" style={{ textAlign: "center" }}>
                    <Formik
                        initialValues={{}}
                        validate={values => { this.setState({ filterValues: values }) }}
                        onSubmit={async values => { await new Promise(resolve => setTimeout(resolve, 500)); }}
                    >
                        <Form >
                            <table className="table table-hover table-striped table-sm ">
                                <thead style={{ backgroundColor: "white" }}>
                                    <tr>
                                        {columnHeads}
                                    </tr>
                                </thead>
                                {this.props.insight && this.state.mode === "default" &&
                                    <tbody>
                                        {rows}
                                    </tbody>
                                }
                                {!this.props.insight &&
                                    <tbody>
                                        {rows}
                                    </tbody>
                                }
                            </table>
                        </Form>
                    </Formik>
                </div>
                {this.state.mode == "insight" && this.props.insight &&

                    <Insight
                        values={values}
                        schoolData={this.props.schoolData}
                        areasData={this.props.areasData}
                        filterValues={this.state.filterValues}
                        extendedSubmissions={this.props.extendedSubmissions}
                    />
                }
            </div>
        );
    }



}

export default Grid;

function checkRowContainsFilterValue(row, key, value) {
    return row[key]?.toString().toUpperCase().includes(value?.toUpperCase());
}
