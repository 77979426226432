
import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ReactMarkdown from 'react-markdown';
import { getFunctions, httpsCallable } from "firebase/functions";

class TeacherChat extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            userMessage: "",
            conversationHistory: [],
            sendingMessage: false,
            showPrompt: false,
            promptInput: this.props.prompt.prompt // Indicates if a message is currently being sent
        };
    }

    handleMessageChange = (event) => {
        this.setState({ userMessage: event.target.value });
    }

    handlePromptChange = (event) => {
        this.setState({ promptInput: event.target.value })
    }

    sendMessage = async () => {
        const { userMessage } = this.state;

        if (!userMessage.trim()) {
            alert('Please enter a message.');
            return;
        }
        const conversationHistory = this.state.conversationHistory
        this.setState({
            sendingMessage: true,
            conversationHistory: [...conversationHistory, {
                role: 'user',
                content: userMessage
            }]
        });

        // Prepare the payload to send to the cloud function
        const payload = {
            schoolId: this.props.schoolData.path,
            messages: [{
                role: 'user', // The role for the new message
                content: this.state.promptInput + userMessage// The content of the new user message
            },
            ...conversationHistory // Spread all existing messages from conversationHistory

            ]


        };

        try {
            // Replace 'yourCloudFunctionName' with the actual name of your callable function
            const functions = getFunctions();
            const sendMessageFunction = httpsCallable(functions, 'teacherChat');
            const result = await sendMessageFunction(payload);
            console.log("result", result)
            const responseMessage = result.data.choices[0].message.content; // Assuming this is the format of your response

            this.setState(prevState => ({
                conversationHistory: [...prevState.conversationHistory, { role: 'assistant', content: responseMessage }],
                userMessage: "", // Clear the message input after sending
                sendingMessage: false
            }));
        } catch (error) {
            console.error("Error sending message: ", error);
            this.setState({ sendingMessage: false });
            alert("Failed to send message. Please try again.");
        }
    }

    render() {
        if (!this.props.showModal) return null;

        const { userMessage, conversationHistory, sendingMessage } = this.state;

        return (
            <div className="card">
                <div className="card-body">
                    <div className="row">
                        <h5 className="col-10 card-title">{this.props.prompt.name}</h5>
                        <div className="col-2" style={{ textAlign: "right" }}>
                            <span className="btn btn-outline-secondary" onClick={this.props.handleClose}>Back</span>
                        </div>
                    </div>
                    <div className="row">
                        <div style={{ paddingTop: "1rem", paddingLeft: "1rem", paddingRight: "1rem" }}>
                            <button onClick={() => { this.setState({ viewPrompt: !this.state.viewPrompt }) }} className="btn btn-outline-primary">
                                {this.state.viewPrompt ? "Hide prompt  " : "View Prompt  "}
                                <FontAwesomeIcon icon="fa-eye" />
                            </button>
                            {this.state.viewPrompt && <>
                                <textarea
                                    value={this.state.promptInput}
                                    onChange={this.handlePromptChange}
                                    placeholder="Type your prompt here"
                                    className="form-control"
                                    style={{ height: "200px" }}
                                />
                                <hr />
                            </>}

                            {!this.state.viewPrompt &&

                                <>

                                    <div className="card" style={{ overflowY: "auto", height: "55vh" }}>
                                        <div className="card-body">
                                            {conversationHistory.length > 0 ? (
                                                conversationHistory.map((entry, index) => (
                                                    <div key={index} className={`d-flex mb-3 ${entry.role === 'user' ? 'justify-content-end' : 'justify-content-start'}`}>
                                                        <div className={`card ${entry.role === 'user' ? 'bg-primary text-white' : 'bg-light text-dark'}`} style={{ maxWidth: '75%' }}>
                                                            <div className="card-body">
                                                                <ReactMarkdown>{entry.content}</ReactMarkdown>
                                                            </div>
                                                        </div>
                                                    </div>
                                                ))
                                            ) : (
                                                <p className="text-muted"> {this.props.prompt.userInstructions} </p>
                                            )}
                                            {sendingMessage && <div className="d-flex mb-3 justify-content-start">
                                                <div className="card bg-light text-dark">
                                                    <div className="card-body">
                                                        <div className="typing">
                                                            <div className="dot"></div>
                                                            <div className="dot"></div>
                                                            <div className="dot"></div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>}

                                            <div ref={this.chatEndRef}></div>
                                        </div>

                                    </div>
                                    <div className="input-group my-3">
                                        <textarea
                                            value={userMessage}
                                            onChange={this.handleMessageChange}
                                            placeholder={conversationHistory.length > 0 ? "Follow on the conversation..." : "Type a message..."}
                                            className="form-control"
                                            disabled={sendingMessage}
                                        />
                                        <button className="btn btn-primary" onClick={this.sendMessage} disabled={sendingMessage}>
                                            <FontAwesomeIcon icon="paper-plane" className="me-2" />
                                            {sendingMessage ? 'Sending...' : 'Send'}
                                        </button>
                                    </div>
                                    <p style={{ textAlign: "center" }} className="text-muted">AI can make mistakes. Always check results.</p>
                                </>
                            }
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default TeacherChat