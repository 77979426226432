import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import TeacherChat from './teacherChat';

const ExistingChatToolCard = ({ prompt, categoryColors, schoolData, isOpen, onOpen, onClose }) => {

    return (
        <div className="col-md-6 mb-4">
            <TeacherChat schoolData={schoolData} showModal={isOpen} handleClose={onClose} prompt={prompt} />
            <div onClick={isOpen ? onClose : onOpen} className="card bigger-on-hover" style={{ minHeight: "8rem" }}>
                <div className="card-body row">
                    <div className="col-2 d-flex justify-content-center align-items-center" style={{
                        height: '100%'
                    }}>
                        <h2>
                            <FontAwesomeIcon icon={prompt.icon} style={{
                                color:"#afcfff",
                                fontSize: "3.5rem",
                                height:"auto",
                                width:"100%",
                                display:"block"
                            }} />
                        </h2>
                    </div>

                    <div className="col-10">
                        <h5 className="card-title">
                            {prompt.name}
                        </h5>
                        <p style={{ fontSize: "0.8rem", color: "grey" }} className="card-text">{prompt.description}</p>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ExistingChatToolCard;
