import React from "react";
import Characteristic from "./characteristic.js";
import { Formik, Field, Form, ErrorMessage, useFormikContext } from "formik";
import { getDatabase, ref, set, onValue } from "firebase/database";
import app from "../../firebase.js"
import AreaSteps from "./areaSteps.js";

class Characteristics extends React.Component {

    constructor(props) {
        super(props);
        this.saveButton = React.createRef();
        this.state = {
            mark: null,
            progressEstimate: 0,
            autoSavedValues: null,
            valuesChanged: false,
            databaseValues: {},
            errorSaving: false,
            loaded: false,
            initialValues: {},
            visibleStep: 0,
        }
    }

    componentDidMount() {

        const database = getDatabase(app);

        const areaStatePath = ref(database, 'schools/' + this.props.schoolData.path + '/submissions/' + this.props.area.path + '/' + this.props.user.uid)
        onValue(areaStatePath, (snapshot) => {
            const data = snapshot.val();
            console.log("from Database", data)
            if (data) {
                if (data.values) {
                    if (!this.state.loaded) {
                        this.setState({ initialValues: data.values, loaded: true, databaseValues: data, mark: data.mark, visibleStep: data.step || 0 })
                    } else {
                        this.setState({ databaseValues: data, mark: data.mark, visibleStep: data.step || 0 })
                    }
                } else {
                    this.setState({ loaded: true, visibleStep: data.step || 0 })
                    // this is bad but testing if it works
                }
            } else {
                this.setState({ loaded: true })
            }

        })

        this.autoSave()

    }

    autoSave = () => {

        const database = getDatabase(app)
        const areaPath = 'schools/' + this.props.schoolData.path + '/submissions/' + this.props.area.path + '/' + this.props.user.uid
        const submissionPath = ref(database, 'schools/' + this.props.schoolData.path + '/submissions/' + this.props.area.path + '/' + this.props.user.uid);
        const values = this.state.autoSavedValues


        if (values && this.state.valuesChanged) {
            // 
            const progressEstimate = this.calculateProgressEstimate(values);
            this.setState({ progressEstimate: progressEstimate })
            const previousValues = this.state.databaseValues
            // 
            console.log("current", values)
            console.log("previousValues ", previousValues)
            let mark = null
            if (this.state.databaseValues.mark) {
                mark = this.state.databaseValues.mark
            }

            const changedKeys = [];

            for (const key in values) {
                if (values.hasOwnProperty(key)) {
                    if (values[key] !== previousValues[key]) {
                        changedKeys.push(key);
                    }
                }
            }

            console.log(changedKeys);

            if (changedKeys.length > 0) {


                for (let i = 0; i < changedKeys.length; i++) {
                    const submissionPath = ref(database, areaPath + "/values/" + changedKeys[i]);

                    set(submissionPath, values[changedKeys[i]]).then(() => {
                        console.log("save succsess")
                        this.setState({ valuesChanged: false, errorSaving: false })
                    })
                        .catch((error) => {
                            console.error("Error writing data to the database:", error);
                            this.setState({ valuesChanged: false, errorSaving: true })
                        });

                    console.log("changed values:", changedKeys[i], values[changedKeys[i]])
                }


            } else {
                //this.setState({ valuesChanged: false })
            }


            /* old version
                        set(areaPath, {
                            values: values,
                            state: "In Progress",
                            mark: mark,
                            progressEstimate: progressEstimate
                        });
            */

            //console.log("AUTOSAVE", values)

        } else {
            //this.setState({ valuesChanged: false })
        }
        setTimeout(() => {
            this.autoSave();
        }, 10000);
    }

    submitToFirebase = (values) => {
        if (values) {

            const database = getDatabase(app)
            const submissionPath = ref(database, 'schools/' + this.props.schoolData.path + '/submissions/' + this.props.area.path + '/' + this.props.user.uid);

            let mark = null
            if (this.state.databaseValues.mark) {
                mark = this.state.databaseValues.mark
            }

            set(submissionPath, {
                values: values,
                state: "In Progress",
                mark: mark,
                step: this.state.visibleStep ? this.state.visibleStep : 0
            });

            this.setState({ autoSavedValues: values })
        }
    }

    requestFeedback = () => {
        const database = getDatabase(app)
        const submissionPath = ref(database, 'schools/' +
            this.props.schoolData.path +
            '/submissions/' +
            this.props.area.path + '/' +
            this.props.user.uid +
            '/mark');

        set(submissionPath, "In Review");
    }


    calculateProgressEstimate(values) {
        const numberOfCharacteristics = this.props.area.characteristics.length;
        let numberOfDescriptionsWritten = 0;
        Object.keys(values).forEach(key => {
            if (key.includes("description")) {
                numberOfDescriptionsWritten++;
            }
        });
        const progressEstimate = Math.round((numberOfDescriptionsWritten / numberOfCharacteristics) * 100)
        return progressEstimate;
    }

    render() {

        let characteristicsData = this.props.area.characteristics.sort((a, b) => a.index - b.index);
        let characteristics = []

        for (let i = 0; characteristicsData.length > i; i++) {

            const characteristicComponent = <Characteristic
                schoolData={this.props.schoolData}
                user={this.props.user}
                area={this.props.area}
                values={this.state.autoSavedValues ? this.state.autoSavedValues : this.state.initialValues}
                index={i}
                characteristicData={characteristicsData[i]}
                graceData={this.state.databaseValues.grace ? this.state.databaseValues.grace[characteristicsData[i].id] : null}
                key={characteristicsData[i].id}
            />

            if (characteristicsData[i].step !== undefined) {
                if (characteristicsData[i].step === this.state.visibleStep) {
                    console.log("visible step is", this.state.visibleStep)
                    characteristics.push(characteristicComponent)
                }
            } else {
                console.log("characteristic", characteristicsData[i])
                characteristics.push(characteristicComponent)
            }
        }

        return (
            <div>
                {this.state.mark === "In Review" &&
                    < div className="alert alert-primary" role="alert">
                        You've requested for a teacher to review this area.
                    </div>
                }

                {this.state.mark === "Complete" &&
                    <div className="alert alert-success" role="alert">
                        A teacher has reviewed this area and marked it as complete!

                        Download Portfolio
                    </div>
                }

                {this.state.mark === "Incomplete" &&
                    <div className="alert alert-primary" role="alert">
                        A teacher has reviewed this area and marked it as incomplete
                    </div>
                }

                {this.state.valuesChanged &&
                    <div className="alert alert-warning" style={{ position: 'fixed', top: "2rem", left: "2rem", zIndex: 999 }}>
                        <h6>
                            Saving...
                        </h6>
                    </div>
                }






                {this.state.errorSaving &&
                    <div className="alert alert-danger" style={{ position: 'fixed', top: "2rem", left: "2rem", zIndex: 999 }}>
                        <h6>
                            Save failed, you may be offline.
                        </h6>
                    </div>
                }


                {
                    this.state.loaded &&
                    <Formik
                        initialValues={this.state.databaseValues ? this.state.initialValues : {}}
                        validate={values => {


                            if (values !== this.state.databaseValues.values) {
                                this.setState({ autoSavedValues: values, valuesChanged: true })
                            }
                        }
                        }
                        onSubmit={async values => {
                            await new Promise(resolve => setTimeout(resolve, 500));

                            //this.submitToFirebase(values);
                            console.log(values)

                        }}>
                        <Form >


                            <div style={{ marginTop: "2rem" }} className="row">
                                <div className="col-12 col-lg-6">

                                    <AreaSteps
                                        schoolData={this.props.schoolData}
                                        area={this.props.area}
                                        values={this.state.databaseValues}
                                        user={this.props.user}
                                        getStep={this.state}
                                    />
                                </div>

                                <div className="col-12 col-lg-6" style={{ textAlign: "right", marginBottom: "1rem" }}>

                                    {/* {!this.props.schoolData.hideRequestFeedback &&
                                        <button className="btn btn-outline-primary" type="button" onClick={this.requestFeedback}>
                                            Request feedback from teacher
                                        </button>
                                    } */}
                                </div>
                            </div>

                            {this.state.initialValues.overallFeedback &&
                                <div>
                                    <h3>Feedback</h3>
                                    <p> {this.state.initialValues.overallFeedback} </p>
                                </div>
                            }

                            {characteristics}

                        </Form>
                    </Formik>
                }
                <AreaSteps
                    schoolData={this.props.schoolData}
                    area={this.props.area}
                    values={this.state.databaseValues}
                    user={this.props.user}
                    getStep={this.state}
                />

            </div >
        );

    }
}

export default Characteristics;