
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faStar, faArrowUpRightDots } from '@fortawesome/free-solid-svg-icons'
import ViewSingleCharacteristic from '../../viewSingleCharacteristic';
import React from 'react';

class TopCharacteristics extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            viewCharacteristic: null
        }
    }

    closeCharacteristic = () => {
        this.setState({viewCharacteristic:null})
    }

    render() {
        const { values, areaData, mode, schoolData } = this.props;

        const characteristicsWithScores = [];

        values.forEach(value => {
            Object.keys(value).forEach(key => {
                const characteristicId = key.replace("confidence", "");
                const confidenceKey = `confidence${characteristicId}`;
                let markScore = null
                if (value.grace) {
                    markScore = value.grace[characteristicId]?.award
                }

                // Check for either a confidence score or a mark score
                if (value[confidenceKey] || markScore) {
                    const confidenceScore = parseInt(value[confidenceKey]) || 0; // default to 0 if undefined
                    markScore = markScore || 0; // default to 0 if undefined
                    const totalScore = confidenceScore * 10 + markScore;
                    console.log("top char value", value)

                    characteristicsWithScores.push({
                        uid: value.uid,
                        area: value.area,
                        firstName: value.firstName,
                        lastName: value.lastName,
                        characteristicId: characteristicId,
                        score: totalScore,
                        source: value["source" + characteristicId],
                        description: value["description" + characteristicId],
                        evidenceFile: value["evidenceFile" + characteristicId],
                        date: value["date" + characteristicId],
                        id: characteristicId
                    });
                }
            });
        });

        // Sort based on score and get the top 5
        const listLength = mode === "topSource" ? 50 : mode === "fullReport" ? 6 : 15
        const topCharacteristics = characteristicsWithScores.sort((a, b) => b.score - a.score).slice(0, listLength);
        const worstCharacteristics = characteristicsWithScores.sort((a, b) => a.score - b.score).slice(0, listLength);


        if (mode === "topSource") {
            return (<>
                {topCharacteristics.map((char, index) => {
                    // Get the area data for this characteristic
                    const areaDataItem = areaData.find(area => area.path === char.area);
                    // Get the characteristic's name
                    const characteristicData = areaDataItem?.characteristics.find(ch => String(ch.id) === char?.characteristicId);
                    const characteristicName = characteristicData ? characteristicData.name : "Unknown";  // Just in case no match is found

                    return (
                        <>
                            {char.source ? char.source + ", " : ""}
                        </>

                    );
                })}
            </>)
        }
        if (mode === "fullReport") {
            return (<>
                {topCharacteristics.map((char, index) => {
                    // Get the area data for this characteristic
                    const areaDataItem = areaData.find(area => area.path === char.area);
                    // Get the characteristic's name
                    const characteristicData = areaDataItem?.characteristics.find(ch => String(ch.id) === char?.characteristicId);
                    const characteristicName = characteristicData ? characteristicData.name : "Unknown";  // Just in case no match is found

                    return (
                        <div>
                            Description written by student:{char.description}
                        </div>
                    );
                })}
            </>)
        } else {

            return (
                <>
                    <div className="col-6">
                        <div className="card text-white bg-success mb-3">
                            <div className="card-body">
                                <h5 className="card-title"><FontAwesomeIcon icon={faStar} /> Strongest characteristics</h5>
                                <hr />
                                <div className="scrollable-card">
                                    {topCharacteristics.map((char, index) => {
                                        // Get the area data for this characteristic
                                        const areaDataItem = areaData.find(area => area.path === char.area);
                                        // Get the characteristic's name
                                        const characteristicData = areaDataItem?.characteristics.find(ch => String(ch.id) === char?.characteristicId);
                                        const characteristicName = characteristicData ? characteristicData.name : "Unknown";  // Just in case no match is found

                                        return (
                                            <div style={{textAlign:"left"}} className="row mb-3"  key={index}>
                                              
                                                <div className="col-10">
                                                <h4>{char.firstName} {char.lastName} </h4>
                                                <h5>I {characteristicName}</h5> {/* Display the characteristic name */}
                                                <p>Confidence weighted score: {char.score}</p>

                                                <button className="btn btn-primary" onClick={() => { this.setState({ viewCharacteristic: char.area + char.uid + String(char.id) }) }} >View</button>

                                                {this.state.viewCharacteristic === char.area + char.uid + String(char.id) &&

                                                    <ViewSingleCharacteristic closeCharacteristic={this.closeCharacteristic} schoolData={schoolData} values={char} uid={char.uid} characteristicData={{ area:char.area, name: characteristicName, id: char.id }} />

                                                }
                                                </div>
                                                <div className="col-2">
                                                <img width="100%" src={areaDataItem?.imageUrl}/>
                                                </div>
                                                <hr className="col-12"/>
                                            </div>
                                        );
                                    })}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-6">
                        <div className="card bg-warning mb-3">
                            <div className="card-body">
                                <h5 className="card-title"><FontAwesomeIcon icon={faArrowUpRightDots} /> Characteristics to improve</h5>
                                <hr />
                                <div className="scrollable-card">
                                    {worstCharacteristics.map((char, index) => {
                                        // Get the area data for this characteristic
                                        const areaDataItem = areaData.find(area => area.path === char.area);
                                        // Get the characteristic's name
                                        const characteristicData = areaDataItem?.characteristics.find(ch => String(ch.id) === char?.characteristicId);
                                        const characteristicName = characteristicData ? characteristicData.name : "Unknown";  // Just in case no match is found

                                        return (
                                            <div style={{textAlign:"left"}} className="row mb-3"  key={index}>
                                              
                                                <div className="col-10">
                                                <h4>{char.firstName} {char.lastName} </h4>
                                                <h5>I {characteristicName}</h5> {/* Display the characteristic name */}
                                                <p>Confidence weighted score: {char.score}</p>

                                                <button className="btn btn-primary" onClick={() => { this.setState({ viewCharacteristic: char.area + char.uid + String(char.id) }) }} >View</button>

                                                {this.state.viewCharacteristic === char.area + char.uid + String(char.id) &&

                                                    <ViewSingleCharacteristic closeCharacteristic={this.closeCharacteristic} schoolData={schoolData} values={char} uid={char.uid} characteristicData={{ area:char.area, name: characteristicName, id: char.id }} />

                                                }
                                                </div>
                                                <div className="col-2">
                                                <img width="100%" src={areaDataItem?.imageUrl}/>
                                                </div>
                                                <hr className="col-12"/>
                                            </div>
                                        );
                                    })}
                                </div>
                            </div>
                        </div>
                    </div>
                </>
            );

        }
    }
}

export default TopCharacteristics;
