// SpiderChart.js
import React, { useEffect, useRef } from 'react';
import * as d3 from 'd3';

function SpiderChart({ data }) {
    const svgRef = useRef(null);

    useEffect(() => {
        if (!data) return;

        const svg = d3.select(svgRef.current);
        svg.selectAll('*').remove();

        // Inside the useEffect

        const width = 500  ;
        const height = 500;
        const margin = 100; // or another value to ensure a margin
        const radius = Math.min(width, height) / 2 - margin;
        

        // Setup the scales and other configurations
        const angleSlice = Math.PI * 2 / data.length;
        const maxValue = 100 //Math.max(...data.map(d => d.value));

        // Add the circles/spokes
        for (let j = 1; j <= 5; j++) {
            const r = radius * (j / 5);
            svg.append('circle')
                .attr('cx', width / 2)
                .attr('cy', height / 2)
                .attr('r', r)
                .style('fill', 'none')
                .style('stroke', 'grey')
                .style('stroke-dasharray', '2,2');
        }

        // Add the lines from the center
        data.forEach((d, i) => {
            const x = width / 2 + (radius + 10) * Math.cos(angleSlice * i - Math.PI / 2); // 10 can be adjusted based on where you want the labels
            const y = height / 2 + (radius + 10) * Math.sin(angleSlice * i - Math.PI / 2);
          
            svg.append('text')
              .attr('x', x)
              .attr('y', y)
              .attr('dy', '.35em') // to vertically center text
              .text(d.label)
              .style('font-size', '20px') // adjust as needed
              .style('text-anchor', i % 2 === 0 ? 'middle' : (Math.cos(angleSlice * i) > 0 ? 'start' : 'end')); // this adjusts the anchoring based on the quadrant
          });
          

        // Add the data
        const line = d3.line()
            .x(d => width / 2 + d.value / maxValue * radius * Math.cos(d.angle - Math.PI / 2))
            .y(d => height / 2 + d.value / maxValue * radius * Math.sin(d.angle - Math.PI / 2))
            .curve(d3.curveCardinalClosed);

        svg.append('path')
            .datum(data.map((d, i) => ({
                angle: i * angleSlice,
                value: d.value
            })))
            .attr('d', line)
            .style('fill', 'rgba(0, 100, 200, 0.6)')
            .style('stroke', 'blue');


    }, [data]);

    return  <svg style={{width:500,height:500}} ref={svgRef}></svg>;
   
}

export default SpiderChart;
