import React from "react";
import SignIn from "./signIn.js";

export function landingPage() {
  return <div className="landing-page">


    <SignIn />
  </div>;
}
