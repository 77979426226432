import React from "react";
import { signOutUser } from "../signOutUser";


export function wrongRoleTeacherWarning() {
  return <div className="warning banner">
    <h2>Teacher account still logged in</h2>
    <h4>It looks like you may be trying to test your schools student link</h4>
    <h4><button onClick={() => { signOutUser()  }}>Sign out of your teacher account</button> first if you want to test the student app</h4>
  </div>;
}
