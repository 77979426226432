import React, { useState, useEffect } from 'react';
import { getFunctions, httpsCallable } from "firebase/functions";
import { getDatabase, ref, set, update, push } from "firebase/database";
import ReactMarkdown from 'react-markdown';
import app from '../../firebase';

const RunReportGenerator = ({ schoolData, reportId, initialRunReportId, reportTemplate, extendedSubmissions }) => {
    const [sections, setSections] = useState([]);
    const [currentIndex, setCurrentIndex] = useState(0);
    const [results, setResults] = useState([]);
    const [isComplete, setIsComplete] = useState(false);
    const [runReportId, setRunReportId] = useState(initialRunReportId);

    useEffect(() => {
        if (reportTemplate) {
            const sortedSections = sortSectionsByDependencies(reportTemplate.sections);
            setSections(sortedSections);
        }
    }, [reportTemplate]);

    useEffect(() => {
        if (sections.length > 0 && currentIndex < sections.length) {
            generateSection(sections[currentIndex]);
        }
    }, [sections, currentIndex]);

    const sortSectionsByDependencies = (sections) => {
        const sorted = [];
        const processed = new Set();

        const processSection = (section) => {
            if (processed.has(section.id)) return;
            if (!section.dependencies || section.dependencies.length === 0) {
                sorted.push(section);
                processed.add(section.id);
            } else {
                section.dependencies.forEach(dep => {
                    const depSection = sections.find(s => s.id === dep);
                    if (depSection) processSection(depSection);
                });
                if (!processed.has(section.id)) {
                    sorted.push(section);
                    processed.add(section.id);
                }
            }
        };

        sections.forEach(section => processSection(section));
        return sorted;
    };

    const generateSection = async (section) => {
        const functions = getFunctions(app);
        const talkToData = httpsCallable(functions, 'talkToData');

        try {
            // Step 1: Data Analysis
            const dataAnalysisPrompt = `You are an AI assistant tasked with getting data for an analyst to look at. Your goal is to examine the provided information and instructions, then create a large suite of at least 5 quantitative statistics.
    
            Here's the information you need to work with:
    
            Title: ${section.title}
            Research Goal: ${section.researchGoal}
            
    
            Your usual tasks are as follows:
            1. Carefully read and understand the Title, Research Goal, and Data Instructions.
            2. Based on the Data Instructions, create and run a python script which gives suitable data. Sometimes using filtering of categories, sorting values to find specific information, and usually focusing on averages and distribution of understanding and confidence.
            3. Create A suite of data 
            5. Describe what process you went through to get this data.
    
            MOST IMPORTANT DATA INSTRUCTIONS FOR YOU tO FOLLOW are below: 
            DATA INSTRUCTIONS: ${section.dataInstructions}

            Remember, be precise, in your analysis.`;

            const analysisResult = await talkToData({
                schoolId: schoolData.path,
                userMessage: dataAnalysisPrompt,
                conversationHistory: JSON.stringify([], null, 2),
                threadId: null,
                fileData: extendedSubmissions
            });

            const analysisData = analysisResult.data.message;

            // Step 2: Report Writing
            const reportWritingPrompt = `You are an AI assistant tasked with writing a section of a report based on the data analysis from the previous step. Your goal is to create a well-structured, informative, and coherent report section that addresses the research goal and follows the given structure instructions.
    
            Here's the information you need to work with:
    
            Title: ${section.title}
            Research Goal: ${section.researchGoal}
            Structure Instructions: ${section.structureInstructions}
    
            You will output only the report section, never say hello to the user or ask clarifying questions.
    
            Your tasks are as follows:
            1. Carefully review the Title, Research Goal, Structure Instructions, and the analysis results provided from the previous step.
            2. Usually some suitable quotes from students (found in descriptions in your data), and consider if they are suitable for this report section.
            3. Ensure that your writing directly addresses the Research Goal.
            4. Incorporate the analysis results, key findings, and insights seamlessly into your writing, providing context and interpretation where appropriate.
            5. Use professional language suitable for the target audience of the report, and don't be overly verbose (don't say delve, dive in etc.).
            6. If you identify any gaps in the information or need additional clarification, note these at the end of your report section.
    
            NEVER MAKE THINGS UP. YOU NEVER MAKE THINGS UP. ALL INFORMATION MENTIONED MUST COME FROM statistical information OR FROM THE ATTACHED FILE!!!!!
            
            Here is the statistical information for you to work with which you must reference:
            ${analysisData}
            `;

            const reportResult = await talkToData({
                schoolId: schoolData.path,
                userMessage: reportWritingPrompt,
                conversationHistory: JSON.stringify([], null, 2),
                threadId: null,
                fileData: extendedSubmissions
            });

            const reportMessage = reportResult.data.message;
            saveSectionResult(section.id, reportMessage);

            setResults(prevResults => [...prevResults, { id: section.id, message: reportMessage }]);
            setCurrentIndex(prevIndex => prevIndex + 1);
        } catch (error) {
            console.error("Error generating section:", error);
        }
    };

    const saveSectionResult = async (sectionId, message) => {
        const db = getDatabase(app);
        let reportRef;

        if (!runReportId) {
            const newReportRef = ref(db, `schools/${schoolData.path}/private/reportRuns/${reportId}/`);
            const newKey = push(newReportRef).key;
            setRunReportId(newKey);
            reportRef = ref(db, `schools/${schoolData.path}/private/reportRuns/${reportId}/` + newKey);

            // Save the initial report data including the timestamp
            await set(reportRef, {
                createdAt: new Date().toISOString(),
                sections: {
                    [sectionId]: message
                },
                complete: false
            });
        } else {
            reportRef = ref(db, `schools/${schoolData.path}/private/reportRuns/${reportId}/` + runReportId);

            // Update the report with the new section result
            await update(reportRef, {
                [`sections/${sectionId}`]: message,
                complete: false
            });
        }

        if (currentIndex === sections.length - 1) {
            await update(reportRef, {
                complete: true
            });
            setIsComplete(true);
        }
    };


    if (isComplete) {
        return <div style={{ textAlign: "left" }}> <p>Report generation complete!</p>
            {results.map(result => (
                <div key={result.id}>
                    <h3>Section {result.id}</h3>
                    <ReactMarkdown>{result.message.toString()}</ReactMarkdown>
                    {/* <ReactMarkdown> {toString(result.message)}</ReactMarkdown> */}
                </div>
            ))}
        </div>;
    }

    return (
        <div style={{ textAlign: "left" }}>
            <h2>Generating Report...</h2>
            {results.map(result => (
                <div key={result.id}>
                    <h3>Section {result.id}</h3>
                    <ReactMarkdown>{result.message.toString()}</ReactMarkdown>

                </div>
            ))}

        </div>
    );
};

export default RunReportGenerator;
