import { getAuth, signOut } from "firebase/auth";
import app from './firebase.js';

export function signOutUser() {
  const auth = getAuth(app);
  signOut(auth).then(() => {
    window.location.reload();
  }).catch((error) => {
    console.log(error);
  });
}
