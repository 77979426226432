import React, { useState } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { getAuth, signInWithEmailAndPassword, updateEmail } from "firebase/auth";

const ChangeEmailForm = ({ closeEmailChange }) => {
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(false);

  const handleSubmit = async (values) => {
    const auth = getAuth();
    const email = values.email;
    const password = values.password;
    const newEmail = values.newEmail;

    try {
      const userCredential = await signInWithEmailAndPassword(auth, email, password);
      const user = userCredential.user;

      await updateEmail(user, newEmail);

      setSuccess(true);

      setTimeout(() => {
        closeEmailChange();
      }, 1000);
    } catch (error) {
      setError(error.message);
    }
  };

  return (
    <div className="my-modal">
      <div className="my-modal-content">
        <div className="my-modal-header">
          <span className="close" onClick={closeEmailChange}>
            &times;
          </span>
          <h2>Change Email</h2>
        </div>
        <Formik initialValues={{ email: "", password: "", newEmail: "" }} onSubmit={handleSubmit}>
          <Form>
            <div className="my-modal-body form-group">
              {error && <div className="alert alert-danger">{error}</div>}
              {success && <div className="alert alert-success">Email updated successfully.</div>}

              <label htmlFor="email">Current Email</label>
              <Field type="email" id="email" name="email" className="form-control" />

              <label htmlFor="password">Password</label>
              <Field type="password" id="password" name="password" className="form-control" />

              <label htmlFor="newEmail">New Email</label>
              <Field type="email" id="newEmail" name="newEmail" className="form-control" />

              <button type="submit" className="btn btn-primary">
                Submit
              </button>
            </div>
          </Form>
        </Formik>
      </div>
    </div>
  );
};

export default ChangeEmailForm;
