import React from "react";
import StudentNavBar from "./navBar.js";
import LoggedInHome from "./homePage/loggedIn/loggedInHome.js"
import { getDatabase, ref, onValue } from "firebase/database";
import app from "../firebase";
import AreaPage from "./areaPage/areaPage.js"
import { BrowserRouter, Route, Routes, Link } from 'react-router-dom';
import SignUpStudent from "./signUpStudent.js";
import areasConfig from "../areasConfig.js";
import { studentHomeLoggedOut } from "./studentHomeLoggedOut";
import StudentPortfolioPage from "./studentPortfolioGetArea.js";
import StudentPrivacyPolicy from "./studentPrivacyPolicy.js";
import NextStepsPage from "./nextSteps/nextStepsPage.js";


class StudentPage extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            areaData: areasConfig
        }
    }

    componentDidMount() {

        console.log(this.props.user)
        this.addCustomArea()

    }

    addCustomArea = () => {
        const database = getDatabase(app);
        const areaPath = ref(database, 'schools/' + this.props.schoolData.path + '/public/areaConfig');
    
        onValue(areaPath, (snapshot) => {
            const data = snapshot.val();
            console.log("custom area from Database", data);
    
            if (data) {
                // Assuming areasData is already available in the state or as a prop
                let areasData = areasConfig; // or replace with areasConfig if available
                
                const areaKeys = Object.keys(data);
                for(let i = 0; i < areaKeys.length; i++) {
                    const currentArea = data[areaKeys[i]];
    
                    // Add the area of this area key to the areasData only if it's unique
                    if (!areasData.some(area => area.path === currentArea.path)) {
                        areasData.push(currentArea);
                    }
                }
    
                this.setState({ areasData: areasData });
            }
        });
    }



    render() {

        return (
            <div>
                <StudentNavBar user={this.props.user} schoolData={this.props.schoolData} />

                <Routes>
                    <Route path='/privacy*' element={<StudentPrivacyPolicy schoolData={this.props.schoolData} />} />
                </Routes>

                {this.props.user &&
                    <Routes>
                        <Route path='/area/:areaPath/*' element={<AreaPage schoolData={this.props.schoolData} user={this.props.user} areaData={this.state.areaData} />} />
                        <Route path='/improve' element={<NextStepsPage schoolData={this.props.schoolData} user={this.props.user} areaData={this.state.areaData} />} />
                        <Route path='/signUp' element={<div style={{textAlign:"center", marginTop:"3rem"}}> <Link className="btn btn-primary"  to={"../"}>Continue to account </Link> </div>} />
                        <Route path='/' element={<LoggedInHome schoolData={this.props.schoolData} user={this.props.user} areaData={this.state.areaData} />} />
                    </Routes>
                }

                {!this.props.user &&
                    <div style={{ textAlign: "center" }}>
                        <h2>{this.props.schoolData.name}</h2>
                        <Routes>
                            <Route path='/signUp' element={<SignUpStudent schoolData={this.props.schoolData} />} />
                            <Route path='/*' element={studentHomeLoggedOut()} />
                        </Routes>
                    </div>

                }


            </div>
        );
    }
}

export default StudentPage;


