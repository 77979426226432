import React from "react";
import { getDatabase, ref, onValue } from "firebase/database";
import app from '../../../firebase.js';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckSquare, faSquare } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';

class NeneParkIcon extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            bronzeComplete: false,
            silverComplete: false,
            goldComplete: false,
            allCharacteristicsStatus: {},
            customInformation: [
                {
                    areaPath: "teoUOqn",  // Participation
                    characteristics: [
                        { id: 77846790426, text: "Bronze" },
                        { id: 18716484173, text: "Silver" },
                        { id: 19888150959, text: "Gold" }
                    ]
                },
                {
                    areaPath: "28vM7fA",  // Leadership
                    characteristics: [
                        { id: 77846790426, text: "Bronze" },
                        { id: 18716484173, text: "Silver" },
                        { id: 19888150959, text: "Gold" }
                    ]
                },
                {
                    areaPath: "QHs29Zk",  // Diversity
                    characteristics: [
                        { id: 77846790428, text: "Bronze" },
                        { id: 18716484175, text: "Silver" },
                        { id: 19888150961, text: "Gold" }
                    ]
                },
                {
                    areaPath: "ZAf4AYZ",  // Excellence
                    characteristics: [
                        { id: 77846790427, text: "Bronze" },
                        { id: 18716484174, text: "Silver" },
                        { id: 19888150960, text: "Gold" }
                    ]
                },
                {
                    areaPath: "facQfB0",  // Giving
                    characteristics: [
                        { id: 77846790429, text: "Bronze" },
                        { id: 18716484176, text: "Silver" },
                        { id: 19888150962, text: "Gold" }
                    ]
                },
                {
                    areaPath: "nExdhrG",  // Service
                    characteristics: [
                        { id: 92863253643, text: "Bronze" },
                        { id: 13730909503, text: "Silver" },
                        { id: 44956160865, text: "Gold" }
                    ]
                }
            ]
        };
    }

    componentDidMount() {
        const database = getDatabase(app);
        const { schoolData, user } = this.props;

        const customInformation = this.state.customInformation


        const characteristicsStatus = {};

        customInformation.forEach((area) => {
            area.characteristics.forEach(characteristic => {
                const gracePath = ref(database, `schools/${schoolData.path}/submissions/${area.areaPath}/${user.uid}/grace/${characteristic.id}/award`);
                onValue(gracePath, (snapshot) => {
                    const data = snapshot.val();
                    characteristicsStatus[characteristic.id] = data && typeof data === 'number' && data >= 70;

                    this.setState({ allCharacteristicsStatus: characteristicsStatus }, this.checkOverallProgress);
                });
            });
        });
    }

    checkOverallProgress = () => {
        const { allCharacteristicsStatus } = this.state;
      
        const customInformation = this.state.customInformation
        // Extract Bronze, Silver, and Gold IDs
        const bronzeIDs = customInformation.flatMap(area =>
            area.characteristics.filter(char => char.text === 'Bronze').map(char => char.id)
        );
        const silverIDs = customInformation.flatMap(area =>
            area.characteristics.filter(char => char.text === 'Silver').map(char => char.id)
        );
        const goldIDs = customInformation.flatMap(area =>
            area.characteristics.filter(char => char.text === 'Gold').map(char => char.id)
        );

        // Check if all IDs for each level are complete
        const bronzeComplete = bronzeIDs.every(id => allCharacteristicsStatus[id]);
        const silverComplete = silverIDs.every(id => allCharacteristicsStatus[id]);
        const goldComplete = goldIDs.every(id => allCharacteristicsStatus[id]);
        this.setState({ bronzeComplete, silverComplete, goldComplete });
    };

    render() {
        const { bronzeComplete, silverComplete, goldComplete } = this.state;

        return (
            <div className="mt-3" style={{ fontSize: "1.5rem", textAlign: "center" }}>
                <img className="area-home-icon" style={{ opacity: "0%" }} src="https://firebasestorage.googleapis.com/v0/b/beworldclass-production.appspot.com/o/schools%2FmzoZkNiM6L%2Fpublic%2FareaConfig%2FteoUOqn%2Ficon?alt=media&token=c5b5697c-4a7b-4d87-a485-98879ccb654a" />
                <div>
                    <Link to={"area/bronze/customPortfolio"}>
                        <FontAwesomeIcon icon={bronzeComplete ? faCheckSquare : faSquare} className={bronzeComplete ? "text-success" : "text-secondary"} />
                    </Link>
                </div>
                <div>
                    <Link to={"area/silver/customPortfolio"}>
                        <FontAwesomeIcon icon={silverComplete ? faCheckSquare : faSquare} className={silverComplete ? "text-success" : "text-secondary"} />
                    </Link>
                </div>
                <div>
                    <Link to={"area/gold/customPortfolio"}>
                        <FontAwesomeIcon icon={goldComplete ? faCheckSquare : faSquare} className={goldComplete ? "text-success" : "text-secondary"} />
                    </Link>
                </div>

            </div>
        );
    }
}

export default NeneParkIcon;
