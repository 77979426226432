import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

function Whiteboard({ jsonData }) {

    const renderColumn = (column) => {
        switch (column.type) {
            case "text":
                return (
                    <div className={`col d-flex align-items-center ${column.size} ${column.color}`}>
                        {column.content}
                    </div>
                );
            case "icon":
                // Extract the numeric value from size like "2x", double it, and construct the font size
                const iconSizeMultiplier = parseInt(column.size.replace('x', '')) * 0.7;
                const fontSizeValue = `${iconSizeMultiplier * 0.75}rem`; // Assuming 1x = 0.75rem, adjust as needed
                return (
                    <div className={`col d-flex align-items-center justify-content-center ${column.size} ${column.color}`}>
                        <FontAwesomeIcon icon={column.icon} style={{ fontSize: fontSizeValue }} />
                    </div>
                );
            default:
                return null;
        }
    };

    return (
        <div className="container-fluid">
            <div className="card" style={{ borderRadius: '25px', pointerEvents: 'none' }}>

                <div className="row flex-grow-1" style={{ height: "500px" }}>
                    {jsonData.rows.map((row, index) => (
                        <div key={index} className="col-12 d-flex" style={{ flex: "1 0 auto" }}>
                            <div className="row g-0">
                                {row.columns.map((column, colIndex) => (
                                    renderColumn(column)
                                ))}
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
}

export default Whiteboard;
