import React from "react";
import EvidenceFile from "./student/areaPage/evidenceFile";
import { getDatabase, ref, update, onValue } from "firebase/database";
import app from "./firebase";
import { faStar, faUniversity, faShareNodes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
//import { Editor } from '@tinymce/tinymce-react';


class ViewSingleCharacteristic extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            loading:true,
            values:null
        }
    }

    componentDidMount() {

        console.log(`disabled=${true}
        schoolData=${this.props.schoolData}
        uid=${this.props.uid}
        index=${this.props.characteristicData.id}
        areaPath=${this.props.characteristicData.area}`)
        //check if i have values, otherwise check the database
        if (!this.props.values) {
            this.getData()
        } else {
            this.setState({values:this.props.values, loading:false})
        }
    }


    getData = () => {
        const database = getDatabase(app);
    
        const dataTypesToGet = ["description", "source", "date"];
        let fetchedDataCount = 0; // To keep track of how many data types have been fetched
        let stateValues = this.state.values ? this.state.values : {};
    
        dataTypesToGet.forEach(dataType => {
            const areaStatePath = ref(database, 'schools/' + this.props.schoolData.path +
                '/submissions/' + this.props.characteristicData.area +
                '/' + this.props.uid + '/values/' + dataType + this.props.characteristicData.id);
    
            onValue(areaStatePath, (snapshot) => {
                const data = snapshot.val();
                console.log("from Database", data);
                if (data) {
                    stateValues[dataType] = data;
                    fetchedDataCount++;
    
                    // Check if all data types have been fetched
                    if (fetchedDataCount === dataTypesToGet.length) {
                        this.setState({ values: stateValues, loading: false });
                    }
                }
            });
        });
    }
    

    close = () => {
        this.props.closeCharacteristic()
    }



    render() {
        console.log("close characteristic", typeof this.props.closeCharacteristic);

        const preStyle = { whiteSpace: "pre-wrap", fontFamily: "Arial", fontSize: "16px", color: "black" }

        return (
            <>
                <div className="my-modal">
                    <div className="my-modal-content">
                        <div className="my-modal-header">
                            <span className="close" onClick={this.close} >&times;</span>
                            <h2>View Characteristic</h2>
                        </div>
                        <div className="my-modal-body">
                            {this.state.values &&
                                <div className="card" style={{ marginBottom: "25px" }}>
                                    <div className="card-header text-dark row">

                                        <h3 className="card-title col-12">I {this.props.characteristicData.name}</h3>
                                    </div>
                                    <div style={{ textAlign: "left" }} className="card-body">

                                        <div className="row">
                                            <div className="col-6">

                                                <h5>Description</h5>
                                                <pre style={preStyle}>
                                                    {this.state.values.description}
                                                </pre>


                                                <h5>Source</h5>
                                                <pre style={preStyle}>
                                                    {this.state.values.source}
                                                </pre>
                                                <h5>Date of evidence</h5>
                                                <pre style={preStyle}>
                                                    {this.state.values.date}
                                                </pre>

                                            </div>

                                            <div className="col-6">
                                                <div className="form-group">
                                                    <div className="form-group">
                                                        <h5>Evidence</h5>
                                                        <EvidenceFile
                                                            disabled={true}
                                                            schoolData={this.props.schoolData}
                                                            uid={this.props.uid}
                                                            index={this.props.characteristicData.id}
                                                            areaPath={this.props.characteristicData.area}
                                                        />
                                                    </div>
                                                </div>
                                            </div>

                                        </div>



                                        <div className="col-12">



                                        </div>

                                    </div>

                                </div>
                            }
                        </div>
                    </div>
                </div >
            </>
        );
    }
}

export default ViewSingleCharacteristic;

