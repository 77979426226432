import React, { useEffect, useRef, useState } from 'react'; 
import * as d3 from 'd3';
import debounce from 'lodash/debounce';

const AnimatedPieChart = ({ data, title }) => {
    const ref = useRef();

    // State to toggle the visibility of labels
    const [showLabels, setShowLabels] = useState(false);

    // Initialize the color scale outside the render cycle
    const colorScale = d3.scaleOrdinal(d3.schemeTableau10);

    // Create a color map for consistency, if data is valid
    const colorMap = data && Array.isArray(data) ? data.reduce((map, d) => {
        map[d.name] = colorScale(d.name);
        return map;
    }, {}) : {};

    // Function to toggle the label visibility
    const toggleLabels = () => {
        setShowLabels(!showLabels);
    };

    // Function to draw chart
    const drawChart = (chartWidth) => {
        if (!Array.isArray(data) || data.length === 0) {
            return;
        }

        const height = Math.min(1500, chartWidth);
        const outerRadius = height / 2 - 10;
        const innerRadius = outerRadius * 0.75;

        // Clear previous content
        d3.select(ref.current).selectAll('*').remove();

        const svg = d3.select(ref.current)
            .attr('viewBox', [-chartWidth / 2, -height / 2, chartWidth, height])
            .attr('width', chartWidth)
            .attr('height', height);

        if (title) {
            svg.append('text')
                .attr('x', 0)
                .attr('y', 0)
                .attr('text-anchor', 'middle')
                .style('font-size', '20px')
                .style('font-weight', 'bold')
                .text(title);
        }

        const pie = d3.pie()
            .sort(null)
            .value(d => d.percentage);

        const arc = d3.arc()
            .innerRadius(innerRadius)
            .outerRadius(outerRadius);

        svg.selectAll('path')
            .data(pie(data)) // Use all data here
            .join('path')
            .attr('fill', (d) => colorMap[d.data.name]) // Use consistent color from the color map
            .attr('d', arc);
    };

    // Resize function to handle chart resizing
    const handleResize = debounce(() => {
        if (ref.current && Array.isArray(data) && data.length > 0) {
            const { width } = ref.current.parentElement.getBoundingClientRect();
            drawChart(width);
        }
    }, 200); // Debounce for performance (200ms delay)

    useEffect(() => {
        if (ref.current && Array.isArray(data) && data.length > 0) {
            const { width } = ref.current.parentElement.getBoundingClientRect();
            drawChart(width);
        }

        // Set up event listener for resizing
        window.addEventListener('resize', handleResize);

        // Cleanup event listener on component unmount
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, [data]); // Dependency array to avoid unnecessary redraws

    if (!Array.isArray(data) || data.length === 0) {
        // Render a user-friendly message if data is not available or invalid
        return (
            <div style={{ textAlign: 'center', padding: '20px' }}>
                <h3>{title || 'Pie Chart'}</h3>
                <p style={{ color: 'red' }}>No data available</p>
            </div>
        );
    }

    return (
        <div style={{ width: '100%' }}>
            {/* SVG for D3 pie chart */}
            <svg ref={ref}></svg>

            {/* Conditionally render labels outside of the D3 chart */}
            {showLabels && (
                <div className="mt-3">
                    {data
                        .sort((a, b) => b.percentage - a.percentage)
                        .slice(0, 6) // Only show the top 6 labels
                        .map((d) => (
                            <div key={d.name} style={{ display: 'flex', alignItems: 'center' }}>
                                <span
                                    style={{
                                        fontSize: '1.5rem',
                                        color: colorMap[d.name], // Use the color from the color map
                                    }}
                                >
                                    &#9679;
                                </span>
                                <span style={{ marginLeft: '8px' }}>
                                    {d.name} - {d.percentage}%
                                </span>
                            </div>
                        ))}
                </div>
            )}

            {/* Toggle button */}
            <button className="btn btn-primary mt-3" onClick={toggleLabels}>
                {showLabels ? 'Hide Labels' : 'Show Labels'}
            </button>
        </div>
    );
};

export default AnimatedPieChart;
