import React, { useEffect, useRef } from 'react';
import * as d3 from 'd3';

const BarGraph = ({ data, showPercentageLabel }) => {
    const svgRef = useRef();

    useEffect(() => {
        if (!data || data.length === 0) return;

        d3.select(svgRef.current).selectAll("*").remove(); // Clear existing

        const aspectRatio = 50 / 10;
        const containerWidth = svgRef.current.parentElement.offsetWidth;
        const height = containerWidth / aspectRatio;
        const width = containerWidth;

        const margin = { top: 0, right: 0, bottom: 0, left: 0 };
        const adjustedWidth = width - margin.left - margin.right;
        const adjustedHeight = height - margin.top - margin.bottom;

        const svg = d3.select(svgRef.current)
            .attr("viewBox", `0 0 ${adjustedWidth + margin.left + margin.right} ${adjustedHeight + margin.top + margin.bottom}`)
            .append("g")
            .attr("transform", `translate(${margin.left},${margin.top})`);

        // Scale the range of the data
        const x = d3.scaleLinear()
            .domain([0, 100]) // Fixed to 0-100% for the axis
            .range([0, adjustedWidth]);

        const y = d3.scaleBand()
            .range([0, adjustedHeight])
            .domain(data.map(d => d.label))
            .padding(0.1);

        // Append bars
        svg.selectAll(".bar")
            .data(data)
            .enter().append("rect")
            .attr("class", "bar")
            .attr("width", d => x((d.value / d.maxValue) * 100)) // Correct calculation for width based on percentage
            .attr("y", d => y(d.label))
            .attr("height", y.bandwidth())
            .attr("fill", (d, i) => d3.schemeTableau10[i % 10]);

        if (showPercentageLabel) {

            // Add the x Axis
            svg.append("g")
                .attr("transform", `translate(0,${adjustedHeight})`)
                .call(d3.axisBottom(x).tickFormat(d => `${parseInt(Math.round(d))}%`));
        }

        // Add the y Axis
        svg.append("g")
            .call(d3.axisLeft(y));

        // Add percentage labels if enabled

        svg.selectAll(".label")
            .data(data)
            .enter().append("text")
            .text(d => `${((d.value / d.maxValue) * 100).toFixed(0)}%`)
            .attr("x", d => x((d.value / d.maxValue) * 100)) // Correct position for text based on percentage
            .attr("y", d => y(d.label) + y.bandwidth() / 2)
            .attr("dy", ".35em")
            .attr("text-anchor", "end")
            .style("fill", "#fff")
            .style("font-size", "12px"); // Ensure text is readable

    }, [data, showPercentageLabel]);

    return (
        <div style={{ width: '100%', aspectRatio: '50 / 10' }}>
            <svg ref={svgRef} style={{ width: '100%', height: '100%' }} />
        </div>
    );
};

export default BarGraph;
