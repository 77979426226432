import React from "react";
import { getStorage, uploadBytes, ref, getDownloadURL } from "firebase/storage";
import { getDatabase, set, ref as databaseRef, onValue } from "firebase/database";
import { Formik, Field, Form, ErrorMessage, useFormikContext } from "formik";
//import { getDatabase, ref, set, onValue } from "firebase/database";
import app from "../firebase";




class UploadAreaIcon extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            errors: {},
            submitAttempt: false,
            submission: null,
            storagePath: "",
            imageUrl: null

        }
    }

    componentDidMount() {

        this.setState({ storagePath: "schools/" + this.props.schoolData.path + "/public/areaConfig/"+ this.props.selectedArea +"/icon"  }, () => {

        this.getFileUrl()

        });


    }

    getFileUrl = () => {
        const storage = getStorage(app);
        getDownloadURL(ref(storage, this.state.storagePath))
            .then((url) => {

                this.setImageUrlInDatabase(url)
                this.props.handleImageUrl(url)

            })
            .catch((error) => {
                console.log(error)
            });

    }

    setImageUrlInDatabase = (url) => {
        const database = getDatabase(app)
        const submissionPath = databaseRef(database, 'schools/' + this.props.schoolData.path + '/public/areaConfig/' +this.props.selectedArea + '/imageUrl');

        set(submissionPath, url);
        this.setState({ imageUrl: url })
    }

    uploadFile = (e) => {
        const file = e.target.files[0];
        console.log(file)



        const storage = getStorage(app);
        const storageRef = ref(storage, this.state.storagePath);
        const metadata = {
            contentType: 'image/jpeg',
        };

        console.log("storageref", storageRef)
        // Upload the file and metadata


        uploadBytes(storageRef, file).then((snapshot) => {
            this.getFileUrl()
        });
    }


    render() {
        return (
            <div>
                {!this.props.disabled &&
                    <div>
                        <label className={`btn ${this.state.imageUrl ? "btn-success" : "btn-primary"} `} for={"fileUpload" + this.props.index}>
                            {this.state.imageUrl ? "Replace file" : "Upload icon"}
                        </label>
                        <input
                            className="hidden"
                            type="file"
                            id={"fileUpload" + this.props.index}
                            value={this.state.file}
                            onChange={this.uploadFile}
                            accept="image/*"
                        />
                        <p>{this.state.imageUrl ?"": "This could be the logo for you school"}</p>
                    </div>
                }
                {
                    this.state.imageUrl &&
                    <img width="40%" src={this.state.imageUrl}></img>
                }




            </div >
        );
    }
}

export default UploadAreaIcon;