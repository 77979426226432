import React from "react";
import { getDatabase, ref, set, onValue, get } from "firebase/database";
import app from "../../firebase.js";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft, faArrowRight } from "@fortawesome/free-solid-svg-icons";


class AreaSteps extends React.Component {

    constructor(props) {
        super(props);
        this.saveButton = React.createRef();
        this.state = {
            step: null,
            visibleStep: 0,
            expectedStep: null
        }
    }

    componentDidMount() {


        const database = getDatabase(app);

        const areaStepPath = ref(database, 'schools/' + this.props.schoolData.path + '/submissions/' + this.props.area.path + '/' + this.props.user.uid + "/step")
        onValue(areaStepPath, (snapshot) => {
            const data = snapshot.val();
            console.log("step from Database", data)
            if (data !== undefined) {
                this.setState({ step: data, visibleStep: data }, () => {

                    //this.calculateExpectedStep()
                })
            }
        })

    }
/*
    calculateExpectedStep = () => {
        let characteristics = this.props.area.characteristics.sort((a, b) => a.step - b.step);
        const values = this.props.values.values
        let step = 0
        for (let i = 0; i < characteristics.length; i++) {
            const characteristic = characteristics[i]
            if (values && values["description" + characteristic.id] && values["description" + characteristic.id] != "") {
                step = characteristic.step
                console.log("checking", "description" + characteristic.id)
            } else {
                if (i == characteristics.length - 1) {
                    console.log("last step")
                    break

                } else if (characteristics[i + 1].step == characteristics[i].step) {
                    console.log("next step is same so do nothing", characteristics[i + 1].step)
                    break
                } else {
                    console.log("last character of step")
                    //characteristic.step + 1
                    break
                }
            }
        }

        this.setState({ expectedStep: step })

    }*/

    nextStep = () => {
        console.log("next")
        let characteristics = this.props.area.characteristics.sort((a, b) => a.step - b.step);
        const maxStep = characteristics[characteristics.length - 1].step

        if (this.state.step < maxStep) {
            const database = getDatabase(app)
            const areaStepPath = ref(database, 'schools/' + this.props.schoolData.path + '/submissions/' + this.props.area.path + '/' + this.props.user.uid + "/step");

            set(areaStepPath, this.state.step ? this.state.step + 1 : 1);
        }

        //this.calculateExpectedStep()

    }

    previousStep = () => {


        if (this.state.step > 0) {
            const database = getDatabase(app)
            const areaStepPath = ref(database, 'schools/' + this.props.schoolData.path + '/submissions/' + this.props.area.path + '/' + this.props.user.uid + "/step");

            set(areaStepPath, this.state.step ? this.state.step - 1 : 0);
        }

       // this.calculateExpectedStep()

    }


    render() {

        let characteristics = this.props.area.characteristics.sort((a, b) => a.step - b.step);
        const maxStep = characteristics[characteristics.length - 1].step


        return (
            <div>
                {maxStep > 0 &&
                    <>
                        {true &&
                            <button type="button" className="btn btn-outline-primary"   disabled={this.state.visibleStep < 1} onClick={this.previousStep}>
                                <FontAwesomeIcon icon={faArrowLeft} />
                            </button>
                        }
                        <span> <b> Step {this.state.visibleStep + 1}/{maxStep + 1} </b></span>

                        <button type="button" className="btn btn-outline-primary" onClick={this.nextStep}>
                            <FontAwesomeIcon icon={faArrowRight} />
                        </button>
                    </>
                }

            </div >
        );

    }
}

export default AreaSteps;