import React, { useState, useEffect } from "react";
import { Formik, Form, Field } from "formik";
import { getDatabase, ref, get, update } from "firebase/database";

const ChangeDetailsForm = ({ closeDetailsChange, user, schoolData }) => {
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(false);
  const [initialValues, setInitialValues] = useState(null);

  useEffect(() => {
    const database = getDatabase();
    const path = ref(database, `schools/${schoolData.path}/students/${user.uid}`);

    const fetchData = async () => {
      try {
        const snapshot = await get(path);
        const userDetails = snapshot.val();
        setInitialValues(userDetails);
        console.log(userDetails)
      } catch (error) {
        setError(error.message);
      }
    };

    fetchData();
  }, []);

  const handleSubmit = async (values) => {
    const database = getDatabase();
    const path = ref(database, `schools/${schoolData.path}/students/${user.uid}`);

    try {
      await update(path, {
        details: {
          firstName: values.firstName,
          lastName: values.lastName,
          yearGroup: values.yearGroup,
          class: values.class,
          gender: values.gender
        }
      });

      setSuccess(true);

      setTimeout(() => {
        closeDetailsChange();
      }, 1000);
    } catch (error) {
      setError(error.message);
    }
  };

  return (
    <div className="my-modal">
      <div className="my-modal-content">
        <div className="my-modal-header">
          <span className="close" onClick={closeDetailsChange}>
            &times;
          </span>
          <h2>Change Details</h2>
        </div>
        {initialValues && (
          <Formik initialValues={initialValues.details} onSubmit={handleSubmit}>
            <Form>
              <div className="my-modal-body form-group">
                {error && <div className="alert alert-danger">{error}</div>}
                {success && <div className="alert alert-success">Details updated successfully.</div>}

                <label htmlFor="firstName">First Name</label>
                <Field type="text" id="firstName" name="firstName" className="form-control" />

                <label htmlFor="lastName">Last Name</label>
                <Field type="text" id="lastName" name="lastName" className="form-control" />

                <label htmlFor="yearGroup">Year Group</label>
                <Field type="number" id="yearGroup" name="yearGroup" className="form-control" />

                <label htmlFor="class">Class/Tutor Group</label>
                <Field type="text" id="class" name="class" className="form-control" />

                <label htmlFor="gender">Gender</label>
                <Field as="select" id="gender" name="gender" className="form-control">
                  <option value="">Select</option>
                  <option value="male">Male</option>
                  <option value="female">Female</option>
                  <option value="other">Other</option>
                </Field>

                <button type="submit" className="btn btn-primary">
                  Submit
                </button>
              </div>
            </Form>
          </Formik>
        )}
      </div>
    </div>
  );
};

export default ChangeDetailsForm;
