import React, { Component } from "react";
import { render } from "react-dom";
import { BrowserRouter, Route, Routes, Link, useParams } from 'react-router-dom';

import "./index.css"
import 'bootstrap/dist/css/bootstrap.min.css';

import TeacherPage from "./teacher/teacherPage.js";
import SignUpSchool from "./teacher/signUpSchool.js";
import FindSchoolUrl from "./student/findSchoolUrl.js";
import ChatLink from "./chat/chatLink.js";
import WebsiteWidgetBanner from "./websiteWidgetBanner.js";


import { getAuth, createUserWithEmailAndPassword, onAuthStateChanged } from "firebase/auth";
import { getDatabase, ref, onValue, get } from "firebase/database"
import app from './firebase.js'
import { wrongRoleTeacherWarning } from "./teacher/wrongRoleTeacherWarning";
import { signOutUser } from "./signOutUser";
import { landingPage } from "./landingPage";
import UploadPage from "./htmlSharing/uploadPage.js"
import DisplayPage from "./htmlSharing/displaypage.js"




class App extends Component {

  constructor(props) {
    super(props);
    this.state = {
      user: null,

    }
  }


  componentDidMount() {

    const auth = getAuth(app);


    onAuthStateChanged(auth, (user) => {
      if (user) {
        console.log("got user")
        this.getUserRoles(user)
      } else {
        console.log("signed out")
      }
    })


  }

  getUserRoles = (user) => {

    const database = getDatabase(app);
    const databasePath = ref(database, 'users/' + user.uid)

    onValue(databasePath, (snapshot) => {
      const data = snapshot.val();
      console.log("got user roles", data)
      if (data) {
        this.getUserDetails(data, user)

      } else {
        console.log("no user roles")
      }
    });

  }

  getUserDetails = (userRoles, user) => {

    const rolePath = userRoles.role === "student" ? "students" : "staff"
    const database = getDatabase(app);
    console.log(rolePath)
    const databasePath = ref(database, 'schools/' + Object.keys(userRoles.schools)[0] + "/" + rolePath + "/" + user.uid)

    onValue(databasePath, (snapshot) => {
      const data = snapshot.val();
      console.log("user details", data)

      this.addUserDetailsToUser(data, user, userRoles);

    });


  }

  handleSignOut = () => {
    signOutUser();
  }


  addUserDetailsToUser(data, user, userRoles) {
    if (data) {

      let newUserObject = user;
      newUserObject.role = userRoles.role;
      newUserObject.school = Object.keys(userRoles.schools) ? Object.keys(userRoles.schools)[0] : undefined;
      newUserObject.firstName = data?.details?.firstName;
      newUserObject.lastName = data?.details?.lastName;
      newUserObject.yearGroup = data?.details?.yearGroup;
      newUserObject.gender = data?.details?.gender;
      this.setState({ user: newUserObject });
    } else {
      console.log("no user details");
    }
  }

  render() {

    const wrongRole = wrongRoleTeacherWarning();


    return (<>

      <BrowserRouter>
        <Routes>
          {this.state.user && this.state.user?.role !== "teacher" &&
            <Route path='/' element={<div className="app"><div style={{ margin: "2rem", padding: "2rem", textAlign: "center" }} className="card">
              <h4>Logged in</h4>
              <h4> <Link className="btn btn-primary" to={"student/" + this.state.user?.school}>Continue to my school</Link>{ } </h4></div></div>} />
          }

          <Route path='/student/:schoolPath/*' element={<div className="app"><div>
            {this.state.user?.role === "teacher" && wrongRole}
            {this.state.user?.role !== "teacher" && <FindSchoolUrl user={this.state.user} />}
          </div></div>} />

          <Route path='/student/*' element={<div className="app"><h2>Error: No school. Try going back to the login page</h2></div>} />

          {this.state.user && this.state.user.role === "teacher" &&
            <Route path='/*' element={<div className="app"><TeacherPage user={this.state.user} /></div>} />
          }
          {!this.state.user &&
            <Route path='/' element={<div className="app">{landingPage()}</div>} />
          }

          {!this.state.user &&
            <Route path='/signupSchool/:schoolPath/*' element={<div className="app"><SignUpSchool /></div>} />
          }

          {!this.state.user &&
            <Route path='/signupSafeStart/:schoolPath/*' element={<div className="app"><SignUpSchool safeStart={true} /></div>} />
          }

          {this.state.user &&
            <Route path='/signupSchool/:schoolPath/*' element={
              <div className="app" style={{ margin: "2rem", padding: "2rem", textAlign: "center" }}>
                <h4>Your school is signed up!</h4>
                <h4> <Link className="btn btn-primary" to={"/"}>Go to my account</Link>{ } </h4>
              </div>} />
          }

          {this.state.user &&
            <Route path='/signupSafeStart/:schoolPath/*' element={
              <div className="app" style={{ margin: "2rem", padding: "2rem", textAlign: "center" }}>
                <h4>Your school is signed up!</h4>
                <h4> <Link className="btn btn-primary" to={"/"}>Go to my account</Link>{ } </h4>
              </div>} />
          }


          <Route path='/usegpt/:schoolPath/:assistantId' element={<div className=""><ChatLink llama={false} /></div>} />
          <Route path='/llama/:schoolPath/:assistantId' element={<div className=""><ChatLink llama={true} /></div>} />
          <Route path='/websiteWidgetBanner/:schoolPath' element={<WebsiteWidgetBanner />} />
          <Route exact path="/htmlSharing" element={<UploadPage/>} />
          <Route path="h/:fileId" element={<DisplayPage/>} />
        </Routes>
      </BrowserRouter>


    </>
    );


  }
}
render(<App />, document.getElementById("root"));





