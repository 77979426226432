import React, { useEffect, useRef, useState } from 'react';
import * as d3 from 'd3';

function DateLineGraph({ data, metric = 'count' }) {
    const ref = useRef();
    const [dimensions, setDimensions] = useState({ width: 0, height: 0 });
    const [selectedDate, setSelectedDate] = useState(null);

    // Fill in missing dates and filter data for one year
    const processData = (data) => {
        const endDate = new Date();
        const startDate = new Date(new Date().setFullYear(new Date().getFullYear() - 1));

        const dateMap = d3.timeDay.range(startDate, endDate).reduce((acc, date) => {
            const dateString = d3.timeFormat('%Y-%m-%d')(date);
            acc[dateString] = { date: dateString, [metric]: 0 }; // Initialize all dates with 0
            return acc;
        }, {});

        data.forEach(d => {
            if (d.date in dateMap) {
                dateMap[d.date][metric] += d[metric]; // Sum metric if the date exists
            }
        });

        return Object.values(dateMap);
    };

    const calculateMovingAverage = (data, numberOfDays) => {
        return data.map((day, index, arr) => {
            const start = Math.max(0, index - numberOfDays + 1);
            const subset = arr.slice(start, index + 1);
            const sum = subset.reduce((a, b) => a + b[metric], 0);
            return {
                date: day.date,
                value: sum / numberOfDays // This ensures the denominator is always the number of days, including zeros
            };
        });
    };


    function calculateEMA(data, days, metric) {
        const alpha = 2 / (days + 1); // Smoothing factor
        let emaArray = [data[0] ? { date: data[0].date, value: data[0][metric] } : { date: '', value: 0 }];

        data.forEach((point, index) => {
            if (index > 0) {
                let ema = emaArray[index - 1].value * (1 - alpha) + point[metric] * alpha;
                emaArray.push({
                    date: point.date,
                    value: ema
                });
            }
        });

        return emaArray;
    }



    const findAnomalies = (data, threshold) => {
        const mean = d3.mean(data.filter(d => d[metric] > 0), d => d[metric]);
        const deviation = d3.deviation(data.filter(d => d[metric] > 0), d => d[metric]);

        return data.filter(d => d[metric] > mean + threshold * deviation);
    };

    useEffect(() => {
        const observer = new ResizeObserver(entries => {
            if (entries[0].contentRect.width !== dimensions.width) {
                setDimensions({
                    width: entries[0].contentRect.width,
                    height: entries[0].contentRect.width * 0.25
                });
            }
        });

        observer.observe(ref.current);
        return () => observer.disconnect();
    }, [dimensions.width]);

    useEffect(() => {
        if (dimensions.width && dimensions.height) {
            const processedData = processData(data); // Assumes processData handles filling dates with zero values
            const emaData = calculateEMA(processedData, 30, metric);

            const svg = d3.select(ref.current)
                .attr('width', dimensions.width)
                .attr('height', dimensions.height);

            svg.selectAll('*').remove();

            const margin = { top: 20, right: 50, bottom: 30, left: 40 };
            const width = dimensions.width - margin.left - margin.right;
            const height = dimensions.height - margin.top - margin.bottom;

            const x = d3.scaleTime()
                .domain(d3.extent(processedData, d => new Date(d.date)))
                .range([margin.left, width - margin.right]);

            const y = d3.scaleLinear()
                .domain([0, d3.max(processedData, d => d[metric])]).nice()
                .range([height - margin.bottom, margin.top]);

            const yEMA = d3.scaleLinear()
                .domain([d3.min(emaData, d => d.value), d3.max(emaData, d => d.value)])
                .range([height - margin.bottom, margin.top]);

            const line = d3.line()
                .x(d => x(new Date(d.date)))
                .y(d => y(d[metric]));

            const lineEMA = d3.line()
                .x(d => x(new Date(d.date)))
                .y(d => yEMA(d.value));

            // Append axes for both scales
            svg.append("g")
                .attr("transform", `translate(0,${height - margin.bottom})`)
                .call(d3.axisBottom(x).ticks(width / 80).tickSizeOuter(0));

            svg.append("g")
                .attr("transform", `translate(${margin.left},0)`)
                .call(d3.axisLeft(y).ticks(5));  // Adjust the number here to find a suitable number of ticks


            svg.append("path")
                .datum(processedData)
                .attr("fill", "none")
                .attr("stroke", "steelblue")
                .attr("stroke-width", 1.5)
                .attr("d", line);

            svg.append("path")
                .datum(emaData)
                .attr("fill", "none")
                .attr("stroke", "green")
                .attr("stroke-width", 1.5)
                .attr("d", lineEMA);

            // Reintroduce Anomaly Detection with Arrows
            const mean = d3.mean(processedData, d => d[metric]);
            const deviation = d3.deviation(processedData, d => d[metric]);
            const anomalies = findAnomalies(processedData, 1);  // Using threshold of 1 standard deviation


            anomalies.forEach(point => {
                svg.append('path')
                    .attr('d', d3.symbol().type(d3.symbolTriangle).size(40))
                    .attr('transform', `translate(${x(new Date(point.date))},${y(point[metric])})`)
                    .attr('fill', 'orange')
                    .attr('cursor', 'pointer');
            });
        }
    }, [data, dimensions, metric]);



    useEffect(() => {
        if (selectedDate) {
            console.log("Selected date:", selectedDate);
            // Handle state change or trigger an action
        }
    }, [selectedDate]);

    return <svg ref={ref} style={{ width: '100%', height: '100%' }} />;
}

export default DateLineGraph;
