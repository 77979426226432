import React from "react";
import Areas from "./areas.js"
import { getAuth, signOut } from "firebase/auth";
import Achievements from "./achievements.js";
import app from '../../../firebase.js'
import { Link } from 'react-router-dom';


class LoggedInHome extends React.Component {

  constructor(props) {
    super(props);
    this.state = {}
  }

  componentDidMount() {


  }

  signOutUser = () => {
    const auth = getAuth(app);
    signOut(auth).then(() => {
      window.location.reload()
    }).catch((error) => {
      // An error happened.
    });

  }

  render() {

    return (
      <div className="row" >
        {/* <button onClick={this.signOutUser}>sign out</button> */}
        <div className="col-12 jumbotron jumbotron-fluid">
          <div className="container">
            <h1 className="display-4">{this.props.schoolData.name}</h1>
          </div>
        </div>

        <div className="col-12 jumbotron jumbotron-fluid">
          <div style={{minHeight:"150px"}} className="container">
            <Areas schoolData={this.props.schoolData} user={this.props.user} areaData={this.props.areaData} />
          </div>
        </div>

        <div className="col-6" >
          <Achievements schoolData={this.props.schoolData} user={this.props.user} areaData={this.props.areaData} />
        </div>

        <div className="col-6" >
          <div className="container mt-5">
            <div className="card bg-light mb-3">
              <div className="card-header">
                <h3>Next Steps</h3>
              </div>
              <div className="card-body">
                <h5>You must be level 20 to start next steps</h5>
                {/* <Link to={"improve/"}> 
                  <button className="btn btn-primary" >See next steps</button>
                </Link> */}
              </div>
            </div>
          </div>

        </div>
      </div>
    );

  }
}

export default LoggedInHome;