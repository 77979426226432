import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { getDatabase, ref, set, child, get } from "firebase/database";

const CreateWebsiteWidget = ({ socialMediaPosts, schoolData }) => {
    const [showQuotes, setShowQuotes] = useState(false);
    const [showBannerWidget, setShowBannerWidget] = useState(false);
    const [showSquareWidget, setShowSquareWidget] = useState(false);
    const database = getDatabase();

    // Extracts and parses JSON from a given string
    const extractJsonFromString = (str) => {
        const jsonStart = str.indexOf('{');
        const jsonEnd = str.lastIndexOf('}') + 1; // +1 to include the last bracket
        if (jsonStart !== -1 && jsonEnd !== -1 && jsonEnd > jsonStart) {
            const jsonString = str.substring(jsonStart, jsonEnd);
            try {
                return JSON.parse(jsonString);
            } catch (error) {
                console.error('Error parsing JSON', error);
                return null;
            }
        } else {
            console.error('JSON structure not found');
            return null;
        }
    };

    const calculateStatistics = (socialMediaPosts) => {
        let quotesArray = Object.values(socialMediaPosts).map(post => {
            // Assuming postData.description[0].text.value contains the quote in JSON
            let parsedData = extractJsonFromString(post.description[0].text.value);
            return parsedData ? parsedData.quote : 'No quote found';
        }).filter(quote => quote !== 'No quote found'); // Filtering out posts without quotes

        return quotesArray;
    };

    const socialMediaQuotesArray = calculateStatistics(socialMediaPosts);

    const deployWidgetsToFirebase = () => {
        const widgetPath = `schools/${schoolData.path}/public/websiteWidget`;
        const widgetsRef = ref(database, widgetPath);

        // Check if it exists
        get(child(ref(database), widgetPath)).then((snapshot) => {
            if (snapshot.exists()) {
                console.log("Updating existing widgets");
            } else {
                console.log("Creating new widgets");
            }
            // Update or set the data
            set(widgetsRef, socialMediaQuotesArray).then(() => console.log('Deployed widgets to Firebase.')).catch((error) => console.error("Firebase deployment error:", error));
        }).catch((error) => console.error("Firebase read error:", error));
    };

    const copyToClipboard = (iframeCode) => {
      
        navigator.clipboard.writeText(iframeCode).then(() => {
            alert('Iframe code copied to clipboard!');
        }, (err) => {
            console.error('Could not copy text: ', err);
        });
    };

    return (
        <div className="container my-5">
            <div className="bg-white p-3 shadow-sm rounded">
                <h2 className="text-center">Website Widget Creator</h2>
            </div>
            <p className="text-muted text-center mt-3">
                <small>Quotes from students have had any identifiable information stripped from them using AI. Adding a BeWorldClass website widget is very similar to embedding a YouTube video. You can send the embedding code to your web developer.</small>
            </p>
            <div className="text-center mb-3">
                <button className="btn btn-light border" onClick={() => { setShowQuotes(false); setShowBannerWidget(false); }}>
                    <FontAwesomeIcon icon="arrow-left" /> Back
                </button>
                <button className="btn btn-primary ms-2" onClick={() => { setShowQuotes(!showQuotes); setShowBannerWidget(false); }}>
                    {showQuotes ? <><FontAwesomeIcon icon="eye-slash" /> Hide Quotes</> : <><FontAwesomeIcon icon="eye" /> Show Quotes</>}
                </button>
               
                <button className="btn btn-success ms-2" onClick={deployWidgetsToFirebase}>
                    <FontAwesomeIcon icon="cloud-upload-alt" /> Update Widgets
                </button>
            </div>

            {showQuotes && (
                <div className="row row-cols-1 row-cols-md-3 g-4 mb-4">
                    {socialMediaQuotesArray.map((quote, index) => (
                        <div key={index} className="col">
                            <div className="card h-100">
                                <div className="card-body">
                                    <p className="card-text">{quote}</p>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            )}

            {showBannerWidget && (
                <div className="mb-3">
                    <div className="d-flex justify-content-center">
                    </div>
                    <div className="d-flex justify-content-center mt-3">
                        <div className="bg-warning p-3" style={{ width: '150px', height: '150px' }}>Square Widget</div>
                    </div>
                </div>
            )}

            <div className="text-center mt-4">
                <h4>Example Widget Iframe</h4>
                <div className="input-group mb-3">
                    <input type="text" className="form-control" value={`    <iframe src="http://app.beworldclass.org/websiteWidgetBanner/${schoolData.path} " frameborder="0" style="width:100%; height:300px; overflow: hidden;" scrolling="no"></iframe>`} readOnly />
                    <button className="btn btn-outline-secondary" type="button" onClick={() => copyToClipboard(`    <iframe src="http://app.beworldclass.org/websiteWidgetBanner/${schoolData.path} " frameborder="0" style="width:100%; height:300px; overflow: hidden;" scrolling="no"></iframe>`)}>
                        <FontAwesomeIcon icon="copy" /> Copy
                    </button>
                </div>
            </div>
        </div>

    );
};

export default CreateWebsiteWidget;
