import React from "react";



class Activity extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            showModal: false,
            step: 0,
            errors: {}
        }
    }



    render() {
        return (
            <div>

                <button className="btn btn-primary my-2 my-sm-0" onClick={e => { this.setState({ showModal: true }) }}>
                    {this.state.step==0? "Start activity":"Try activity again"}
                </button>
                {this.state.showModal &&
                    <div className="my-modal">
                        <div className="my-modal-content">
                            <div className="my-modal-header">
                                <span className="close" onClick={e => { this.setState({ showModal: false }) }} >&times;</span>
                                <h2 style={{ color: "white" }}> Activity</h2>
                            </div>
                            <div className="my-modal-body form-group">
                                {this.state.step == 0 && <div>
                                    <h4>Video</h4>
                                    <p>Watch the video closely. There will be a quiz!</p>
                                    <iframe width="560" height="315" src="https://www.youtube.com/embed/HdxjGNJa1ns" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>

                                </div>}

                                {this.state.step == 1 && <div className="slide-in-blurred-right">
                                    <h4>{this.state.step} What does xyz mean?</h4>

                                    <button onClick={e => { this.setState({ step: this.state.step + 1 }) }} className="col-3 btn btn-primary btn-lg quizChoice">this </button>
                                    <button onClick={e => { this.setState({ step: this.state.step + 1 }) }} className="col-3 btn btn-primary btn-lg quizChoice">that</button>
                                    <button onClick={e => { this.setState({ step: this.state.step + 1 }) }} className="col-3 btn btn-primary btn-lg quizChoice">other</button>

                                </div>}

                                {this.state.step == 2 && <div className="slide-in-blurred-right">
                                    <h4>{this.state.step} Why do we...?</h4>

                                    <button onClick={e => { this.setState({ step: this.state.step + 1 }) }} className="col-3 btn btn-primary btn-lg quizChoice">this</button>
                                    <button onClick={e => { this.setState({ step: this.state.step + 1 }) }} className="col-3 btn btn-primary btn-lg quizChoice">that</button>
                                    <button onClick={e => { this.setState({ step: this.state.step + 1 }) }} className="col-3 btn btn-primary btn-lg quizChoice">something else</button>
                                    <button onClick={e => { this.setState({ step: this.state.step + 1 }) }} className="col-3 btn btn-primary btn-lg quizChoice">this</button>
                                </div>}
                                {this.state.step == 3 && <div className="slide-in-blurred-right">
                                    <h4>{this.state.step} What is abc for?</h4>

                                    <button onClick={e => { this.setState({ step: this.state.step + 1 }) }} className="col-3 btn btn-primary btn-lg quizChoice">this</button>
                                    <button onClick={e => { this.setState({ step: this.state.step + 1 }) }} className="col-3 btn btn-primary btn-lg quizChoice">that</button>
                                    <button onClick={e => { this.setState({ step: this.state.step + 1 }) }} className="col-3 btn btn-primary btn-lg quizChoice">something else</button>

                                </div>}

                                {this.state.step == 4 && <div className="slide-in-blurred-right">
                                    <h2>You scored 2/3!</h2>
                                    <p style={{ colour: "green" }}>
                                        1. what does xyz mean? Answer: this
                                    </p>
                                    <p style={{ colour: "red" }}>
                                        2. Why do we...? Answer: that
                                    </p>
                                    <p style={{ colour: "green" }}>
        
                                        3. What is abc for?
                                    </p>

                                    <button className="btn btn-outline-secondary" onClick={e => { this.setState({ step: 0 }) }}>Try again</button>
                                    <button className="btn btn-primary" onClick={e => { this.setState({ showModal: false }) }}>Continue</button>

                                </div>}







                            </div>
                            <div className="my-modal-footer">
                                {this.state.step < 0 &&
                                    <button className="btn btn-outline-primary" onClick={e => { this.setState({ step: this.state.step - 1 }) }}>Back</button>
                                }

                                {this.state.step < 1 &&
                                    <button className="btn btn-primary" onClick={e => { this.setState({ step: this.state.step + 1 }) }}>Next</button>
                                }

                            </div>


                        </div>
                    </div>
                }






            </div >
        );

    }
}

export default Activity;