import React from "react";
import app from "./firebase";
import { onValue, ref, getDatabase } from "firebase/database";
import EvidenceFile from "./student/areaPage/evidenceFile";

class Portfolio extends React.Component {
    constructor(props) {
        super(props);
        this.state = { loaded: false, values: null, foundFiles: [], grace: null };
    }

    componentDidMount() {
        const database = getDatabase(app);
        const areaStatePath = ref(
            database,
            "schools/" +
            this.props.schoolData.path +
            "/submissions/" +
            this.props.area.path +
            "/" +
            this.props.user.uid
        );
        onValue(areaStatePath, (snapshot) => {
            const data = snapshot.val();
            console.log("from Database", data);
            this.setState({ values: data.values, grace: data.grace, loaded: true });
        });
    }

    printPage = () => {
        window.print();
    };

    handleFileFound = (index) => {
        // Update the foundFiles array
        this.setState((prevState) => ({
            foundFiles: [...prevState.foundFiles, index]
        }));
    };

    render() {
        let characteristicsData = this.props.area.characteristics;
        let characteristics = [];
        if (this.state.values) {
            for (let i = 0; characteristicsData.length > i; i++) {
                const fileFound = this.state.foundFiles.includes(characteristicsData[i].id);

                if (this.state.values["description" + characteristicsData[i].id]) {


                    characteristics.push(
                        <div style={{
                            border: "1px solid #ddd",
                            marginBottom: "10px",
                            padding: "10px",
                            boxShadow: "0 4px 8px 0 rgba(0, 0, 0, 0.2)",
                            pageBreakInside: "avoid"
                        }}
                            className={`row ${fileFound ? "" : "no-file-found"}`}>
                            <h3 style={{ fontFamily: "Georgia, serif", fontSize: "1.5em" }}>
                                {characteristicsData[i].name}
                            </h3>
                            <div style={{
                                fontFamily: "Arial, sans-serif",
                                marginBottom: "10px",
                                width: fileFound ? "50%" : "100%"
                            }}>
                                <p>{this.state.values["description" + characteristicsData[i].id]}</p>
                            </div>

                            <div className="col-6">
                                <EvidenceFile
                                    disabled={true}
                                    schoolData={this.props.schoolData}
                                    uid={this.props.user.uid}
                                    index={characteristicsData[i].id}
                                    areaPath={this.props.area.path}
                                    onFileFound={this.handleFileFound}
                                />
                            </div>

                        </div>
                    );
                }
            }
        }


        // Get total award value
        let totalAwardValue = 0;
        for (let key in this.state.grace) {
            totalAwardValue += this.state.grace[key].award;
        }

        // Check if total award value is greater than characteristics length * 70
        const areaComplete = totalAwardValue >= (characteristicsData.length * 70) || this.state.values?.mark === "Complete";

        return (

            <div style={{ padding: "2rem", backgroundColor: "#f9f9f9", borderRadius: "10px", boxShadow: "0px 0px 10px rgba(0,0,0,0.1)" }}>
                <div className="row no-gutters justify-content-center no-print">
                    <div className="col-auto">
                        <button className="btn btn-primary" onClick={this.printPage}>
                            Save/Print
                        </button>
                    </div>
                </div>

                <div className="row no-gutters justify-content-center mt-5">
                    <div className="col-auto text-center">
                        <img src="https://i0.wp.com/beworldclass.org/wp-content/uploads/2022/06/cropped-BeWorldClass-transparent-rgb.png" alt="Certificate Seal" style={{ height: "100px" }} />
                    </div>
                </div>
                {areaComplete && (
                    <>
                        <div className="row no-gutters justify-content-center mt-5">
                            <div className="col-md-12 text-center" style={{ paddingBottom: "5rem" }}>
                                <p style={{ fontFamily: "Times New Roman, serif", fontSize: "1.2rem", fontStyle: "italic" }}>
                                    In recognition of outstanding commitment and significant accomplishment, we hereby recognise
                                </p>
                                <h2 style={{ fontFamily: "Georgia, serif", fontSize: "2.5rem" }}>
                                    {this.props.user.firstName} {this.props.user.lastName}
                                </h2>
                                <h3 className="text-light-color" style={{ fontFamily: "Arial, sans-serif", fontSize: "2rem" }}>
                                    {this.props.schoolData.name}
                                </h3>
                                <h4 className="text-light-color" style={{ fontFamily: "Arial, sans-serif", fontSize: "1.5rem" }}>
                                    Year {this.props.user.yearGroup}
                                </h4>
                                <p style={{ fontFamily: "Times New Roman, serif", fontSize: "1.2rem", fontStyle: "italic" }}>
                                    for successfully completing the <strong>{this.props.area.name}</strong> program.
                                    This achievement is a testament to their exemplary skills and characteristics demonstrated in this area.
                                </p>
                            </div>
                            <div className="col-4 text-center" style={{ margin: "auto" }}>

                                <p style={{ fontFamily: "Times New Roman, serif", fontSize: "1.2rem", fontStyle: "italic", margin: "auto" }}>
                                    {this.props.area.motto.replace("because I", "")}
                                </p>

                            </div>
                            <div className="col-4 text-center" style={{ padding: "2rem", }}>
                                <img width="175px" src={this.props.area.imageUrl} alt="Area" />

                            </div>
                            <div className="col-4 text-center" style={{ margin: "auto" }}>
                                <p style={{ fontFamily: "Times New Roman, serif", fontSize: "1.2rem", fontStyle: "italic" }}>
                                    Awarded this year of {new Date().getFullYear()}
                                </p>
                            </div>
                        </div>

                        <div className="page-break"></div>
                    </>
                )}
                <h4 style={{ fontFamily: "Times New Roman, serif", fontSize: "1.5rem", fontStyle: "italic" }}>
                    {this.props.area.motto}
                </h4>

                <div className="row no-gutters justify-content-center mt-3">
                    {characteristics}
                </div>
               
            </div>
        );
    }
}

export default Portfolio;
