import React from "react";
import { Formik, Field, Form } from "formik";
import { getFunctions, httpsCallable } from "firebase/functions";
import { getDatabase, ref, onValue, get } from "firebase/database"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import prompts from './gptTools.json'; // Adjust the path as needed
import ExistingChatToolCard from "./existingChatToolCard";
import ChatBot from "./chatBot";
import TeacherChat from "./teacherChat";


class TeacherChatHome extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            assistantsList: null, // To store the fetched list of assistants
            showMessage: null, // To show messages dynamically
            showCreateForm: false,
            showApiKeyInput: false,
            editingAssistant: null,
            selectedSidebarItem: 'tools',
            openedToolIndex: null,

            // Control visibility of the create assistant form
        };
    }

    componentDidMount = () => {
        this.getAssistants()
    }

    // Method to open a tool
    handleOpenTool = (index) => {
        this.setState({ openedToolIndex: index });
    }

    // Method to close a tool
    handleCloseTool = () => {
        this.setState({ openedToolIndex: null });
    }

    // Function to add an API key
    addApiKey = (values) => {

        const functions = getFunctions();
        const updateSchoolAIKey = httpsCallable(functions, 'updateSchoolAIKey');

        updateSchoolAIKey({ schoolId: this.props.schoolData.path, Key: values.apiKey })
            .then((result) => {
                // Handle result
                console.log(result);
                alert('API Key added successfully.');
            })
            .catch((error) => {
                // Handle error
                console.error("Error adding API key:", error);
                alert('Failed to add API Key.');
            });
    }

    // Function to get the list of assistants
    getAssistants = () => {
        console.log("Fetching Assistants List");
        const functions = getFunctions();
        const listAssistants = httpsCallable(functions, 'listAssistants');

        listAssistants({ schoolId: this.props.schoolData.path })
            .then((result) => {
                // Update state with the fetched list
                this.setState({ assistantsList: result.data });
                console.log(result.data);
            })
            .catch((error) => {
                // Handle error
                console.error("Error fetching assistants list:", error);
                alert('Failed to fetch assistants list.');
            });
    }

    selectForEdit = (assistant) => {
        console.log("editing", assistant)
        this.setState({ editingAssistant: assistant });
    }

    modifyAssistant = (values, { setSubmitting }) => {
        const functions = getFunctions();
        const modifyAssistantFn = httpsCallable(functions, 'modifyAssistant');

        modifyAssistantFn({
            schoolId: this.props.schoolData.path,
            assistantId: this.state.editingAssistant.id, // Ensure you have an 'id' or similar identifier
            ...values,
        })
            .then((result) => {
                alert('Assistant modified successfully.');
                console.log(result.data);
                setSubmitting(false);
                this.setState({ editingAssistant: null }); // Clear the editing state
                this.getAssistants(); // Optionally refresh the list
            })
            .catch((error) => {
                console.error('Error modifying assistant:', error);
                alert('Failed to modify assistant.');
                setSubmitting(false);
            });
    }

    createAssistant = (values, { setSubmitting }) => {
        const functions = getFunctions();
        const createAssistantFn = httpsCallable(functions, 'createAssistant');

        createAssistantFn({
            schoolId: this.props.schoolData.path,
            ...values, // Spread the form values directly
        })
            .then((result) => {
                alert('Assistant created successfully.');
                console.log(result.data);
                // Optionally refresh the assistants list here
                setSubmitting(false);
            })
            .catch((error) => {
                console.error('Error creating assistant:', error);
                alert('Failed to create assistant.');
                setSubmitting(false);
            });
    }

    toggleApiKeyInput = () => {
        this.setState({ showApiKeyInput: !this.state.showApiKeyInput })
    }

    render() {
        const { assistantsList } = this.state;

        const categoryColors = {
            "administration": "bg-primary",
            "communication": "bg-success",
            "lesson planning": "bg-info",
            "curriculum development": "bg-warning",
            "classroom management": "bg-danger",
            "student engagement": "bg-secondary"
        };

        const { openedToolIndex } = this.state;

        if (this.props.schoolData?.path) {



            return (

                <div className="row " >
                    <div style={{ textAlign: "left", padding: "1rem", height: "80vh" }} className="col-2 nav nav-pills">
                        <div className="card" >
                            <div className="card-body">


                                <li className="nav-item">
                                    <div className={`nav-link align-text-left ${this.state.selectedSidebarItem === 'tools' ? 'active' : ''}`} onClick={() => this.setState({ selectedSidebarItem: 'tools', openedToolIndex: null })}>
                                        <FontAwesomeIcon icon="fa-hammer" /> Tools
                                    </div>
                                </li>
                                <li className="nav-item">
                                    <div className={`nav-link align-text-left ${this.state.selectedSidebarItem === 'chatBot' ? 'active' : ''}`} onClick={() => this.setState({ selectedSidebarItem: 'chatBot', openedToolIndex: null })}>
                                        <FontAwesomeIcon icon="fa-robot" />  Chat
                                    </div>
                                </li>
                                <li className="nav-item " onClick={this.getAssistants}>
                                    <div className={`nav-link align-text-left ${this.state.selectedSidebarItem === 'deploy' ? 'active' : ''}`} onClick={() => this.setState({ selectedSidebarItem: 'deploy', openedToolIndex: null })}>
                                        <FontAwesomeIcon icon="fa-rocket" /> Deploy Bot
                                    </div>
                                </li>
                                <li className="nav-item">
                                    <div className={`nav-link align-text-left ${this.state.selectedSidebarItem === 'settings' ? 'active' : ''}`} onClick={() => this.setState({ selectedSidebarItem: 'settings', openedToolIndex: null })}>
                                        <FontAwesomeIcon icon="fa-cog" /> Settings
                                    </div>
                                </li>
                            </div>
                        </div>
                    </div>


                    <div className="col-10">
                        {assistantsList &&
                            this.state.selectedSidebarItem == "deploy" &&
                            !this.state.showCreateForm &&
                            !this.state.editingAssistant && (
                                <div  >
                                    <div className="row" style={{ marginTop: "1rem" }} >
                                        <div style={{ textAlign: "right" }} >
                                            <button
                                                className="btn btn-primary"
                                                onClick={() => this.setState({ showCreateForm: true })}
                                            >
                                                Create Bot
                                            </button>
                                        </div>
                                        {assistantsList.assistants.map((assistant) => (
                                            <div className="col-6">
                                                <div key={assistant.id} className="card mb-3">
                                                    <div className="card-body">
                                                        <h5 className="card-title">{assistant.name}</h5>
                                                        <p className="card-text text-truncate" style={{ maxHeight: '3rem', overflow: 'hidden' }}>
                                                            {assistant.instructions}
                                                        </p>
                                                        <a target="_blank" href={`/useGPT/${this.props.schoolData.path}/${assistant.id}`}>{`Link to bot`}</a> <br />

                                                        <button className="btn btn-primary" onClick={() => this.selectForEdit(assistant)}>Edit</button>
                                                    </div>
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            )}


                        <div className="row">
                            {this.state.selectedSidebarItem === "tools" && (
                                <>
                                    <div style={{ paddingTop: "1rem" }}></div>
                                    {openedToolIndex === null && prompts?.map((prompt, index) => (
                                        <ExistingChatToolCard
                                            schoolData={this.props.schoolData}
                                            key={index}
                                            prompt={prompt}
                                            categoryColors={categoryColors}
                                            isOpen={openedToolIndex === index}
                                            onOpen={() => this.handleOpenTool(index)}
                                            onClose={this.handleCloseTool}
                                        />
                                    ))}
                                    {openedToolIndex !== null && (
                                        <TeacherChat
                                            schoolData={this.props.schoolData}
                                            showModal={openedToolIndex !== null}
                                            handleClose={this.handleCloseTool}
                                            prompt={prompts[openedToolIndex]}
                                        />
                                    )}
                                </>
                            )}
                        </div>

                        {this.state.selectedSidebarItem == "chatBot" &&
                            // Use the PromptCard component for each prompt
                            <ChatBot schoolData={this.props.schoolData} />
                        }

                        {!assistantsList && (
                            <div>
                                loading...
                            </div>
                        )}

                        {this.state.selectedSidebarItem == "deploy" &&
                            (this.state.showCreateForm || this.state.editingAssistant) &&
                            <>
                                <div style={{ marginTop: "1rem" }} >
                                </div>

                                <div className="card">
                                    <div className="card-body" style={{ padding: "2rem" }} >

                                        {this.state.editingAssistant ? (
                                            <Formik
                                                initialValues={{ name: this.state.editingAssistant.name, instructions: this.state.editingAssistant.instructions }}
                                                onSubmit={this.modifyAssistant}
                                                key={this.state.editingAssistant?.id ? this.state.editingAssistant.id : ""}
                                            >
                                                {({ isSubmitting }) => (
                                                    <Form>
                                                        <div className="row">
                                                            <h3 className="card-title mb-3 col-9">Edit Bot</h3>
                                                            <div className="col-3" style={{ textAlign: "right" }} >

                                                                <a className="btn btn-outline-secondary" onClick={() => this.setState({ showCreateForm: false, editingAssistant: null })}>
                                                                    Cancel</a>
                                                            </div>
                                                        </div>
                                                        <div className="form-group">
                                                            <label >Name of bot</label>
                                                            <Field type="text" name="name" placeholder="Assistant Name" className="form-control" />
                                                        </div>
                                                        <div className="form-group">
                                                            <label style={{ marginTop: "1rem" }} >Bot instructions</label>
                                                            <Field as="textarea" name="instructions" placeholder="Instructions" className="form-control" rows="4" />
                                                            <small className="form-text text-muted">This is your "prompt" to create the bot</small>
                                                        </div>

                                                        <button style={{ marginTop: "1rem" }} type="submit" className="btn btn-primary" disabled={isSubmitting}>
                                                            Update Bot
                                                        </button>
                                                    </Form>
                                                )}
                                            </Formik>
                                        ) : (
                                            <Formik
                                                key={this.state.editingAssistant?.id ? this.state.editingAssistant.id : ""}
                                                initialValues={{ name: '', instructions: '', tools: '', model: '' }}
                                                onSubmit={this.createAssistant}
                                            >
                                                {({ isSubmitting }) => (
                                                    <Form>
                                                        <div className="row">

                                                            <h3 className="card-title mb-3 col-10" style={{ marginBottom: "2rem" }} >Create Bot</h3>
                                                            <div className="col-2"  style={{ textAlign: "right" }} >

                                                                <button className="btn btn-outline-secondary" onClick={() => this.setState({ showCreateForm: false, editingAssistant: null })}>
                                                                    Cancel</button>
                                                            </div>
                                                            <div className="form-group">
                                                                <Field type="text" name="name" placeholder="Assistant Name" className="form-control" />
                                                            </div>
                                                            <div className="form-group">
                                                                <Field as="textarea" style={{ height: "15rem" }} name="instructions" placeholder="Instructions" className="form-control" rows="3" />
                                                            </div>
                                                            <div>

                                                                <button type="submit" className="btn btn-primary" disabled={isSubmitting}>
                                                                    Create Bot
                                                                </button>
                                                            </div>

                                                        </div>
                                                    </Form>
                                                )}
                                            </Formik>
                                        )}
                                    </div>
                                </div>
                            </>
                        }




                        {this.state.selectedSidebarItem == "settings" && <div>
                            <button className="btn btn-primary" onClick={this.toggleApiKeyInput}>Update API Key</button>
                            {this.state.showApiKeyInput &&
                                <Formik
                                    initialValues={{ apiKey: '' }}
                                    onSubmit={(values, { setSubmitting }) => {
                                        this.addApiKey(values);
                                        setSubmitting(false);
                                    }}
                                >
                                    <Form>
                                        <Field type="text" name="apiKey" placeholder="Enter API Key" />
                                        <button type="submit">Submit API Key</button>
                                    </Form>
                                </Formik>
                            }


                        </div>}
                    </div>
                </div>
            )
        } else return (
            <p>loading</p>
        );
    }
}

export default TeacherChatHome;
