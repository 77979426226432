import React from 'react';
import { Link, Routes, Route } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faRobot, faUserGroup, faUser, faCog, faNetworkWired, faEyeSlash} from '@fortawesome/free-solid-svg-icons';
import ManageStaff from '../manageStaff';
import AIOptions from './aiOptions';
import CreateGroups from './createGroups';
import CreateCustomAreas from './createCustomAreas';
import OtherSettings from './otherSettings';

const SettingsPage = (props) => {
    return (
        <div className="container">


            <Routes>
                <Route path="/" element={
                    <>
                        <Link to="/settings/" className="list-group-item list-group-item-action">
                            <h2>Settings</h2>
                        </Link>
                        <div className="list-group">
                            <Link to="/settings/staff" className="list-group-item list-group-item-action">
                                <FontAwesomeIcon icon={faUser} /> Manage Staff
                            </Link>
                            {/* <Link to="/settings/ai-options" className="list-group-item list-group-item-action">
                                <FontAwesomeIcon icon={faRobot} /> AI Options
                            </Link> */}
                            <Link to="/settings/create-groups" className="list-group-item list-group-item-action">
                                <FontAwesomeIcon icon={faUserGroup} /> Create Groups
                            </Link>
                            <Link to="/settings/custom-areas" className="list-group-item list-group-item-action">
                                <FontAwesomeIcon icon={faNetworkWired} /> Custom Areas
                            </Link>
                            <Link to="/settings/other-settings" className="list-group-item list-group-item-action">
                                <FontAwesomeIcon icon={faEyeSlash} /> Hide Features 
                            </Link>
                        </div>
                    </>
                } />

                <Route path="create-groups" element={<><Link to="/settings" >Back</Link> <CreateGroups schoolData={props.schoolData} /></>} />
                <Route path="ai-options" element={<><Link to="/settings" >Back</Link> <AIOptions schoolData={props.schoolData} /></>} />
                <Route path="staff" element={<><Link to="/settings" >Back</Link> <ManageStaff schoolData={props.schoolData} /></>} />
                <Route path="custom-areas" element={<><Link to="/settings" >Back</Link> <CreateCustomAreas schoolData={props.schoolData} /></>} />
                <Route path="other-settings" element={<><Link to="/settings" >Back</Link> <OtherSettings schoolData={props.schoolData} /></>} />
            </Routes>
        </div>
    );
};

export default SettingsPage;
