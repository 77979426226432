import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import { getDatabase, ref, update } from "firebase/database";
import app from '../../firebase';
import { Form } from 'react-bootstrap'; // Import Form from react-bootstrap

const OtherSettings = (props) => {
    const [hideRequestFeedback, setHideRequestFeedback] = useState(props.schoolData.hideRequestFeedback || false);
    const [hideWorldClassAreas, setHideWorldClassAreas] = useState(props.schoolData.hideWorldClassAreas || false);

    useEffect(() => {
        // Syncing state with props for both settings
        setHideRequestFeedback(props.schoolData.hideRequestFeedback || false);
        setHideWorldClassAreas(props.schoolData.hideWorldClassAreas || false);
    }, [props.schoolData.hideRequestFeedback, props.schoolData.hideWorldClassAreas]);

    const handleSaveSettings = (e) => {
        e.preventDefault();
        const db = getDatabase(app);
        const schoolId = props.schoolData.path;
        const publicDataRef = ref(db, `schools/${schoolId}/public`);

        update(publicDataRef, {
            hideRequestFeedback: hideRequestFeedback,
            hideWorldClassAreas: hideWorldClassAreas,
        }).then(() => {
            alert("Settings saved successfully!");
        }).catch((error) => {
            console.error("Error saving settings: ", error);
        });
    };

    const handleRequestFeedbackChange = (e) => {
        const value = e.target.value;
        setHideRequestFeedback(value === 'yes');
    };

    const handleWorldClassAreasChange = (e) => {
        const value = e.target.value;
        setHideWorldClassAreas(value === 'yes');
    };

    return (
        <div className="container">
            <h3><FontAwesomeIcon icon={faEyeSlash} /> Hide Features</h3>
            <form onSubmit={handleSaveSettings}>
                <Form.Group className="mb-3">
                    <Form.Label>Hide the "Request feedback from teacher" button?</Form.Label>
                    <Form.Select aria-label="Hide request feedback" value={hideRequestFeedback ? 'yes' : 'no'} onChange={handleRequestFeedbackChange}>
                        <option value="no">No</option>
                        <option value="yes">Yes</option>
                    </Form.Select>
                    <br />
                    <Form.Label>Hide World Class Areas (leaving only your custom values)?</Form.Label>
                    <Form.Select aria-label="Hide World Class Areas" value={hideWorldClassAreas ? 'yes' : 'no'} onChange={handleWorldClassAreasChange}>
                        <option value="no">No</option>
                        <option value="yes">Yes</option>
                    </Form.Select>
                </Form.Group>
                <button className="btn btn-primary">Save</button>
            </form>
        </div>
    );
};

export default OtherSettings;
