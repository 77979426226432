import React from "react";
import FeedbackCharacteristic from "./feedbackCharacteristic";
import { Formik, Field, Form, ErrorMessage, useFormikContext } from "formik";
import { getDatabase, ref, update, onValue } from "firebase/database";
import app from "../firebase";




class GiveFeedback extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            errors: {},
            submitAttempt: false,
            submission: null,
            areaPath: null,
            uid: null,
        }
    }

    componentDidMount() {
        const propValues = this.props.values
        const selectedKey = this.props.selected

        const submission = propValues.filter(value => value.key === selectedKey)[0]

        const area = this.props.areasData.filter(thisArea => thisArea.path === submission.area)[0]
        this.setState({ areaPath: area.path, uid: submission.uid })

    }

    submitToFirebase = (values) => {
        const database = getDatabase(app)
        const submissionPath = ref(database, 'schools/' + this.props.schoolData.path + '/submissions/' + this.state.areaPath + '/' + this.state.uid);

        const progressEstimate = values.progressEstimate ? values.progressEstimate : 0
        Object.keys(values).forEach(key => {
            if ((!key.includes("feedback") && key !== "mark" && key !== "overallFeedback") || values[key] === undefined) {
                delete values[key];
            }
        })

        
        let valuesRef = ref(database, `schools/${this.props.schoolData.path}/submissions/${this.state.areaPath}/${this.state.uid}/values`);
        let markRef = ref(database, `schools/${this.props.schoolData.path}/submissions/${this.state.areaPath}/${this.state.uid}`);
        
        if (values) {
            update(valuesRef, values);
        }
        if(values.mark) {
            update(markRef, {mark: values.mark});
        }

        this.props.closeAction()
    }


    render() {
        const values = this.props.values
        const selectedKey = this.props.selected

        let submission = values.filter(value => value.key === selectedKey)[0]

        const area = submission ? this.props.areasData.filter(thisArea => thisArea.path === submission.area)[0] : null
        console.log("area", area, "submission", submission)
        let characteristics = []

        if (area && this.state.uid) {

            const characteristicsData = area.characteristics

            for (let i = 0; characteristicsData.length > i; i++) {
                

                characteristics.push(<FeedbackCharacteristic
                    schoolData={this.props.schoolData}
                    areaPath={this.state.areaPath}
                    uid={this.state.uid}
                    submission={submission}
                    id={characteristicsData[i].id}
                    index={i}
                    characteristicData={characteristicsData[i]} />)
                
            }
        }

        return (
            <div>

                <div className="my-modal">
                    <div className="my-modal-content">
                        <div className="my-modal-header">
                            <span className="close" onClick={(e) => this.props.closeAction()} >&times;</span>
                            <h2> Review</h2>
                        </div>
                        <Formik
                            initialValues={submission}
                            validate={values => {
                                let errors = {}
                                if (!values.mark) {
                                    errors.mark = "Required"
                                }

                                this.setState({ errors: errors })

                            }}
                            onSubmit={async values => {
                                await new Promise(resolve => setTimeout(resolve, 500));

                                this.submitToFirebase(values);
                                console.log(values)

                            }}

                        >
                            <Form >
                                <div className="my-modal-body form-group">
                                    <div className="row">
                                        <div className="col-9 feedback-left">
                                            <img src={area?.imageUrl} width="100px" />
                                            <h4>
                                                {submission.firstName} {submission.lastName}
                                            </h4>

                                            {characteristics}
                                        </div>

                                        <div className="col-3 feedback-right">
                                            <label >Overall feedback</label>
                                            <Field id={"overallFeedback"} name={"overallFeedback"} as="textarea" className="form-control" style={{ height: "200px" }} />

                                            <div className="form-group">
                                                <label >Review</label>
                                                <Field as="select" name="mark" className={`${this.state.errors.mark ? "is-invalid" : ""} form-control`} placeholder=" " >
                                                    <option></option>
                                                    <option value="Incomplete">Incomplete</option>
                                                    <option value="Complete">Complete</option>
                                                </Field>
                                                <small class="form-text text-muted">
                                                    Only review as complete if there is no more work left to do (you can undo this).
                                                </small>
                                                <div class="invalid-feedback">{this.state.errors.mark}</div>
                                            </div>

                                            <div style={{ textAlign: "right" }} className="my-modal-footer">
                                                <hr />
                                                <button className="btn btn-primary" type="submit"> Submit</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </Form>
                        </Formik>
                    </div>
                </div>
            </div >
        );
    }
}

export default GiveFeedback;