import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const IndividualSubmissionDetails = ({ submission, onBack }) => {
    if (!submission) return null;

    return (
        <div className="card h-100">
            <div className="card-header d-flex justify-content-between align-items-center">
                <h5 className="mb-0">{`${submission.firstName} ${submission.lastName}`}</h5>
                <button className="btn btn-secondary" onClick={onBack}>
                    <FontAwesomeIcon icon="fa-arrow-left" className="mr-2" />
                    Back to Charts
                </button>
            </div>
            <div className="card-body">
                {/* Characteristic as a Title */}
           
                
                {/* Description emphasized */}
                <div className="mb-5">
                    <h6 className="text-uppercase text-primary">{submission.characteristic}</h6>
                    <p className="lead" style={{ fontSize: '1.2rem'}}>
                        {submission.description}
                    </p>
                </div>

                {/* Other submission details in two columns */}
                <div className="row">
                    <div className="col-md-6">
                        <p><strong>Area:</strong> {submission.areaName}</p>
                        <p><strong>Understanding:</strong> {submission.understanding}/100</p>
                        <p><strong>Confidence:</strong> {submission.confidence}/5</p>
                        <p><strong>Location:</strong> {submission.location}</p>
                        <p><strong>Affects:</strong> {submission.affects}</p>
                
                    </div>
                    <div className="col-md-6">
                        <p><strong>Subject:</strong> {submission.subject}</p>
                        <p><strong>Supported By:</strong> {submission.supportedBy}</p>
                        <p><strong>Role:</strong> {submission.role}</p>
                        <p><strong>Duration of Impact:</strong> {submission.durationOfImpact}</p>
                        <p><strong>Other:</strong> {submission.other}</p>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default IndividualSubmissionDetails;
