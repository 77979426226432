import React from "react";
import ImprovedGrid from "./grid/improvedGrid";
import StudentDetails from "./studentDetails";
import { CSVLink } from 'react-csv';

class AllStudents extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            userSubmissions: [],
            selectedStudent: null
        };
    }

    componentDidMount() {
        this.modifySubmissions();
    }

    componentDidUpdate(prevProps) {
        if (prevProps.extendedSubmissions !== this.props.extendedSubmissions) {
            this.modifySubmissions();
        }
    }

    modifySubmissions = () => {
        const { extendedSubmissions } = this.props;

        if (!extendedSubmissions || extendedSubmissions.length === 0) {
            return; // Prevent modifying submissions if data isn't loaded yet
        }

        const userSubmissionsMap = {};

        extendedSubmissions.forEach(submission => {
            const { uid, firstName, lastName, class: className, yearGroup,email } = submission;

            if (!userSubmissionsMap[uid]) {
                userSubmissionsMap[uid] = {
                    uid,
                    firstName,
                    lastName,
                    email,
                    class: className,
                    badge: 0,
                    yearGroup,
                    characteristicsCompleted: {}
                };
            }

            if (submission.characteristic) {
                userSubmissionsMap[uid].characteristicsCompleted[submission.characteristic] = {
                    completed: true,
                    pledge: submission.pledge || '',
                    description: submission.description || ''
                };

                userSubmissionsMap[uid].badge += 1;
            }
        });

        const userSubmissions = Object.values(userSubmissionsMap);
        this.setState({ userSubmissions });
    }

    handleStudentSelect = (student) => {
        this.setState({ selectedStudent: student });
    }

    handleBackToList = () => {
        this.setState({ selectedStudent: null });
    }

    render() {
        const { userSubmissions, selectedStudent } = this.state;

        if (selectedStudent) {
            return (
                <StudentDetails
                student={selectedStudent}
                extendedSubmissions={this.props.extendedSubmissions}
                onBack={this.handleBackToList}
                areasData={this.props.areasData}  
                schoolData={this.props.schoolData}
            />
            );
        }

        const columns = [
            { key: 'firstName', label: 'First Name' },
            { key: 'lastName', label: 'Last Name' },
            { key: 'yearGroup', label: 'Year' },
            { key: 'class', label: 'Class' },
            { key: 'badge', label: 'Badge Count' }
        ];

        return (
            <div className="container">
                <h1 className="my-3">All Students</h1>
           

                <div className="d-flex justify-content-end mb-3">
                    <CSVLink
                        data={userSubmissions}
                        headers={columns.map(col => ({ label: col.label, key: col.key }))}
                        filename={"BeWorldClass_AllStudents.csv"}
                        className="btn btn-outline-primary"
                    >
                        Download CSV
                    </CSVLink>
                </div>

                <ImprovedGrid
                    extendedSubmissions={userSubmissions}
                    columns={columns}
                    onRowClick={this.handleStudentSelect}
                />
            </div>
        );
    }
}

export default AllStudents;
