import { getStorage, ref, uploadBytes, getDownloadURL } from 'firebase/storage';

// Assuming `firebaseApp` is the initialized Firebase app instance
// You should import this from where it's being initialized
import app from '../firebase.js';

const storage = getStorage(app);


export const uploadFile = async (file) => {
  const fileRef = ref(storage, `htmlFiles/${file.name}`);

  try {
    await uploadBytes(fileRef, file);
    const downloadURL = await getDownloadURL(fileRef);
    return downloadURL;
  } catch (error) {
    console.error('Error uploading file:', error);
    throw error;
  }
};

export const getFileContent = async (fileId) => {
  const fileRef = ref(storage, `htmlFiles/${fileId}`);
  try {
    const url = await getDownloadURL(fileRef);
    return await url;
  } catch (error) {
    console.error('Error fetching file content:', error);
    throw error;
  }
};
