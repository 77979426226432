import React from "react";

import * as XLSX from "xlsx";

class SpreadsheetImport extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            data: [],
            changedData: [],
            noEmail: 0,
        };

        this.handleFileUpload = this.handleFileUpload.bind(this);
    }

    handleFileUpload(event) {
        const file = event.target.files[0];
        const reader = new FileReader();
        reader.onload = (event) => {
            const csvData = event.target.result;
            const workbook = XLSX.read(csvData, { type: "string" });
            const worksheet = workbook.Sheets[workbook.SheetNames[0]];
            const importedData = XLSX.utils.sheet_to_json(worksheet, { header: 1 });

            const headers = importedData[0];
            const rows = importedData.slice(1);
            const transformedData = rows.map((row) => {
                return headers.reduce((obj, header, index) => {
                    obj[header] = row[index];
                    return obj;
                }, {});
            });

            const filteredData = transformedData.filter((item) => item["email"])

            


            this.setState({ data: filteredData, noEmail: transformedData.length - filteredData.length });
        };
        reader.readAsText(file);
    }

    render() {
        const { data } = this.state;

        return (
            <div style={{ textAlign: "center" }}>
                <div className="my-modal">
                    <div className="my-modal-content">
                        <div className="my-modal-header">
                            <span className="close" onClick={(e) => this.props.closeAction()} >&times;</span>
                            <h1>spreadsheetImport</h1>
                        </div>
                        <div className="my-modal-body form-group">
                            <input type="file" accept=".csv" onChange={this.handleFileUpload} />
                            {data.length > 0 && (
                                <table>
                                </table>
                            )}

                            

                            <pre> {JSON.stringify(data)} </pre>
                        </div>
                    </div>
                </div>



            </div >

        );
    }
}

export default SpreadsheetImport;


