import React from "react";
import { getFunctions, httpsCallable } from "firebase/functions";
import { getAuth, sendPasswordResetEmail } from "firebase/auth";
import Grid from "./grid/grid";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faMailForward } from "@fortawesome/free-solid-svg-icons";

class ManageStaff extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            staff: null,
            email: "",
            firstName: "",
            lastName: "",
            password: "",
            message: "",
            showAddForm: false,
            showInviteForm: false,
        };
    }

    componentDidMount() {
        this.getStaff(this.props.schoolData.path);
    }

    getStaff = (schoolPath) => {
        console.log("call functuio");
        const functions = getFunctions();
        const getSchoolStaff = httpsCallable(functions, 'getSchoolStaff');
        getSchoolStaff({ school: schoolPath })
            .then((result) => {
                const users = result.data.authData.users;
                let staffValues = result.data.values;
                let staffValuesArray = [];

                for (let i = 0; i < users.length; i++) {
                    staffValues[users[i].uid].email = users[i].email;
                    staffValues[users[i].uid].uid = users[i].uid;
                    staffValues[users[i].uid].key = users[i].uid;
                }

                for (const [key, value] of Object.entries(staffValues)) {
                    staffValuesArray.push(value);
                }

                this.setState({ staff: staffValuesArray });
                console.log("firebase function response", result);
            });
    };

    addUser = (e) => {
        e.preventDefault();

        const functions = getFunctions();
        const createSchoolStaff = httpsCallable(functions, 'createSchoolStaff');

        createSchoolStaff({
            school: this.props.schoolData.path,
            email: this.state.email,
            firstName: this.state.firstName,
            lastName: this.state.lastName,
            password: this.state.password
        }).then((result) => {
            if (result.data.error) {
                this.setState({ message: "Error adding user: " + result.data.error });
            } else {
                this.setState({
                    message: "User added successfully",
                    email: "",
                    firstName: "",
                    lastName: "",
                    password: "",
                });
                this.getStaff(this.props.schoolData.path);
            }
        });
    };

    inviteUser = (e) => {
        e.preventDefault();

        const functions = getFunctions();
        const inviteSchoolStaff = httpsCallable(functions, 'inviteSchoolStaff');

        const emailToInvite = this.state.email; // Save the email locally

        inviteSchoolStaff({
            school: this.props.schoolData.path,
            email: emailToInvite,
            firstName: this.state.firstName,
            lastName: this.state.lastName,
        }).then((result) => {
            if (result.data.error) {
                this.setState({ message: "Error adding user: " + result.data.error });
            } else {
                this.setState({
                    message: "User added successfully. Sending password reset email...",
                    email: "",
                    firstName: "",
                    lastName: "",
                    password: "",
                });
                this.getStaff(this.props.schoolData.path);
                this.sendPasswordReset(emailToInvite); // Pass the saved email to the reset method
            }
        });
    };

    sendPasswordReset = (email) => {
        const auth = getAuth();
        sendPasswordResetEmail(auth, email)
            .then(() => {
                // Password reset email sent!
                this.setState({ message: "User added successfully. Password reset email sent!" });
            })
            .catch((error) => {
                const errorCode = error.code;
                const errorMessage = error.message;
                this.setState({ message: "Error sending password reset email: " + errorMessage });
            });
    };


    toggleAddForm = () => {
        this.setState({ showAddForm: !this.state.showAddForm, showInviteForm: false });
    };

    toggleInviteForm = () => {
        this.setState({ showInviteForm: !this.state.showInviteForm, showAddForm: false });
    };

    handleChange = (e) => {
        this.setState({ [e.target.name]: e.target.value });
    };

    render() {
        return (
            <div className="container">
                <h3>Manage Staff</h3>

                <button className="btn btn-outline-primary" onClick={this.toggleAddForm}>
                    <FontAwesomeIcon icon={faPlus} /> Add Staff
                </button>
                <button className="btn btn-outline-primary" onClick={this.toggleInviteForm}>
                    <FontAwesomeIcon icon={faMailForward} /> Email Invite Staff
                </button>
                {this.state.showAddForm && (
                    <form className="mt-3">
                        <div className="form-group">
                            <input name="firstName" type="text" placeholder="First Name" value={this.state.firstName} onChange={this.handleChange} className="form-control" required />
                        </div>
                        <div className="form-group">
                            <input name="lastName" type="text" placeholder="Last Name" value={this.state.lastName} onChange={this.handleChange} className="form-control" required />
                        </div>
                        <div className="form-group">
                            <input name="email" type="email" placeholder="Email" value={this.state.email} onChange={this.handleChange} className="form-control" required />
                        </div>
                        <div className="form-group">
                            <input name="password" type="password" placeholder="Password" value={this.state.password} onChange={this.handleChange} className="form-control" required />
                        </div>
                        <button className="btn btn-success" type="button" onClick={this.addUser}>Add User</button>
                    </form>
                )}

                {this.state.showInviteForm && (
                    <form className="mt-3">
                        <div className="form-group">
                            <input name="firstName" type="text" placeholder="First Name" value={this.state.firstName} onChange={this.handleChange} className="form-control" required />
                        </div>
                        <div className="form-group">
                            <input name="lastName" type="text" placeholder="Last Name" value={this.state.lastName} onChange={this.handleChange} className="form-control" required />
                        </div>
                        <div className="form-group">
                            <input name="email" type="email" placeholder="Email" value={this.state.email} onChange={this.handleChange} className="form-control" required />
                        </div>
                        <button className="btn btn-success" type="button" onClick={this.inviteUser}>Invite User</button>
                    </form>
                )}
                {this.state.message && <p className="text-danger">{this.state.message}</p>}
                <Grid
                    selectStudent={this.addUser.bind(this)}
                    columns={[
                        { label: "First Name", key: "firstName" },
                        { label: "Last Name", key: "lastName" },
                        { label: "Email Address", key: "email" },
                    ]}
                    values={this.state.staff}
                    startingFilters={[]}
                //insight={this.state.insight}
                />
            </div>
        );
    }
}

export default ManageStaff;
