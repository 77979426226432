import React, { useState } from "react";
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCog } from '@fortawesome/free-solid-svg-icons';
import Dropdown from 'react-bootstrap/Dropdown';
import DropdownButton from 'react-bootstrap/DropdownButton';
import ChangePasswordForm from "../changePasswordForm";
import ChangeEmailForm from "../changeEmailForm";
import ChangeDetailsForm from "../changeDetailsForm";
import { signOutUser } from "../signOutUser";

//import { useFirebase } from 'your-firebase-package'; // import the Firebase hooks you're using

const TeacherNavBar = (props) => {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [passwordChangeOpen, setPasswordChangeOpen] = useState(false);
  const [emailChangeOpen, setEmailChangeOpen] = useState(false);
  const [detailsChangeOpen, setDetailsChangeOpen] = useState(false);

  const handleLogout = () => {
    signOutUser()
  };

  const openChangePassword = () => {
    setPasswordChangeOpen(true); // Open the password change form
  };

  const openChangeEmail = () => {
    setEmailChangeOpen(true); // Open the password change form
  };

  const openChangeDetails = () => {
    setDetailsChangeOpen(true); // Open the password change form
  };

  const closePasswordChange = () => {
    setPasswordChangeOpen(false); // Close the password change form
  };

  const closeEmailChange = () => {
    setEmailChangeOpen(false); // Close the password change form
  };

  const closeDetailsChange = () => {
    setDetailsChangeOpen(false); // Close the password change form
  };

  const schoolPath = props.schoolData ? props.schoolData.path : "";

  return (
    <nav className="navbar navbar-dark bg-dark no-print" style={{ marginBottom: "20px" }}>
      <Link className="navbar-brand" to={"/"}>
        <img style={{ marginLeft: "10px" }} src="https://i0.wp.com/beworldclass.org/wp-content/uploads/2022/06/cropped-BeWorldClass-transparent-rgb.png" height="50px" alt="" />
        

      </Link>

      <div className="navbar-brand">
        {props.schoolData?props.schoolData.name:"no school data" }
      </div>

      {props.user && (

        <div className="dropdown">
          <DropdownButton variant="secondary" style={{ marginLeft: "-40px", }} id="dropdown-basic-button" title={
            <FontAwesomeIcon icon={faCog} /> && props.user
              ? <> <span className="nav-name"> {props.user.firstName} {props.user.lastName} </span><FontAwesomeIcon icon={faCog} /> </> :
              ""}>
            <Dropdown.Item onClick={openChangeDetails}>Change Details</Dropdown.Item>
            <Dropdown.Item onClick={openChangePassword}>Change Password</Dropdown.Item>
            <Dropdown.Item onClick={openChangeEmail}>Change Email Address</Dropdown.Item>
            <Dropdown.Item onClick={handleLogout}>Log out</Dropdown.Item>
          </DropdownButton>
        </div>
      )}

      {passwordChangeOpen && <ChangePasswordForm closePasswordChange={closePasswordChange} />}
      {emailChangeOpen && <ChangeEmailForm closeEmailChange={closeEmailChange} />}
      {detailsChangeOpen && <ChangeDetailsForm user={props.user} schoolData={props.schoolData} closeDetailsChange={closeDetailsChange} />}
    </nav>
  );
};

export default TeacherNavBar;
