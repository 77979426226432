
import { initializeApp } from 'firebase/app';
import { getAuth, createUserWithEmailAndPassword, onAuthStateChanged } from "firebase/auth";

/*
old details
const app = initializeApp({
    apiKey: "AIzaSyBF60YRFmG2xelPYQ5dbzNvNOaOaZWrWfs",
    authDomain: "localhost",
    databaseURL: "https://beworldclass-801f5-default-rtdb.europe-west1.firebasedatabase.app/",
    projectId: "beworldclass-801f5",
    storageBucket: "beworldclass-801f5.appspot.com",
    messagingSenderId: "759526089026"
 
})*/

//Producation details
const app = initializeApp({
    apiKey: "AIzaSyDskWEmnqfe1sCU-vR-qqJwl1aLt7OqT7M",
    authDomain: "localhost",
    databaseURL: "https://beworldclass-production-default-rtdb.europe-west1.firebasedatabase.app/",
    projectId: "beworldclass-production",
    storageBucket: "gs://beworldclass-production.appspot.com",
    messagingSenderId: "69483760652"
 
})




export const auth = getAuth(app);
export default app 