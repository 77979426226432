import React, { useState } from 'react';
import { uploadFile } from './firebasepageshareservice.js';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUpload, faCloudUploadAlt, faLink, faCheckCircle, faExclamationCircle } from '@fortawesome/free-solid-svg-icons';

export default function UploadPage() {
  const [file, setFile] = useState(null);
  const [uploadStatus, setUploadStatus] = useState('');
  const [fileLink, setFileLink] = useState('');

  const handleFileChange = (e) => {
    setFile(e.target.files[0]);
  };

  const handleUpload = async () => {
    if (!file) {
      setUploadStatus('Please select a file');
      return;
    }

    try {
      const fileUrl = await uploadFile(file);
      setFileLink(fileUrl);
      setUploadStatus('File uploaded successfully');
    } catch (error) {
      setUploadStatus(`Upload failed: ${error.message}`);
    }
  };

  return (
    <div className="container mt-5">
      <div className="card shadow-sm">
        <div className="card-header text-center bg-primary text-white">
          <h1><FontAwesomeIcon icon={faUpload} /> Upload HTML File</h1>
        </div>
        <div className="card-body">
          <div className="form-group">
            <label htmlFor="fileInput" className="form-label">Select a HTML file to upload:</label>
            <input 
              type="file" 
              id="fileInput" 
              accept=".html" 
              onChange={handleFileChange} 
              className="form-control" 
            />
          </div>
          <button 
            onClick={handleUpload} 
            className="btn btn-primary mt-3 w-100"
          >
            <FontAwesomeIcon icon={faCloudUploadAlt} /> Publish
          </button>
          {uploadStatus && (
            <div className={`alert mt-3 ${uploadStatus.includes('successfully') ? 'alert-success' : 'alert-danger'}`} role="alert">
              <FontAwesomeIcon icon={uploadStatus.includes('successfully') ? faCheckCircle : faExclamationCircle} /> {uploadStatus}
            </div>
          )}
          {fileLink && (
            <div className="mt-3">
              <p>View webpage: <a href={fileLink} target="_blank" rel="noopener noreferrer" className="btn btn-link"><FontAwesomeIcon icon={faLink} /> {fileLink}</a></p>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
