import { ref, set, getDatabase } from 'firebase/database';
import app from '../../firebase';
import React, { Component } from 'react';

class ViewImprovement extends Component {
    constructor(props) {
        super(props);
        this.state = {
            evidence: ''
        };
    }


    render() {
        return (
            <div className="my-modal">
                <div className="my-modal-content">
                    <div className="my-modal-header">
                        <h2>View Improvement</h2>
                    </div>
                    <div className="my-modal-body">
                        <br />
                        <h4>Characteristic: I {this.props.improvementData?.characteristic} </h4>

                        <h6>Task: {this.props.improvementData?.text} </h6>

                        <p> {this.props.improvementData?.evidence} </p>
                        <button className="btn btn-secondary mt-3 ml-2" onClick={this.props.onCompletion}>Cancel</button>
                    </div>
                </div>
            </div>
        );
    }
}

export default ViewImprovement;
