const defaultColumns = [
    { label: "First Name", key: "firstName" },
    { label: "Last Name", key: "lastName", },
    { label: "Year Group", key: "yearGroup", },
    { label: "Gender", key: "gender", type: "select"},
    { label: "Class/Tutor Group", key: "class", },
    { label: "Area", key: "area" ,type: "select"},
    { label: "Progress Estimate", key: "progressEstimate"}
]

const submissionTabsConfig = [
    {
        name:"Review",
        key:"review",
        columns:defaultColumns,
        filters:[{
            key:"mark",
            value:"In Review"
        }
        ]
    },
    {
        name:"Complete",
        key:"complete",
        columns:defaultColumns,
        filters:[{
            key:"mark",
            value:"Complete"
        }
        ]
    },
    {
        name:"Incomplete",
        key:"incomplete",
        columns:defaultColumns,
        filters:[{
            key:"mark",
            value:"Incomplete"
        }
        ]
    },
    {
        name:"Favorites",
        key:"favorites",
        columns:defaultColumns,
        filters:[{
            key:"mark",
            value:"hhhj"
        }
        ]
    },
    {
        name:"All Submissions",
        key:"allSubmissions",
        columns:[
            { label: "First Name", key: "firstName" },
            { label: "Last Name", key: "lastName", },
            { label: "Year Group", key: "yearGroup", },
            { label: "Gender", key: "gender", type: "select"},
            { label: "Class/Tutor Group", key: "class", },
            { label: "Area", key: "area", type: "select" },
            { label:"Mark", key: "mark", type: "select"},
            { label: "Pupil premium", key: "pupilPremium", type:"boolean", optional:true, type: "select"},
            { label: "Progress Estimate", key: "progressEstimate"}
        ],
        filters:[
        ]
    },
    {
        name:"All Students",
        key:"allStudents",
        columns:[
            { label: "First Name", key: "firstName" },
            { label: "Last Name", key: "lastName", },
            { label: "Email", key: "email", },
            { label: "Year Group", key: "yearGroup", },
            { label: "Gender", key: "gender", type: "select"},
            { label: "Class/Tutor Group", key: "class", },
            { label: "SEN", key: "sen", type:"boolean", optional:true },
            { label: "Pupil premium", key: "pupilPremium", type:"boolean", optional:true, type: "select"},

        ],
        filters:[]
    },
    {
        name:"Insight",
        key:"insight",
        columns:[
            { label: "First Name", key: "firstName" },
            { label: "Last Name", key: "lastName", },
            { label: "Year Group", key: "yearGroup", },
            { label: "Gender", key: "gender", type: "select"},
            { label: "Class/Tutor Group", key: "class", },
            { label: "SEN", key: "sen", type:"boolean", optional:true },
            { label: "Pupil premium", key: "pupilPremium", type:"boolean", optional:true, type: "select"},

        ],
        filters:[]
    }
]

export default submissionTabsConfig
