import React, { useState } from 'react';
import { getDatabase, ref, set } from "firebase/database";
import app from '../../firebase';
import AreaSetup from '../areaSetup';

const CreateCustomAreas = (props) => {
    // State to manage the input value for the new group
    const [selectedArea, setSelectedArea] = useState(null);
    // State to manage the success or error messages

    const createArea = () => {

        function makeId(length) {
            var result = '';
            var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
            var charactersLength = characters.length;
            for (var i = 0; i < length; i++) {
                result += characters.charAt(Math.floor(Math.random() * charactersLength));
            }
            return result;
        }



        setSelectedArea(makeId(7))

    }


    const handleSelectArea = (key) => {

        setSelectedArea(key);

    };



    return (
        <div className="container">
            <h3>Create Custom Areas</h3>
            <p>Create your own areas of skills, characteristics or accomplishments that match your schools values.</p>

            {!selectedArea &&
                <>
                    <button className="btn btn-primary" onClick={() => createArea()}>Create Area</button>

                    <div className="mt-3">
                        <h4>Your Areas</h4>
                        <ul className="list-group">

                            {((props.schoolData.areaConfig && Object.keys(props.schoolData.areaConfig)) || []).map((key, index) => (
                                <li key={index} className="list-group-item d-flex justify-content-between align-items-center">
                                    {props.schoolData.areaConfig[key].name}
                                    <button className="btn btn-danger btn-sm" onClick={() => handleSelectArea(key)}>Edit</button>
                                </li>
                            ))}
                        </ul>
                    </div>
                </>
            }
            {selectedArea &&
            <>
            <button className="btn btn-secondary" onClick={()=> setSelectedArea(null)} >Back</button>
                <AreaSetup selectedArea={selectedArea} schoolData={props.schoolData} />
            </>
            }
        </div>
    );
};

export default CreateCustomAreas;
