import React from "react";
import { useParams } from "react-router-dom";
import StudentChat from "./studentChat";
import ReplicateChat from "./replicateChat";
function ChatLink({llama}) {
  // Using useParams hook to access the route parameters
  let { schoolPath, assistantId } = useParams();

  console.log("chat link")
  console.log("llama = ", llama)

  return (
    <>
      {!llama &&
        <div>
          <StudentChat schoolPath={schoolPath} assistantId={assistantId} />
        </div>
      }
      {llama &&
        <div>
          <ReplicateChat schoolPath={schoolPath} assistantId={assistantId} />
        </div>
      }
    </>
  );
}

export default ChatLink;