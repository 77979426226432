import React from 'react';
import './badge.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { library } from '@fortawesome/fontawesome-svg-core';
import { fas } from '@fortawesome/free-solid-svg-icons';

library.add(fas);

const Badge = ({ iconName, color, special }) => {
  const backgroundStyle = {
    backgroundColor: special ? 'transparent' : color,
    borderColor: special ? color : 'transparent',
  };

  const iconStyle = {
    color: 'white',
  };

  return (
      <div className={`badge-wrapper`} style={backgroundStyle}>
        <FontAwesomeIcon icon={iconName} size="2xl" className="icon" style={iconStyle} />
        {special && <FontAwesomeIcon icon="star" color={color} className="star" />}
      </div>
  );
};

export default Badge;
