import React from "react";
import areasConfig from "../areasConfig";
import { Formik, Form, Field } from "formik";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheck, faXmark } from '@fortawesome/free-solid-svg-icons'
import app from "../firebase";
import { getDatabase, set, ref, onValue } from "firebase/database";
import CharacteristicSetup from "./characteristicSetup";
import UploadAreaIcon from "./uploadAreaIcon";


class AreaSetup extends React.Component {
    constructor(props) {
        super(props);
    }
    state = {
        areaData: {
            name: "",
            motto: "",
            sortOrder:0,
            characteristics: []
        },
        values: {},
        understands: {},
        improvements: {},
        colors: {},
        imageUrl: "",
        loaded: false,
        unsavedChanges: false
    }

    componentDidMount() {

        const existingArea = this.props.schoolData.areaConfig ?
            Object.keys(this.props.schoolData.areaConfig).includes(this.props.selectedArea):
            null

        
     


        if (existingArea && this.props.schoolData?.areaConfig[this.props.selectedArea] && this.props.schoolData?.areaConfig[this.props.selectedArea].name  ) {


            const unpackedData = this.unpackData(this.props.schoolData.areaConfig[this.props.selectedArea])

            const understandKeys = Object.keys(unpackedData).filter(key => key.includes('understand'));
            const improveKeys = Object.keys(unpackedData).filter(key => key.includes('improve'));
            const colorKeys = Object.keys(unpackedData).filter(key => key.includes('color'));

            const improveObj = {};
            const understandObj = {}
            const colorObj = {}

            understandKeys.forEach(key => {
                understandObj[key] = unpackedData[key];
            });

            improveKeys.forEach(key => {
                improveObj[key] = unpackedData[key];
            });

            colorKeys.forEach(key => {
                colorObj[key] = unpackedData[key];
            });

            this.setState({ areaData: this.props.schoolData.areaConfig[this.props.selectedArea], loaded: true, values: unpackedData, improvements: improveObj, understands: understandObj, colors: colorObj, unsavedChanges: false })

        } else {
            this.setState({ loaded: true, unsavedChanges: false })
        }
        console.log("newData")
        this.setState({ unsavedChanges: false })

    }



    unpackData = (areaData) => {

        let values = {}
        values.name = areaData.name
        values.motto = areaData.motto
        values.sortOrder = areaData.sortOrder
        for (let i = 0; i < areaData.characteristics.length; i++) {
            const characteristic = areaData.characteristics[i]
            values["name" + characteristic.id.toString()] = characteristic.name
            values["index" + characteristic.id.toString()] = characteristic.index
            values["understand" + characteristic.id.toString()] = characteristic.understand
            values["improvements" + characteristic.id.toString()] = characteristic.improvements
            values["color" + characteristic.id.toString()] = characteristic.color
            values["aiMarkScheme" + characteristic.id.toString()] = characteristic.aiMarkScheme
            values["aiMainFocus" + characteristic.id.toString()] = characteristic.aiMainFocus
            values["icon" + characteristic.id.toString()] = characteristic.icon
            areaData.characteristics[i] = characteristic
        }
        return (values)

    }

    addCharacteristic = () => {

        let characteristics = this.state.areaData.characteristics

        let thisCharacteristic = {}
        thisCharacteristic.id = Math.round(Math.random() * 100000000000)
        thisCharacteristic.index = characteristics.length + 1
        thisCharacteristic.name = ""
        thisCharacteristic.aiMarkScheme = ""
        thisCharacteristic.aiMainFocus = ""
        thisCharacteristic.icon = ""
        thisCharacteristic.improvements = []
        thisCharacteristic.understand = null


        characteristics.push(thisCharacteristic)
        console.log(characteristics)
        let areaData = this.state.areaData

        areaData.characteristics = characteristics
        console.log(areaData)


        this.setState({
            areaData: areaData, values: this.unpackData(areaData)
        })
    }

    deleteCharacteristic = (id) => {

        console.log("delete", id)
        let characteristics = this.state.areaData.characteristics
        characteristics = characteristics.filter(characteristic => characteristic.id !== id);
        let areaData = this.state.areaData
        areaData.characteristics = characteristics
        this.setState({ areaData: areaData })

    }

    setAreaDataCorrectly = () => {
        let values = this.state.values

        let areaData = {
            name: values.name,
            motto: values.motto,
            sortOrder: values.sortOrder,
            path: this.props.selectedArea,
            characteristics: this.state.areaData.characteristics.sort((a, b) => a.index - b.index)
        }

        for (let i = 0; i < areaData.characteristics.length; i++) {
            let characteristic = areaData.characteristics[i]

            characteristic.understand = this.state.understands["understand" + characteristic.id.toString()]
            characteristic.name = values["name" + characteristic.id.toString()]
            characteristic.index = values["index" + characteristic.id.toString()]
            characteristic.aiMarkScheme = values["aiMarkScheme" + characteristic.id.toString()]
            characteristic.aiMainFocus = values["aiMainFocus" + characteristic.id.toString()]
            characteristic.icon = values["icon" + characteristic.id.toString()]
            characteristic.improvements = this.state.improvements["improvements" + characteristic.id.toString()]
            characteristic.color = this.state.colors["color" + characteristic.id.toString()]
            areaData.characteristics[i] = characteristic

        }
        this.setState({ areaData: areaData })
    }

    handleEditorChange = (content, id) => {

        let understands = this.state.understands
        understands["understand" + id.toString()] = content

        this.setState({ understands: understands, unsavedChanges: true }, () => {
            //this.setAreaDataCorrectly()
        });

    };

    handleColorChange = (color, id) => {

        let colors = this.state.colors
        colors["color" + id.toString()] = color

        this.setState({ colors: colors, unsavedChanges: true }, () => {
            this.setAreaDataCorrectly()
        });
    };

    handleImrovementsAdd = (content, id) => {
        let improvementsState = this.state.improvements
        let improvements = this.state.improvements["improvements" + id.toString()] ? this.state.improvements["improvements" + id.toString()] : []
        const newImprovemtnsArray = [...improvements, content];
        improvementsState["improvements" + id.toString()] = newImprovemtnsArray

        this.setState({ improvements: improvementsState, unsavedChanges: true }, () => {
            this.setAreaDataCorrectly()
        });
    };

    handleImrovementsDelete = (index, id) => {
        console.log("parent function");
        const improvementsState = this.state.improvements;
        const improvements = this.state.improvements["improvements" + id.toString()];
        improvements.splice(index, 1);
        improvementsState["improvements" + id.toString()] = improvements;

        this.setState({ improvements: improvementsState, unsavedChanges: true }, () => {
            this.setAreaDataCorrectly()
        });
    }

    handleImageUrl = (url) => {
        this.setState({ imageUrl: url })
    }


    submitToFirebase = () => {




        function removeUndefined(obj) {
            return Object.fromEntries(Object.entries(obj).filter(([_, value]) => value !== undefined));
        }

        let areaData = this.state.areaData
        areaData.imageUrl = this.state.imageUrl
        areaData.stage = "custom"


        for (let i = 0; i < areaData.characteristics.length; i++) {

            areaData.characteristics[i].understand = this.state.understands["understand" + areaData.characteristics[i].id.toString()]
            areaData.characteristics[i].color = this.state.colors["color" + areaData.characteristics[i].id.toString()]
            areaData.characteristics[i] = removeUndefined(areaData.characteristics[i])
        }

        console.log("sending to firebase")
        const database = getDatabase(app)
        const submissionPath = ref(database, 'schools/' + this.props.schoolData.path + '/public/areaConfig/' + this.props.selectedArea);

        set(submissionPath, areaData)
            .then(() => {
                this.setState({ unsavedChanges: false });
            })
            .catch((error) => {
                console.error('Error writing to Firebase: ', error);
            });

    }


    render() {


        let characteristicsData = this.state.areaData.characteristics;
        let characteristics = []
        if (this.state.loaded === true) {
            for (let i = 0; characteristicsData.length > i; i++) {

                characteristics.push(<CharacteristicSetup
                    deleteCharacteristic={this.deleteCharacteristic}
                    characteristicData={characteristicsData[i]}
                    index={i}
                    onEditorChange={this.handleEditorChange}
                    improvementsAdd={this.handleImrovementsAdd}
                    improvementsDelete={this.handleImrovementsDelete}
                    improvements={characteristicsData[i].improvements}
                    handleColorChange={this.handleColorChange}
                    unsavedChanges={this.state.unsavedChanges}
                />)

            }
        }

        return (
            <div > {this.state.loaded === true &&
                <Formik
                    initialValues={this.state.values}
                    validate={values => {

                        this.setState({ values: values, valuesChanged: true, unsavedChanges: true }, () => {
                            this.setAreaDataCorrectly()
                        });
                    }
                    }
                    validateOnChange={true}
                    onSubmit={async values => {
                        await new Promise(resolve => setTimeout(resolve, 500));

                        this.submitToFirebase(values);
                        console.log(values)

                    }}>
                    <Form >
                        {this.state.unsavedChanges && this.state.areaData.characteristics.length >0 &&
                            <div className="alert alert-warning" style={{ position: 'fixed', top: "2rem", left: "2rem", zIndex: 999 }}>
                                <h6>
                                    You have unsaved changes
                                    <button style={{ marginLeft: "1rem" }} className="btn btn-outline-secondary" onClick={() => { this.submitToFirebase() }}>Save</button>
                                </h6>
                            </div>
                        }
                        <div style={{ backgroundColor: "white", marginBottom: "25px", padding: "2rem" }}>
                            <div style={{ textAlign: "right", marginBottom: "1rem" }}>
                                {/* <button onClick={() => { this.submitToFirebase() }} style={{ marginRight: "2rem" }} ref={this.saveButton} className="btn btn-primary" type="button">
                                    Save
                                </button> */}
                            </div>

                            <div className="form-group">
                                <label>Area Name</label>
                                <Field id={"name"} name={"name"} className="form-control" placeholder="Our school principals" />
                            </div>

                            <div className="form-group">
                                <label>Motto</label>
                                <Field id={"motto"} name={"motto"} className="form-control" placeholder="We are dedicated to our 8 school principals, making us great, because we..." />
                            </div>
                            <div className="form-group">
                                <label>Area Sort Order (use this to order the areas if you have more than one)</label>
                                <Field id={"sortOrder"} name={"sortOrder"} className="form-control" type="number" />
                            </div>

                            <div>
                                <label>Icon/logo*</label>
                                <UploadAreaIcon handleImageUrl={this.handleImageUrl} selectedArea={this.props.selectedArea} schoolData={this.props.schoolData} />
                            </div>
                        </div>
                        {characteristics}
                        <button style={{ marginRight: "2rem" }} onClick={() => { this.addCharacteristic() }} className="btn btn-primary" type="button">
                            + Add characteristic
                        </button>

                        <pre>{JSON.stringify(this.state)}</pre>
                    </Form>
                </Formik>

            }
            </div >);
    }

}

export default AreaSetup;