import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faRobot, faInfoCircle, faQuestionCircle } from '@fortawesome/free-solid-svg-icons';
import { getDatabase, ref, update } from "firebase/database";
import app from '../../firebase';
import { ProgressBar } from 'react-bootstrap';

const AIOptions = (props) => {
    // State to manage the input values, initialized with values from props
    const [monthlyLimit, setMonthlyLimit] = useState(props.schoolData.monthlyLimit || '');
    const [weeklyLimit, setWeeklyLimit] = useState(props.schoolData.weeklyLimit || '');
    const [showLimitExplanation, setShowLimitExplanation] = useState(false);


    // useEffect to update state when props.schoolData changes
    useEffect(() => {
        setMonthlyLimit(props.schoolData.monthlyLimit || '');
        setWeeklyLimit(props.schoolData.weeklyLimit || '');
    }, [props.schoolData]);

    // Handler for the form submission
    const handleSubmit = (e) => {
        e.preventDefault();

        // Access the database
        const db = getDatabase(app);
        const schoolId = props.schoolData.path;
        const aiOptionsRef = ref(db, `schools/${schoolId}/public`);

        // Update the values in the database
        update(aiOptionsRef, {
            monthlyLimit: monthlyLimit,
            weeklyLimit: weeklyLimit
        }).then(() => {
            alert("Settings saved successfully!");
        }).catch((error) => {
            console.error("Error saving settings: ", error);
        });
    };

    const timeSaved = props.schoolData?.AiUses ? (props.schoolData.AiUses * 6) / 60 : 0;
    const remainingUses = 100000 - props.schoolData?.AiUses;
    const progress = (remainingUses / 100000) * 100;

    return (
        <div className="container">
            <h3><FontAwesomeIcon icon={faRobot} /> AI Options</h3>
            <h5>You have saved an estimated {timeSaved} hours of teacher time trough GRACE giving feedback. </h5>

            <h2>The limit on GRACE AI usage has been removed</h2>

            <br />

      
        </div>
    );
};

export default AIOptions;
