import React from "react";

export function checkboxTableCell(rowSelected) {
    return <div className="custom-control custom-checkbox">
        {rowSelected &&
            <input className="custom-control-input" type="checkbox" defaultChecked={true}></input>}
        {!rowSelected &&
            <input className="custom-control-input" type="checkbox" defaultChecked={false}></input>}
    </div>;
}
