import React, { Component } from 'react';
import { getDatabase, ref, onValue } from 'firebase/database';
import app from '../../../firebase';
import Badge from '../../badge';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrophy } from '@fortawesome/free-solid-svg-icons';
import ViewSingleCharacteristic from '../../../viewSingleCharacteristic';

class Achievements extends Component {
    constructor(props) {
        super(props);
        this.state = {
            databaseValues: [],
            totalScore: 0,
            topAwards: [],
            viewCharacteristic: null
        };
    }

    componentDidMount() {
        const database = getDatabase(app);

        this.props.areaData.forEach(area => {
            const areaStatePath = ref(database, 'schools/' + this.props.schoolData.path + '/submissions/' + area.path + '/' + this.props.user.uid + '/grace');

            onValue(areaStatePath, (snapshot) => {
                const data = snapshot.val();
                let areaScore = 0;

                // Check if data is not null before processing
                if (data) {
                    for (let key in data) {
                        if (data[key].award) {
                            if (data[key].award >= 80) areaScore += 80;
                            else if (data[key].award >= 70) areaScore += 50;

                            // Update top awards
                            this.setState(prevState => {
                                let updatedTopAwards = prevState.topAwards;
                                if (updatedTopAwards.length < 4) {
                                    // Add to top awards directly if it does not contain 4 awards yet
                                    updatedTopAwards.push({ key: key, award: data[key].award, area: area.path });
                                } else {
                                    // Replace the smallest award in top awards if current award is larger
                                    let minAwardIndex = updatedTopAwards.reduce((minIndex, current, index, array) => current.award < array[minIndex].award ? index : minIndex, 0);
                                    if (data[key].award > updatedTopAwards[minAwardIndex].award) {
                                        updatedTopAwards[minAwardIndex] = { key: key, award: data[key].award, area: area.path };
                                    }
                                }
                                return { topAwards: updatedTopAwards };
                            });
                        }
                    }
                }

                this.setState(prevState => {
                    const existingAreaIndex = prevState.databaseValues.findIndex(dbValue => dbValue.area === area);
                    const updatedDatabaseValues = existingAreaIndex >= 0
                        ? [...prevState.databaseValues.slice(0, existingAreaIndex), { ...data, areaScore }, ...prevState.databaseValues.slice(existingAreaIndex + 1)]
                        : [...prevState.databaseValues, { ...data, areaScore }];

                    return {
                        databaseValues: updatedDatabaseValues,
                        totalScore: prevState.totalScore + areaScore
                    };
                });
            });
        });
    }

    closeCharacteristic = () => {
        this.setState({viewCharacteristic:null})
    }



    render() {
        const pointsPerLevel = 100;
        const level = 1 + Math.floor(this.state.totalScore / pointsPerLevel);
        const progress = this.state.totalScore % pointsPerLevel; // Points gained towards the next level
        const progressRemaining = pointsPerLevel - progress; // Points needed to reach the next level
        const progressPercentage = (progress / pointsPerLevel) * 100; // Percentage progress towards the next level


        const levelNames = [
            "Getting Started",
            "First Steps",
            "Novice Achiever",
            "Growing Potential",
            "Budding Talent",
            "Promise Unfolding",
            "Rising Star",
            "Pioneer Progress",
            "Disciplined Achiever",
            "Progressing Mastery",
            "Explorer of Skills",
            "Innovative Mind",
            "Continuing Ascent",
            "Mindful Master",
            "In Pursuit of Excellence",
            "Resilient Achiever",
            "Nurturing Potential",
            "Journey's Momentum",
            "Vanguard of Skill",
            "Emerging Scholar",
            "Persistent Pioneer",
            "Advancing Artisan",
            "Progressive Achiever",
            "Distinctive Dynamo",
            "Elite Achiever",
            "Oracle of Persistence",
            "Growing Prodigy",
            "Peak Performer",
            "World Class Achiever",
            "Professional Skills Master"
        ];
        const levelName = levelNames[level - 1];




        return (
            <div className="container mt-5">
                <div className="card bg-light mb-3">
                    <div className="card-header">
                        <h3><FontAwesomeIcon icon={faTrophy} /> Points: {this.state.totalScore} </h3>
                    </div>
                    <div className="card-body">
                        <div class="badge badge-warning">Warning</div>
                        <h5>Level: {level} - {levelName} </h5>
                        <h5 className="card-title"></h5>
                        <div className="progress mb-3">
                            <div className="progress-bar progress-bar-striped bg-warning" role="progressbar" style={{ width: `${progressPercentage}%` }} aria-valuenow={progress} aria-valuemin="0" aria-valuemax="100"></div>
                        </div>
                        <p> {progressRemaining} points until level {level + 1} </p>


                        <h5>Some of your top characteristics:</h5>
                        <div className='row'>
                            {this.state.topAwards.map((award, index) => {
                                // Find the area with a path value of award.area
                                const awardArea = this.props.areaData.find(area => area.path === award.area);
                                console.log("awardArea", awardArea, "award", award)
                                // If awardArea is not found, return null
                                if (!awardArea) return null;

                                // Find the item in awardArea where the id is equal to award.key
                                const awardItem = awardArea.characteristics.find(item => item.id == award.key);

                                // If awardItem is not found, return null
                                if (!awardItem) return null;

                                return (
                                    <div className='col'>
                                        {this.state.viewCharacteristic === awardArea.path + this.props.user.uid + String(awardItem.id) &&

                                            <ViewSingleCharacteristic closeCharacteristic={this.closeCharacteristic} uid={this.props.user.uid} schoolData={this.props.schoolData} characteristicData={{ area:awardArea.path,name: awardItem.name, id: awardItem.id }}/>
                                        }

                                        <div
                                            onClick={() => { this.setState({ viewCharacteristic: awardArea.path + this.props.user.uid + String(awardItem.id) }) }}
                                            style={{ width: '70px', height: '70px', margin: "auto" }} key={index}>
                                            <Badge
                                                iconName={awardItem.icon||"trophy"}
                                                color={awardArea.color||"yellow"}
                                                special={award.award >= 80}
                                            />
                                        </div>
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                </div>
            </div>
        );
    }

}

export default Achievements;
