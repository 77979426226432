import React, { useState, useEffect } from 'react';
import ReportGeneratorSection from './reportGeneratorSection';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faArrowUp, faArrowDown, faTrash } from '@fortawesome/free-solid-svg-icons';
import { getDatabase, ref, set, push, get } from "firebase/database";
import app from '../../firebase';

const ReportLayout = ({ schoolData, reportId, initialReportData }) => {
  const [sections, setSections] = useState([
    { id: 1, title: '', researchGoal: '', dataInstructions: '', structureInstructions: '', dependencies: [] }
  ]);
  const [isLoaded, setIsLoaded] = useState(false);
  const [reportName, setReportName] = useState('');
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const [sectionToDelete, setSectionToDelete] = useState(null);

  useEffect(() => {
    console.log("initialReportData",initialReportData)
    if (initialReportData && !isLoaded) {
      setSections(initialReportData.sections?.map(section => ({
        ...section,
        dependencies: section.dependencies || []
      })));
      setIsLoaded(true);
    }
  }, [initialReportData, isLoaded]);

  useEffect(() => {
    if (reportId && !isLoaded) {
      loadReport(reportId);
    }
  }, [reportId, isLoaded]);

  const loadReport = async (reportId) => {
    const db = getDatabase(app);
    const reportRef = ref(db, 'schools/' + schoolData.path + '/private/reportTemplates/' + reportId);
    const snapshot = await get(reportRef);
    if (snapshot.exists()) {
      const data = snapshot.val();
      console.log("report data", data)
      setReportName(data.name || '');
      setSections(data.sections.map(section => ({
        ...section,
        dependencies: section.dependencies || []
      })));
      setIsLoaded(true);
    }
  };

  const hasCircularDependency = (sections, sectionId, newDependencyId) => {
    const visited = new Set();

    const check = (currentId) => {
      if (visited.has(currentId)) return true;
      visited.add(currentId);

      const currentSection = sections.find(section => section.id === currentId);
      if (!currentSection || !Array.isArray(currentSection.dependencies)) return false;

      for (let dep of currentSection.dependencies) {
        if (dep === sectionId || check(dep)) return true;
      }
      return false;
    };

    return check(newDependencyId);
  };



  const addSection = (index) => {
    const newSection = { id: sections.length + 1, title: '', researchGoal: '', dataInstructions: '', structureInstructions: '', dependencies: [] };
    const newSections = [...sections];
    newSections.splice(index + 1, 0, newSection);
    setSections(newSections);
  };

  const updateSection = (index, updatedSection) => {
    const newSections = [...sections];
    newSections[index] = { ...updatedSection, dependencies: updatedSection.dependencies || [] };
    setSections(newSections);
  };

  const moveSectionUp = (index) => {
    if (index === 0) return;
    const newSections = [...sections];
    [newSections[index - 1], newSections[index]] = [newSections[index], newSections[index - 1]];
    setSections(newSections);
  };

  const moveSectionDown = (index) => {
    if (index === sections.length - 1) return;
    const newSections = [...sections];
    [newSections[index], newSections[index + 1]] = [newSections[index + 1], newSections[index]];
    setSections(newSections);
  };

  const handleDependencyChange = (sectionId, dependencies) => {
    const newSections = sections.map(section => {
      if (section.id === sectionId) {
        return { ...section, dependencies: dependencies || [] };
      }
      return section;
    });
    setSections(newSections);
  };

  const saveReport = async () => {
    const db = getDatabase(app);
    let reportKey = reportId;

    if (!reportKey) {
      reportKey = push(ref(db, 'schools/' + schoolData.path + '/private/reportTemplates')).key;
    }

    await set(ref(db, 'schools/' + schoolData.path + '/private/reportTemplates/' + reportKey), {
      name: reportName,
      sections
    });
  };

  const confirmDeleteSection = (index) => {
    setSectionToDelete(index);
    setShowDeleteConfirmation(true);
  };

  const deleteSection = () => {
    const newSections = sections.filter((_, index) => index !== sectionToDelete);
    setSections(newSections);
    setShowDeleteConfirmation(false);
    setSectionToDelete(null);
  };

  const cancelDelete = () => {
    setShowDeleteConfirmation(false);
    setSectionToDelete(null);
  };

  return (
    <div className="">
      <div className="row">
        <div className="col-md-3 bg-light border-end" style={{ minHeight: '100vh' }}>
          <div className="mb-4">
            <label htmlFor="reportName" className="form-label">Report Name</label>
            <input
              type="text"
              className="form-control"
              id="reportName"
              value={reportName}
              onChange={(e) => setReportName(e.target.value)}
            
            />
          </div>
          <div className="d-grid gap-2">
            <button className="btn btn-success btn-lg" onClick={saveReport}>Save Report</button>
          </div>
        </div>
        <div className="col-md-9 py-4">
          <h2 className="mb-4">Document Structure</h2>

          {sections.map((section, index) => (
            <div key={section.id} className="card mb-4">
              <div className="card-body">
                <div className="d-flex justify-content-between align-items-center mb-3">
                  <h5 className="card-title mb-0">Section {index + 1}</h5>
                  <div className="btn-group">
                    <button className="btn btn-outline-secondary btn-sm" onClick={() => moveSectionUp(index)} title="Move Up">
                      <FontAwesomeIcon icon={faArrowUp} />
                    </button>
                    <button className="btn btn-outline-secondary btn-sm" onClick={() => moveSectionDown(index)} title="Move Down">
                      <FontAwesomeIcon icon={faArrowDown} />
                    </button>
                    <button className="btn btn-outline-danger btn-sm" onClick={() => confirmDeleteSection(index)} title="Delete">
                      <FontAwesomeIcon icon={faTrash} />
                    </button>
                  </div>
                </div>
                <ReportGeneratorSection
                  section={section}
                  sections={sections}
                  onUpdate={(updatedSection) => updateSection(index, updatedSection)}
                  hasCircularDependency={hasCircularDependency}
                  onDependencyChange={handleDependencyChange}
                />
              </div>
            </div>
          ))}
          <div className="text-center mb-4">
            <button className="btn btn-primary" onClick={() => addSection(sections.length)}>
              <FontAwesomeIcon icon={faPlus} className="me-2" />
              Add New Section
            </button>
          </div>

        </div>
      </div>

      {showDeleteConfirmation && (
        <div className="modal d-block" tabIndex="-1" style={{ backgroundColor: 'rgba(0,0,0,0.5)' }}>
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title">Confirm Deletion</h5>
                <button type="button" className="btn-close" onClick={cancelDelete}></button>
              </div>
              <div className="modal-body">
                <p>Are you sure you want to delete this section?</p>
              </div>
              <div className="modal-footer">
                <button type="button" className="btn btn-secondary" onClick={cancelDelete}>No</button>
                <button type="button" className="btn btn-danger" onClick={deleteSection}>Yes</button>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default ReportLayout;
