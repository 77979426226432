import React, { useState, useEffect } from "react";
import { getDatabase, ref, set, onValue } from "firebase/database";
import { getFunctions, httpsCallable } from "firebase/functions";
import app from "../../firebase";

function ChatGPTTeacher(props) {
    const [response, setResponse] = useState("");
    const [loading, setLoading] = useState(false);


    useEffect(() => {
        if (!loading && response.length<=0) {
            getResponse();
        }
    }, []);


    //I will use this later
    async function saveResponseToFirebase(response, award) {
        const database = getDatabase(app);
        const responsePath = ref(database, 'schools/' + props.schoolData.path + '/submissions/' + props.areaPath + '/' + props.uid + '/grace/' + props.characteristicId)
        set(responsePath, { response: response, award: award }).then(() => {
            console.log("save succsess")
        })
    }


    async function loadPreviousResponse() {
        const database = getDatabase(app);
        const responsePath = ref(database, 'schools/' + props.schoolData.path + '/submissions/' + props.areaPath + '/' + props.uid + '/grace/' + props.characteristicId)
        onValue(responsePath, (snapshot) => {
            const data = snapshot.val();
            if (data) {
                setResponse(data.response);

            } else {
                setResponse("");

            }
        })
    }

    const functions = getFunctions(app);

    async function getResponse() {
        console.log("school data for chat", props.schoolData)
        setLoading(true)

        const messages = [{
            role: "user",
            content: `You are now GRACE, the growth and resiliance assistant for character education. On behalf of BeWorldClass, you are acting as a consultant to a ${props.schoolData?.stage} school, analysing some data on character development, and producing a report on this data. The data was gathered through students using the BeWorldClass online platform. Your primary role is data analysis in a caracter and career education context. When talking about the BeWorldClass AI, speak in the first person, as that is you. You have already marked a lot of the work. Any data regarding marks or issuing badges was done by you. You will be given a task on a section of the document, and you must return only the text to be inserted into this document. You should NOT add headings, for example "Introduction:".`
        }, {
            role: "user",
            content: `This is the task: ${props.prompt}`

        }
        ]


        try {
            const chatGpt = httpsCallable(functions, 'chatGptTeacher');
            const result = await chatGpt({
                messages: messages,
                schoolId: props.schoolData.path
            });

            const text = result?.data?.choices ? result.data.choices[0].message.content : null;

            if (text) {

                setLoading(false)
                setResponse(text.replace(/Introduction:|Overview:|Overview and introduction: |Conclusion: /gi, ''));

            } else {
                setLoading(false)
                setResponse("Sorry, it seems that something went wrong. I either had trouble connecting to the internet or I made a mistake reviewing this. I'm still improving. Please try again to see my response.")
            }

            props.onLoaded()

        } catch (error) {
            console.log(error)
            setLoading(false)
            setResponse("Sorry, it seems that something went wrong. I either had trouble connecting to the internet or I made a mistake reviewing this. I'm still improving. Please try again to see my response.")
            props.onLoaded()
        }

    }

    return (

        <>


            {loading &&
                <div className="col-12  col-lg-9">
                    <p className="bubble" >
                        <img width="15%" className="bubble d-block d-lg-none" src="https://firebasestorage.googleapis.com/v0/b/beworldclass-801f5.appspot.com/o/staticImages%2FGRACE%20head.png?alt=media&token=1197660e-9d0e-4f57-b02a-5ea32613668e" />
                        G.R.A.C.E. is thinking. This may take a moment.
                    </p>
                </div>
            }

            {(response && !loading) && <>
                <pre style={{ whiteSpace: "pre-wrap", fontFamily: "Arial", fontSize: "22px", color: "black" }} className="col-12 col-lg-9">

                    {response}

                </pre>
            </>
            }


        </>
    );
}

export default ChatGPTTeacher;